import axios, { CancelTokenSource } from "axios";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchUnsubscribeAlert } from "../../services/unsubscribeAlert";
import errToStr from "../../util/errToStr";
import { getLogo } from "../../util/getLogo";
import Bold from "../Bold";
import LoadingContainer from "../LoadingContainer";
import { PageContainer } from "../PageStyles";
import StateContext from "../StateContext";
import { FlexRow, HeadingWrapper, LogoContainer, MetaWrapper, Section } from "./styles";

const UnsubscribeAlertScreen: FC = () => {
  const { id } = useParams();

  const { color } = useContext(ThemeContext);
  const { theme } = useContext(StateContext);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const unsubscribe = useCallback(() => {
    setSubmitting(true);
    fetchUnsubscribeAlert(source, id)
      .then((response) => {
        setSubmittedMsg(response);
        setSubmittingErr("");
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  }, []);

  useEffect(() => {
    unsubscribe();
  }, [id]);

  return (
    <div style={{ overflow: "auto", width: "100%", height: "100%" }}>
      <PageContainer>
        <FlexRow>
          <HeadingWrapper>
            <LogoContainer>{getLogo(color, theme)}</LogoContainer>
          </HeadingWrapper>
          <MetaWrapper>
            <LoadingContainer loading={submitting} err={submittingErr}>
              <Section style={{ minHeight: "100px" }}>
                <Bold>{submittedMsg}</Bold>
              </Section>
            </LoadingContainer>
          </MetaWrapper>
        </FlexRow>
      </PageContainer>
    </div>
  );
};

export default UnsubscribeAlertScreen;
