import axios, { CancelTokenSource } from "axios";

export const postToken = async (source: CancelTokenSource, email: string, password: string, noExpire: boolean, twoFactor?: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + `/token`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Cache-Control": "no-cache",
    },
    data: JSON.stringify({
      email,
      password,
      twoFactor,
      noExpire,
    }),
  });

  return data;
};
