import axios from "axios";
import matchSorter from "match-sorter";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { deleteAlerts, fetchPlaceAlerts } from "../../services/editAlerts";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { printFixedLength, printLength, printLengthUnit } from "../../util/formatUnits";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import Badge from "../Badge";
import Bold from "../Bold";
import { PrimaryBtn } from "../Buttons";
import DeleteModal from "../DeleteModal";
import EditAlertModal from "../EditAlertModal";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import { PrimaryIconBtn } from "../IconButtons";
import LoadingContainer from "../LoadingContainer";
import Table, { CsvButtonsComponent } from "../Table";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";
import { DesktopDiv, MobileDiv, TableHeaderButtons } from "./../NewTable/styles";

// if placeId is passed in, this table is being used on the place page so we don't display as much information.
// if no placeId is passed in, this table is being used on the alerts page.
const PlaceAlertsTable: FC<any> = ({ placeId, meta }) => {
  const { color } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [selectedId, setSelectedId] = useState<any>(undefined);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [filterOptions, setFilterOptions] = useState<any>({
    trackerTags: [],
    placeTags: [],
    placeTypes: [],
    assetTypes: [],
  });

  const tableRef = useRef<any>(null);

  // Fetch data on mount and when meta changes because meta is updated when the place is edited on the place page
  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchPlaceAlerts(source, placeId)
      .then((response) => {
        setData(response);

        // get distinct strings for use in the select input filters
        if (response.length > 0) {
          const trackerTags = new Set();
          const assetTypes = new Set();
          const placeTags = new Set();
          const placeTypes = new Set();

          for (let i = 0; i < response.length; i++) {
            if (response[i].sensorGroup) trackerTags.add(response[i].sensorGroup);
            if (response[i].assetTypeName) assetTypes.add(response[i].assetTypeName);
            if (response[i].placeTagName) placeTags.add(response[i].placeTagName);
            if (response[i].placeGroup) placeTypes.add(response[i].placeGroup);
          }

          setFilterOptions({
            trackerTags: Array.from(trackerTags).sort(),
            assetTypes: Array.from(assetTypes).sort(),
            placeTags: Array.from(placeTags).sort(),
            placeTypes: Array.from(placeTypes).sort(),
          });
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [meta]);

  const formatDataToCsv = (tableRef: any) => {
    const headers = [
      "Alert Name",
      "Email Addresses",
      "Phone Numbers",
      "Webhooks",
      "Alert Enter",
      "Alert Leave",
      "Tracker ID",
      kegsOrTrackers("Keg Tag", "Tracker Tag"),
      "Asset Type",
    ];

    if (!placeId) {
      headers.push("Place");
      headers.push("Place Tag");
      headers.push("Place Type");
      headers.push("Latitude");
      headers.push("Longitude");
      headers.push(`Radius (${printLengthUnit()})`);
    }

    const data = tableRef.current.getResolvedState().sortedData;

    return [
      headers,
      ...data.map((row: any) => {
        const data = [
          row.alertName,
          row.emailAddress.join(", "),
          row.phoneNumber.join(", "),
          row.webhook.join(", "),
          row.alertEnter ? "True" : "False",
          row.alertLeave ? "True" : "False",
          row.sensorId,
          row.sensorGroup,
          row.assetTypeName,
        ];

        if (!placeId) {
          data.push(row.placeName);
          data.push(row.placeTagName);
          data.push(row.placeGroup);
          data.push(row.latitude ? row.latitude.toFixed(6) : "");
          data.push(row.longitude ? row.longitude.toFixed(6) : "");
          data.push(printFixedLength(row.radius));
        }

        return data;
      }, []),
    ];
  };

  const columns = [];

  columns.push({
    Header: "Actions",
    minWidth: 110,
    maxWidth: 110,
    filterable: false,
    sortable: false,
    Cell: ({ original }: any) => {
      return (
        <Tooltip
          maxWidth="none"
          theme="binary-no-padding"
          content={
            <MenuList>
              {isAdminOrUser() ? (
                <>
                  <MenuButton
                    onClick={() => {
                      setSelectedId(original.id);
                      setEditModalOpen(true);
                    }}
                  >
                    Edit Alert
                  </MenuButton>
                  <DangerMenuButton
                    onClick={() => {
                      setSelectedId(original.id);
                      setDeleteModalOpen(true);
                    }}
                  >
                    Delete Alert
                  </DangerMenuButton>
                </>
              ) : (
                <>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <MenuButton disabled={true}>Edit Alert</MenuButton>
                    </div>
                  </Tooltip>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <MenuButton disabled={true}>Delete Alert</MenuButton>
                    </div>
                  </Tooltip>
                </>
              )}
            </MenuList>
          }
          interactive={true}
          touch={true}
          appendTo={document.body}
          trigger="click"
          placement="bottom-start"
        >
          <MoreIconContainer>
            <MoreIconSize>
              <MoreIcon fill={color.font[2]} />
            </MoreIconSize>
          </MoreIconContainer>
        </Tooltip>
      );
    },
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "alertName",
    Header: "Alert Name",
    accessor: "alertName",
    minWidth: 220,
    maxWidth: 220,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["alertName"],
      }),
    filterAll: true,
  });

  columns.push({
    id: "emailAddress",
    Header: "Email Addresses",
    accessor: "emailAddress",
    minWidth: 220,
    maxWidth: 220,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["emailAddress"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const value = props.value;
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((item, index) => (
              <Tag key={index} name={item} colour={color.primary[2]} />
            ))}
          </div>
        );
      }
      return <span></span>;
    },
  });

  columns.push({
    id: "phoneNumber",
    Header: "Phone Numbers",
    accessor: "phoneNumber",
    minWidth: 220,
    maxWidth: 220,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["phoneNumber"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const value = props.value;
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((item, index) => (
              <Tag key={index} name={item} colour={color.primary[2]} />
            ))}
          </div>
        );
      }
      return <span></span>;
    },
  });

  columns.push({
    id: "webhook",
    Header: "Webhooks",
    accessor: "webhook",
    minWidth: 250,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["webhook"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const value = props.value;
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((item, index) => (
              <Tag key={index} name={item} colour={color.primary[2]} />
            ))}
          </div>
        );
      }
      return <span></span>;
    },
  });

  columns.push({
    id: "sensorId",
    Header: kegsOrTrackers("Keg", "Tracker"),
    accessor: "sensorId",
    minWidth: 160,
    maxWidth: 160,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["sensorId"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const url = kegsOrTrackers("kegs", "trackers");
      let title = props.value ? props.value : "";
      title += `${props.original.nickname ? " (" + props.original.nickname + ")" : ""}`;

      return (
        <Link to={`/${url}/${props.value}`} title={title}>
          {title}
        </Link>
      );
    },
  });

  columns.push({
    id: "sensorGroup",
    Header: kegsOrTrackers("Keg Tag", "Tracker Tag"),
    accessor: "sensorGroup",
    minWidth: 150,
    maxWidth: 150,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        {filterOptions.trackerTags.map((tag: any) => {
          return (
            <option key={tag} value={tag}>
              {tag}
            </option>
          );
        }, [])}
      </select>
    ),
    Cell: (props: any) => <Tag name={props.value} colour={props.original.sensorGroupColour} />,
  });

  if (!placeId) {
    columns.push({
      id: "placeName",
      Header: "Place",
      accessor: "placeName",
      minWidth: 320,
      maxWidth: 320,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["placeName"],
        }),
      filterAll: true,
      Cell: (props: any) => <Link to={`/places/${props.original.placeId}`}>{props.value}</Link>,
    });

    columns.push({
      id: "placeTagName",
      Header: "Place Tag",
      accessor: "placeTagName",
      minWidth: 150,
      maxWidth: 150,
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") {
          return true;
        }
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          {filterOptions.placeTags.map((tag: any) => {
            return (
              <option key={tag} value={tag}>
                {tag}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) => <Tag name={props.value} colour={props.original.placeTagColour} />,
    });

    columns.push({
      id: "placeGroup",
      Header: "Place Type",
      accessor: "placeGroup",
      minWidth: 150,
      maxWidth: 150,
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") {
          return true;
        }
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          {filterOptions.placeGroups.map((type: any) => {
            return (
              <option key={type} value={type}>
                {type}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) => (
        <>
          <ColoredDot color={props.original.placeGroupColour} />
          <span title={props.value}>{props.value}</span>
        </>
      ),
    });

    columns.push({
      id: "latitude",
      Header: "Latitude",
      accessor: "latitude",
      minWidth: 120,
      maxWidth: 120,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["latitude"],
        }),
      filterAll: true,
      Cell: (props: any) => (props.value ? props.value.toFixed(6) : ""),
    });

    columns.push({
      id: "longitude",
      Header: "Longitude",
      accessor: "longitude",
      minWidth: 140,
      maxWidth: 140,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["longitude"],
        }),
      filterAll: true,
      Cell: (props: any) => (props.value ? props.value.toFixed(6) : ""),
    });

    columns.push({
      id: "radius",
      Header: "Radius",
      accessor: "radius",
      minWidth: 120,
      maxWidth: 120,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["radius"],
        }),
      filterAll: true,
      Cell: (props: any) => <span>{printLength(props.value)}</span>,
    });
  }

  columns.push({
    id: "alertEnter",
    Header: "Alert Enter",
    accessor: "alertEnter",
    minWidth: 140,
    maxWidth: 140,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      if (filter.value === "false") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    ),
    Cell: (props: any) => (
      <Badge title={props.value ? "True" : "False"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
        {props.value ? "True" : "False"}
      </Badge>
    ),
  });

  columns.push({
    id: "alertLeave",
    Header: "Alert Leave",
    accessor: "alertLeave",
    minWidth: 140,
    maxWidth: 140,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      if (filter.value === "false") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    ),
    Cell: (props: any) => (
      <Badge title={props.value ? "True" : "False"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
        {props.value ? "True" : "False"}
      </Badge>
    ),
  });

  const defaultSorted = [
    {
      id: placeId ? "emailAddress" : "placeName",
      desc: false,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedId(null);
                      setEditModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedId(null);
                        setEditModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
          <CsvButtonsComponent data={data} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Alert List.csv" />
        </TableHeaderButtons>
        <Table loading={dataLoading} filterable={true} style={{ clear: "both" }} data={data} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
      </LoadingContainer>
      {editModalOpen && <EditAlertModal id={selectedId} setData={setData} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Alert"
          body={<span>Are you sure you want to delete this alert?</span>}
          successMsg="Alert Deleted"
          onDelete={() => setData((prev: any) => prev.filter((row: any) => row.id !== selectedId))}
          onClose={() => setSelectedId(undefined)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deleteAlerts}
          serviceParams={[selectedId]}
        />
      )}
    </div>
  );
};

export default PlaceAlertsTable;
