const hexToRgbaArray = (hex: string): [number, number, number, number] => {
  if (hex) {
    // Remove # if present
    hex = hex.replace(/^#/, "");

    let r: number,
      g: number,
      b: number,
      a = 255;

    // Handle different hex formats
    if (hex.length === 3 || hex.length === 4) {
      // #RGB or #RGBA
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);

      if (hex.length === 4) {
        a = parseInt(hex[3] + hex[3], 16) / 255;
      }
    } else if (hex.length === 6 || hex.length === 8) {
      // #RRGGBB or #RRGGBBAA
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);

      if (hex.length === 8) {
        a = parseInt(hex.substring(6, 8), 16) / 255;
      }
    } else {
      throw new Error("Invalid hex color format");
    }

    return [r, g, b, a];
  }

  throw new Error("No hex color format");
};

export default hexToRgbaArray;
