import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import QrScanner from "qr-scanner";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../services/autoComplete";
import { getAccount } from "../../services/localStorage";
import { putManifest } from "../../services/manifests";
import InfoIcon from "../../svgs/Legend";
import { isAdmin } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { exists, maxLength, validName } from "../../util/formValidations";
import { hasNavify } from "../../util/integrations";
import { DangerAlert } from "../Alerts";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import Checkbox from "../Checkbox";
import FlatpickrPicker from "../FlatpickrPicker";
import { FormError, FormInput, FormTextareaInput, InfoIconContainer } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncSelect } from "../Select";
import Tooltip from "../Tooltip";
import TrackerInfoPanel from "../TrackerInfoPanel";
import { QrScanButton } from "./styles";

const defaultManifest = {
  id: null,
  tracker: null,
  name: "",
  description: "",
  startTime: "",
  endTime: "",
  deadlineTime: "",
  startPlace: null,
  endPlace: null,
  targetPlace: null,
  startingUser: null,
  endUser: null,
  autoStart: false,
  autoEnd: false,
};

const initManifestForm = (manifest: any) => {
  const formData = {
    ...defaultManifest,
    ...manifest,
    tracker: manifest.sensorId
      ? {
          value: manifest.sensorId,
          label: manifest.sensorId,
        }
      : null,
    startTime: manifest.startTimeUnix ? manifest.startTimeUnix * 1000 : "",
    endTime: manifest.endTimeUnix ? manifest.endTimeUnix * 1000 : "",
    deadlineTime: manifest.deadlineTimeUnix ? manifest.deadlineTimeUnix * 1000 : "",
    startPlace: manifest.startPlaceId
      ? {
          value: manifest.startPlaceId,
          label: manifest.startPlaceName,
        }
      : null,
    endPlace: manifest.endPlaceId
      ? {
          value: manifest.endPlaceId,
          label: manifest.endPlaceName,
        }
      : null,
    targetPlace: manifest.targetPlaceId
      ? {
          value: manifest.targetPlaceId,
          label: manifest.targetPlaceName,
        }
      : null,
    startingUser: manifest.startingUserId
      ? {
          value: manifest.startingUserId,
          label: manifest.startingUserName,
        }
      : null,
    endUser: manifest.endUserId
      ? {
          value: manifest.endUserId,
          label: manifest.endUserName,
        }
      : null,
  };

  return formData;
};

const EditManifestModel: FC<any> = ({ manifest, onSuccess, modalOpen, setModalOpen }) => {
  const { color, short_date, short_datetime } = useContext(ThemeContext);

  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);

  const [formData, setFormData] = useState<any>(initManifestForm(manifest));
  const [formErrors, setFormErrors] = useState<any>({});

  const [codeReader, setCodeReader] = useState<any>(undefined);
  const [scanning, setScanning] = useState<boolean>(false);
  const [scan, setScan] = useState<any>(undefined);
  const [scanError, setScanError] = useState<string>("");
  const [scanLoading, setScanLoading] = useState<boolean>(false);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;
    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setScan(code);
        }
      });
      codeReader.setInversionMode("both");
      setCodeReader(codeReader);
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  // Stop code reader when tracker info is loading or the tracker info modal is open
  useEffect(() => {
    if (codeReader) {
      if (scanning) {
        codeReader.start();
      } else {
        codeReader.stop();
        if (videoRef && videoRef.srcObject) {
          videoRef.srcObject.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }
    }
  }, [codeReader, scanning, videoRef]);

  // On QR scan, fetch all trackers from autoComplete to find matching binaryId and set it in the form or show error
  useEffect(() => {
    if (scan) {
      setScanLoading(true);
      fetchAutoComplete("trackers", "")
        .then((response) => {
          const test = response.filter((tracker: any) => tracker.value == scan);
          if (test.length > 0) {
            setFormData((prev: any) => ({ ...prev, tracker: test[0] }));
            setScan(undefined);
            setScanning(false);
            setScanError("");
          } else {
            setScanError("No tracker found with ID: " + scan);
          }
          setScanLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setScanError(errToStr(err));
            setScanLoading(false);
          }
        });
    }
  }, [scan]);

  useEffect(() => {
    if (formData.tracker && formData.tracker.value) {
      if (formData.name === "" || formData.name.includes(`on ${moment().format(short_date)}`)) {
        setFormData((prev: any) => ({ ...prev, name: `${formData.tracker.value} on ${moment().format(short_date)}` }));
      }
    }
  }, [formData.tracker]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "tracker":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 50, setFormErrors) && validName(name, value, setFormErrors);
          break;

        case "description":
          currValid = maxLength(name, value, 500, setFormErrors);
          break;

        case "startPlace":
          if (hasNavify() || formData.autoStart === true) currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        case "targetPlace":
          if (formData.autoEnd === true) currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      id: formData.id,
      binaryId: formData.tracker?.value,
      name: formData.name === "" ? `${formData.tracker.value} on ${moment().format(short_date)}` : formData.name,
      description: formData.description ? formData.description : undefined,
      startTime: formData.startTime ? Math.round(formData.startTime / 1000).toString() : undefined,
      endTime: formData.endTime ? Math.round(formData.endTime / 1000).toString() : undefined,
      deadlineTime: formData.deadlineTime ? Math.round(formData.deadlineTime / 1000).toString() : undefined,
      startPlaceId: formData.startPlace ? formData.startPlace.value : undefined,
      endPlaceId: formData.endPlace ? formData.endPlace.value : undefined,
      targetPlaceId: formData.targetPlace ? formData.targetPlace.value : undefined,
      startingUserId: formData.startingUser ? formData.startingUser.value : undefined,
      endUserId: formData.endUser ? formData.endUser.value : undefined,
      autoStart: formData.autoStart,
      autoEnd: formData.autoEnd,
    };

    return formattedData;
  };

  const handleUpdate = () => {
    const valid = validateForm();

    if (valid) {
      const body = formatFormData();
      setSubmitting(true);
      putManifest(source, body)
        .then(() => {
          if (onSuccess) onSuccess();
          setSubmittedMsg("Manifest Updated");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleEnableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setSubmittedMsg("");
    setSubmittingErr("");
    setScanning(true);
    setScanError("");
    setScanLoading(false);
  };

  const handleDisableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setScan(undefined);
    setScanning(false);
    setScanError("");
    setScanLoading(false);
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const handleDateChange = (date: any) => {
    setFormData((prev: any) => ({ ...prev, deadlineTime: date[0] }));
    setFormErrors((prev: any) => ({ ...prev, deadlineTime: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  // Auto-populates select input on search.
  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  // Auto-populates the user select input on search.
  const loadUsers = (inputValue: string, callback: any) => {
    fetchAutoComplete("users", inputValue).then((response) => {
      const sortedUsers = [...response].sort((a, b) => a.label.localeCompare(b.label));

      const currUserIndex = sortedUsers.findIndex((user) => user.value === getAccount().id);

      // If the current user is found, remove it and insert at the start
      if (currUserIndex !== -1) {
        const [targetItem] = sortedUsers.splice(currUserIndex, 1);
        sortedUsers.unshift(targetItem);
      }

      callback(sortedUsers);
    });
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={handleUpdate}
      onClose={handleClose}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title="Edit Manifest"
      success={submittedMsg}
      error={submittingErr}
      body={
        <LoadingContainer loading={submitting}>
          <div
            style={{
              display: scanning ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingContainer loading={scanLoading}>
              <video
                id="video"
                ref={videoRefCallback}
                style={{
                  display: "inline-block",
                  border: "1px solid gray",
                  borderRadius: "3px",
                  maxHeight: "50vh",
                  maxWidth: "100%",
                }}
              />
            </LoadingContainer>
          </div>
          {scanError && (
            <DangerAlert
              style={{
                margin: "20px 0 0 0",
                float: "left",
                width: "100%",
              }}
            >
              {scanError}
            </DangerAlert>
          )}
          <div
            style={{
              display: scanning ? "none" : "block",
            }}
          >
            <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
              <ModalFormContainer>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <label style={{ float: "left" }}>Tracker</label>
                    <div style={{ cursor: "pointer" }}>
                      <Tooltip
                        content={`The device that will be associated with this Manifest - movement and sensor data will be recorded from this Tracker`}
                        interactive={true}
                        touch={true}
                        appendTo={document.body}
                      >
                        <InfoIconContainer>
                          <InfoIcon fill={color.font[2]} />
                        </InfoIconContainer>
                      </Tooltip>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <AsyncSelect
                        name="tracker"
                        defaultOptions={true}
                        isClearable={false}
                        isError={formErrors.tracker}
                        value={formData.tracker}
                        loadOptions={(inputValue: any, callback: any) => loadOptions("trackers", inputValue, callback)}
                        onChange={handleSelectChange}
                        placeholder="Select..."
                        isDisabled={!isAdmin() || formData.startTimeUnix !== undefined}
                      />
                    </div>
                    <div style={{ width: "100%", maxWidth: "34px", marginLeft: "6px" }}>
                      <Tooltip content="Scan QR Code" trigger="mouseenter">
                        <OutlineBtn
                          disabled={!isAdmin() || formData.startTimeUnix !== undefined}
                          onClick={handleEnableScan}
                          style={{ height: "34px", minWidth: "unset" }}
                          padding="0"
                          width="34px"
                        >
                          <div
                            style={{
                              width: "34px",
                              height: "34px",
                              lineHeight: "0",
                              fill: "none",
                            }}
                          >
                            <QrScanButton disabled={formData.startTimeUnix !== undefined} />
                          </div>
                        </OutlineBtn>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <FormError error={formErrors.tracker}>{formErrors.tracker}</FormError>
                <TrackerInfoPanel trackerId={formData.tracker?.value} />
              </ModalFormContainer>
              <ModalFormContainer>
                <label style={{ float: "left" }}>Name</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    content={`A brief summary of the Manifest, used for Alerts and identification - defaults to "TrackerID on Date Created" if left blank`}
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <FormInput
                  type="text"
                  name="name"
                  value={formData.name}
                  error={formErrors.name}
                  onChange={handleChange}
                  disabled={!isAdmin() || formData.endTimeUnix !== undefined}
                />
                <FormError error={formErrors.name}>{formErrors.name}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label style={{ float: "left" }}>Description</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    content="Additional details about this Manifest, such as expected contents, purpose, or special instructions"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <FormTextareaInput
                  name="description"
                  rows={6}
                  maxLength={4096}
                  value={formData.description}
                  error={formErrors.description}
                  onChange={handleChange}
                  disabled={formData.endTimeUnix !== undefined}
                />
                <FormError error={formErrors.description}>{formErrors.description}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Start Place</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip content="The location where this Manifest journey begins" interactive={true} touch={true} appendTo={document.body}>
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <AsyncSelect
                  name="startPlace"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.startPlace}
                  value={formData.startPlace}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                  isDisabled={formData.startTimeUnix !== undefined}
                />
                <FormError error={formErrors.startPlace}>{formErrors.startPlace}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true} style={{ alignContent: "center" }}>
                <Checkbox
                  style={{ display: "inline-block" }}
                  name="autoStart"
                  label="Auto Start"
                  checked={formData.autoStart}
                  onChange={handleCheckboxChange}
                  disabled={!isAdmin() || formData.startTimeUnix !== undefined}
                />
                <div
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    marginBottom: "-3px",
                  }}
                >
                  <Tooltip
                    content="If enabled, the Manifest status will automatically change from Created → Started when the Tracker departs the starting Place"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Starting User</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    content="The User responsible for initiating the Manifest - this can be set at a later time if needed"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <AsyncSelect
                  name="startingUser"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.startingUser}
                  value={formData.startingUser}
                  loadOptions={(inputValue: any, callback: any) => loadUsers(inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                  isDisabled={!isAdmin() || formData.startTimeUnix !== undefined}
                />
                <FormError error={formErrors.startingUser}>{formErrors.startingUser}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Start Time</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip content="The time at which this Manifest started" interactive={true} touch={true} appendTo={document.body}>
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <FormInput
                  autoComplete="no"
                  type="text"
                  name="startTime"
                  value={formData.startTime ? moment(formData.startTime).format(short_datetime) : ""}
                  disabled={true}
                />
                <FormError error={formErrors.startTime}>{formErrors.startTime}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Target Destination</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    content="The intended final location for this Manifest - this can be set later if needed"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <AsyncSelect
                  name="targetPlace"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.targetPlace}
                  value={formData.targetPlace}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                  isDisabled={formData.endTimeUnix !== undefined}
                />
                <FormError error={formErrors.targetPlace}>{formErrors.targetPlace}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true} style={{ alignContent: "center" }}>
                <Checkbox
                  style={{ display: "inline-block" }}
                  name="autoEnd"
                  label="Auto End"
                  checked={formData.autoEnd}
                  onChange={handleCheckboxChange}
                  disabled={!isAdmin() || formData.endTimeUnix !== undefined}
                />
                <div
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    marginBottom: "-3px",
                  }}
                >
                  <Tooltip
                    content="If enabled, the Manifest status will automatically change from Started → Finished when the Tracker reaches the Target Destination"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Ending User</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    content="The User accountable for receiving and ending the Manifest upon arrival - this can be set at a later time if needed"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <AsyncSelect
                  name="endUser"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.endUser}
                  value={formData.endUser}
                  loadOptions={(inputValue: any, callback: any) => loadUsers(inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                  isDisabled={!isAdmin() || formData.endTimeUnix !== undefined}
                />
                <FormError error={formErrors.endUser}>{formErrors.endUser}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Deadline</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    content="The expected time by which this Manifest should reach its target destination"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                {formData.endTimeUnix !== undefined ? (
                  <FormInput
                    autoComplete="no"
                    type="text"
                    name="deadlineTime"
                    value={formData.deadlineTime ? moment(formData.deadlineTime).format(short_datetime) : ""}
                    disabled={true}
                  />
                ) : (
                  <FlatpickrPicker
                    enableTime={true}
                    name="deadlineTime"
                    value={formData.deadlineTime}
                    onChange={handleDateChange}
                    onClose={handleDateChange}
                    onClear={() => handleDateChange([undefined])}
                    disabled={!isAdmin()}
                  />
                )}
                <FormError error={formErrors.deadlineTime}>{formErrors.deadlineTime}</FormError>
              </ModalFormContainer>
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        scanning ? (
          <OutlineBtn onClick={handleDisableScan} width="100%">
            Back
          </OutlineBtn>
        ) : submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
            {formData.endTimeUnix === undefined && <PrimaryBtn onClick={handleUpdate}>Update</PrimaryBtn>}
          </>
        )
      }
    />
  );
};

export default EditManifestModel;
