import moment from "moment";
import { FC, useContext, useRef } from "react";
import Flatpickr from "react-flatpickr";
import { ThemeContext } from "styled-components";
import Cross from "../../svgs/Cross";
import { ClearButton } from "./styles";

const FlatpickrPicker: FC<any> = ({ value, onClose, onChange, onClear, error, clearable = true, dateFormat, enableTime, options, placeholder, disabled }) => {
  const flatpickrRef = useRef<any>(null);

  const { short_datetime, short_date } = useContext(ThemeContext);

  return (
    <div style={{ position: "relative", width: "100%", overflow: "hidden", opacity: disabled ? 0.33 : 1 }}>
      <Flatpickr
        data-enable-time={enableTime}
        ref={flatpickrRef}
        value={value}
        onChange={(date: any) => (!disabled ? onChange(date) : undefined)}
        onClose={(date: any) => (!disabled ? onClose(date) : undefined)}
        options={{ ...options, formatDate: (date: any) => moment(date).format(dateFormat ?? enableTime ? short_datetime : short_date), clickOpens: !disabled }}
        className={error ? "flatpickr-input-error" : "flatpickr-input"}
        placeholder={placeholder}
      />
      {!disabled && clearable && value != null && (
        <ClearButton
          onClick={() => {
            if (flatpickrRef && flatpickrRef.current && flatpickrRef.current.flatpickr) {
              flatpickrRef.current.flatpickr.clear();
              if (onClear) onClear();
            }
          }}
        >
          <Cross />
        </ClearButton>
      )}
    </div>
  );
};

export default FlatpickrPicker;
