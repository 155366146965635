import matchSorter from "match-sorter";
import moment from "moment";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { printLength } from "../../util/formatUnits";
import { googleMapsLink } from "../../util/googleMapsLink";
import ChartHeading from "../ChartHeading";
import FlatpickrRangePicker from "../FlatpickrRangePicker";
import Table from "../Table";

const SensorHistory: FC<any> = ({ data, loading }) => {
  const { short_datetime, short_date } = useContext(ThemeContext);

  const columns = [
    {
      id: "date",
      Header: "Date",
      accessor: (place: any) => place.eventDate,
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 180,
      maxWidth: 180,
    },
    {
      id: "event",
      Header: "Event",
      accessor: (keg: any) => keg.eventType,
      minWidth: 160,
      maxWidth: 160,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["event"],
        }),
      filterAll: true,
      style: { whiteSpace: "unset" },
    },
    {
      id: "detail",
      Header: "Detail",
      accessor: (keg: any) => keg.eventType,
      Cell: (props: any) => (
        <>
          {props.original.text ? props.original.text : ""}
          {props.original.placeId && props.original.placeName ? (
            <>
              &nbsp;
              <Link to={`/places/${props.original.placeId}`}>{props.original.placeName}</Link>
            </>
          ) : (
            ""
          )}
          {props.original.beerName ? (
            <>
              &nbsp;
              {props.original.beerName}
            </>
          ) : (
            ""
          )}
          {props.original.latitude && props.original.longitude ? (
            <>
              &nbsp;
              {googleMapsLink(props.original.latitude, props.original.longitude, props.original.addressText)}
            </>
          ) : (
            ""
          )}
          {props.original.locationType && props.original.accuracy ? ` (${props.original.locationType} accuracy ${printLength(props.original.accuracy)})` : ""}
          {props.original.user && ` by ${props.original.user}`}
          {props.original.placeFilledId && (
            <>
              {" "}
              at <Link to={`/places/${props.original.placeFilledId}`}>{props.original.placeFilledName}</Link>
            </>
          )}
          {props.original.placeEmptiedId && (
            <>
              {" "}
              at <Link to={`/places/${props.original.placeEmptiedId}`}>{props.original.placeEmptiedName}</Link>
            </>
          )}
        </>
      ),
      minWidth: 400,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: [
            "detail",
            "_original.placeName",
            "_original.beerName",
            "_original.latitude",
            "_original.longitude",
            "_original.addressText",
            "_original.locationType",
            "_original.accuracy",
          ],
        }),
      filterAll: true,
      style: { whiteSpace: "unset" },
    },
  ];

  const defaultSorted = [
    {
      id: "date",
      desc: true,
    },
  ];

  return (
    <>
      <ChartHeading>Event History</ChartHeading>
      <Table loading={loading} filterable={true} data={data} columns={columns} defaultSorted={defaultSorted} />
    </>
  );
};

export default SensorHistory;
