import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../services/autoComplete";
import { postReportSchedule } from "../../services/reportSchedules";
import InfoIcon from "../../svgs/Legend";
import errToStr from "../../util/errToStr";
import { exists, maxLength, validEmail, validName, validOption } from "../../util/formValidations";
import { FormError, FormInput, InfoIconContainer } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { CreatableSelect, Select } from "../Select";
import InfoTooltip from "../Tooltip";

interface ReportSchedule {
  id: number;
  name: string;
  reportId: string;
  frequency: string;
  filters: string;
  recipients: string;
  dateCreatedUnix: number;
  dateCreated: Date;
  createdBy: string;
}

const defaultReportSchedule = {
  id: null,
  name: "",
  reportId: null,
  frequency: "",
  filters: {},
  recipients: [],
  createdBy: "",
};

const CreateReportScheduleModal: FC<any> = ({ reportId, filters, onSuccess, modalOpen, setModalOpen }) => {
  const { color, short_date } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>({ ...defaultReportSchedule, reportId, filters });
  const [formErrors, setFormErrors] = useState<any>({});

  const [inputValues, setInputValues] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    if (formData.tracker && formData.tracker.value) {
      if (formData.name === "" || formData.name.includes(`on ${moment().format(short_date)}`)) {
        setFormData((prev: any) => ({ ...prev, name: `${formData.tracker.value} on ${moment().format(short_date)}` }));
      }
    }
  }, [formData.tracker]);

  const validateForm = () => {
    setFormErrors({});
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 255, setFormErrors) && validName(name, value, setFormErrors);
          break;

        case "frequency":
          if (value) currValid = validOption(name, value.value, ["Daily", "Weekly", "Monthly"], setFormErrors);
          else currValid = exists(name, value, setFormErrors);
          break;

        // If there are any emails, check if they're all valid
        case "recipients":
          currValid = exists(name, value, setFormErrors) && value.every((email: any) => !inputValues[name] && validEmail(name, email.value, setFormErrors));
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      reportId: reportId,
      name: formData.name,
      frequency: formData.frequency?.value,
      recipients: formData.recipients && formData.recipients.length > 0 ? formData.recipients.map((email: any) => email.value, []) : [],
      filters: JSON.stringify(filters),
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const valid = validateForm();

    if (valid) {
      const body = formatFormData();
      setSubmitting(true);
      postReportSchedule(source, body)
        .then((response) => {
          if (onSuccess) onSuccess();
          setFormData((prev: any) => ({ ...prev, id: response.id }));
          setSubmittedMsg("Report Schedule Created");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const createMultiTextInput = (name: string, inputValue: any, value: any) => {
    let valid = true;

    if (name === "recipients") valid = validEmail(name, inputValue, setFormErrors);

    if (formData[name].some((e: any) => e.value === inputValue)) {
      setFormErrors((prev: any) => ({
        ...prev,
        [name]: "Value already exists",
      }));
      valid = false;
    }

    if (valid) {
      setInputValues((prev: any) => ({ ...prev, [name]: "" }));
      setFormData((prev: any) => ({
        ...prev,
        [name]: [
          ...value,
          {
            label: inputValue,
            value: inputValue,
          },
        ],
      }));
    }
  };

  const handleMultiTextKeyDown = (e: any, name: string) => {
    const inputValue = inputValues[name];
    const value = formData[name];

    if (inputValue) {
      switch (e.key) {
        case "Enter":
        case "Tab":
        case ",":
        case ";":
          createMultiTextInput(name, inputValue, value);
          e.preventDefault();
      }
    }
  };

  const handleMultiTextBlur = (e: any, name: string) => {
    const inputValue = inputValues[name];
    const value = formData[name];

    if (inputValue) {
      createMultiTextInput(name, inputValue, value);
      e.preventDefault();
    }
  };

  const handleMultiTextInputChange = (value: any, action: any, name: string) => {
    if (action === "input-change") {
      setInputValues((prev: any) => ({
        ...prev,
        [name]: value,
      }));
      setFormErrors((prev: any) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const handleMultiTextChange = (value: any, name: string) => {
    setFormData((prev: any) => ({
      ...prev,
      [name]: value ? value : [],
    }));
  };

  // Auto-populates select input on search.
  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onClose={handleClose}
        size={!submittedMsg && !submittingErr ? "lg" : "sm"}
        title="Create Report Schedule"
        success={submittedMsg}
        error={submittingErr}
        submitBtnText="Create"
        onSubmit={handleSubmit}
        body={
          <LoadingContainer loading={submitting}>
            <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
              <ModalFormContainer>
                <label>Name</label>
                <FormInput type="text" name="name" placeholder="Name" value={formData.name} error={formErrors.name} onChange={handleChange} required={true} />
                <FormError error={formErrors.name}>{formErrors.name}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label style={{ float: "left" }}>Frequency</label>
                <div style={{ cursor: "pointer" }}>
                  <InfoTooltip content="the selected frequency determines the report's date range" interactive={true} touch={true} appendTo={document.body}>
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </InfoTooltip>
                </div>
                <div style={{ width: "100%" }}>
                  <Select
                    name="frequency"
                    isClearable={true}
                    isSearchable={true}
                    value={formData.frequency}
                    isError={formErrors.frequency}
                    options={[
                      { label: "Daily", value: "Daily" },
                      { label: "Weekly", value: "Weekly" },
                      { label: "Monthly", value: "Monthly" },
                    ]}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                </div>
                <FormError error={formErrors.frequency}>{formErrors.frequency}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label style={{ float: "left" }}>Email Addresses</label>
                <div style={{ cursor: "pointer" }}>
                  <InfoTooltip content="Press Enter after typing to add another" interactive={true} touch={true} appendTo={document.body}>
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </InfoTooltip>
                </div>
                <CreatableSelect
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  inputValue={inputValues.recipients}
                  isClearable={true}
                  isMulti={true}
                  menuIsOpen={false}
                  onChange={(value: any) => handleMultiTextChange(value, "recipients")}
                  onInputChange={(value: string, { action }: any) => handleMultiTextInputChange(value, action, "recipients")}
                  onBlur={(e: any) => handleMultiTextBlur(e, "recipients")}
                  onKeyDown={(e: any) => handleMultiTextKeyDown(e, "recipients")}
                  type="text"
                  name="recipients"
                  id="email"
                  value={formData.recipients}
                  isError={formErrors.recipients}
                  placeholder="Type email address and press enter..."
                />

                <FormError error={formErrors.recipients}>{formErrors.recipients}</FormError>
              </ModalFormContainer>
            </form>
          </LoadingContainer>
        }
      />
    </>
  );
};

export default CreateReportScheduleModal;
