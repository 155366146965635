import { Column, ColumnOrderState, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable, VisibilityState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import Drawer from "rc-drawer";
import React, { FC, InputHTMLAttributes, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { BiError } from "react-icons/bi";
import { TbCopy, TbDownload, TbLayoutColumns, TbLayoutRows } from "react-icons/tb";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../services/autoComplete";
import Cross from "../../svgs/Cross";
import EmptyDataIcon from "../../svgs/EmptyDataIcon";
import FilterIcon from "../../svgs/FilterIcon";
import { ROW_PADDING_OPTIONS, toggleRowDensity } from "../../util/tableUtils";
import { getTableFilters } from "../../util/urlParamFilters";
import ColumnList from "../ColumnList";
import { DrawBody, DrawCloseButton, DrawContent, DrawHeader } from "../Drawer/styles";
import ErrorScreen from "../ErrorScreen";
import FlatpickrRangePicker from "../FlatpickrRangePicker2";
import { DefaultIconBtn } from "../IconButtons";
import LoadingScreen from "../LoadingScreen";
import { AsyncSelect, Select } from "../Select";
import Tooltip from "../Tooltip";
import {
  Dot,
  FilterInput,
  PageButton,
  PageNumber,
  PaginationContainer,
  PaginationFlex,
  Resizer,
  RowCount,
  SelectInput,
  SortHeader,
  Table,
  TableBody,
  TableContainer,
  TableDataCell,
  TableFilterCell,
  TableHead,
  TableHeadCell,
  TableHeaderButtons,
  TableHeadRow,
  TableRow,
} from "./styles";

const NewTable: FC<any> = ({
  data,
  count,
  dataErr,
  dataLoading,
  columns,
  columnFilters,
  columnFiltersDebounced,
  setColumnFilters,
  sorting,
  setSorting,
  pageIndex,
  pageSize,
  setPagination,
  fetchData,
  fetchDataDependencies = [],
  fetchCsv,
  defaultTableSettings,
  getTableSettings,
  saveTableSettings,
  TableButtons,
  dataTypeName = "Data",
  emptyDataMsg = "",
}) => {
  const { color, short_date, color_30, color_80 } = useContext(ThemeContext);

  const isFirstRender = useRef(true);

  const [columnSettingsOpen, setColumnSettingsOpen] = useState<boolean>(false);
  const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>(getTableSettings() ? getTableSettings().order : []);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(getTableSettings() ? getTableSettings().visibility : {});

  const [rowDensity, setRowDensity] = useState<number>(getTableSettings() ? getTableSettings().density : 0);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    // Avoid making a request on the first render if columnFiltersDebounced is pre-populated
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (columnFiltersDebounced.length > 0 || pageIndex > 0 || sorting.length > 0) {
      fetchData();
    }
  }, [pageIndex, pageSize, sorting, columnFiltersDebounced, ...fetchDataDependencies]);

  useEffect(() => {
    saveTableSettings({ ...getTableSettings(), order: columnOrder });
  }, [columnOrder]);

  useEffect(() => {
    saveTableSettings({ ...getTableSettings(), visibility: columnVisibility });
  }, [columnVisibility]);

  useEffect(() => {
    saveTableSettings({ ...getTableSettings(), density: rowDensity });
  }, [rowDensity]);

  useEffect(() => {
    saveTableSettings({ ...getTableSettings(), pageSize: pageSize });
  }, [pageSize]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: data,
    columns,
    pageCount: Math.ceil(count / pageSize) ?? -1,
    state: {
      columnVisibility,
      columnFilters,
      columnOrder,
      pagination,
      sorting,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: "onChange",
    enableSortingRemoval: true,
    enableColumnFilters: true,
    enableMultiRemove: true,
    manualPagination: true,
    manualFiltering: true,
    enableMultiSort: true,
    sortDescFirst: true,
    enableSorting: true,
    manualSorting: true,
  });

  // Reset page index to 0 on page size, sorting, or filters change
  useEffect(() => {
    if (pageIndex !== 0) {
      table.setPageIndex(0);
    }
  }, [pageSize, sorting, columnFiltersDebounced]);

  const getPageButtons = () => {
    const pageCount = table.getPageCount();
    const pageSize = table.getState().pagination.pageSize;
    const pageIndex = table.getState().pagination.pageIndex;

    const bigSkip = Math.max(Math.pow(10, Math.floor(Math.log10(pageCount) - 1)), 10);

    let pagesNumbers: JSX.Element[] = [];

    if (pageCount < 8) {
      for (let i = 0; i < pageCount; i++) {
        pagesNumbers.push(
          <PageNumber key={i} selected={pageIndex === i} onClick={() => table.setPageIndex(i)}>
            {i + 1}
          </PageNumber>
        );
      }
    } else {
      if (pageIndex > 3 && pageIndex < pageCount - 3) {
        pagesNumbers = [0, "...", pageIndex - 1, pageIndex, pageIndex + 1, "...", pageCount - 1].map((page: any, i: number) => {
          if (typeof page === "string") {
            return (
              <PageNumber key={i} disabled={true}>
                ...
              </PageNumber>
            );
          } else {
            return (
              <PageNumber key={i} selected={pageIndex === page} onClick={() => table.setPageIndex(page)}>
                {page + 1}
              </PageNumber>
            );
          }
        }, []);
      } else if (pageIndex <= 3) {
        pagesNumbers = [0, 1, 2, 3, 4, "...", pageCount - 1].map((page: any, i: number) => {
          if (typeof page === "string") {
            return (
              <PageNumber key={i} disabled={true}>
                ...
              </PageNumber>
            );
          } else {
            return (
              <PageNumber key={i} selected={pageIndex === page} onClick={() => table.setPageIndex(page)}>
                {page + 1}
              </PageNumber>
            );
          }
        }, []);
      } else if (pageIndex >= pageCount - 3) {
        pagesNumbers = [0, "...", pageCount - 5, pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1].map((page: any, i: number) => {
          if (typeof page === "string") {
            return (
              <PageNumber key={i} disabled={true}>
                ...
              </PageNumber>
            );
          } else {
            return (
              <PageNumber key={i} selected={pageIndex === page} onClick={() => table.setPageIndex(page)}>
                {page + 1}
              </PageNumber>
            );
          }
        }, []);
      }
    }

    return (
      <PaginationFlex>
        <div>
          <SelectInput
            value={pageSize}
            onChange={(e: any) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 50, 75, 100].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </SelectInput>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <RowCount style={{ marginRight: "24px" }}>
            {pageIndex * pageSize + 1} - {Math.min((pageIndex + 1) * pageSize, count)} of {count}
          </RowCount>
          <PageButton onClick={() => table.setPageIndex(pageIndex - bigSkip)} title={`Skip ${bigSkip}`} disabled={!table.getCanPreviousPage()}>
            {"<<"}
          </PageButton>
          <PageButton onClick={() => table.previousPage()} title="Previous" disabled={!table.getCanPreviousPage()}>
            {"<"}
          </PageButton>
          {pagesNumbers}
          <PageButton onClick={() => table.nextPage()} title="Next" disabled={!table.getCanNextPage()}>
            {">"}
          </PageButton>
          <PageButton onClick={() => table.setPageIndex(pageIndex + bigSkip)} title={`Skip ${bigSkip}`} disabled={!table.getCanNextPage()}>
            {">>"}
          </PageButton>
        </div>
      </PaginationFlex>
    );
  };

  return (
    <>
      <div style={{ position: "relative", display: "flex", flexDirection: "column" }}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}>{TableButtons}</div>
          <div style={{ display: "flex" }}>
            <Tooltip trigger="mouseenter" content="Column Settings">
              <div style={{ position: "relative" }}>
                {Object.values(columnVisibility).some((column: any) => column === false) && <Dot title="Some columns are hidden" />}
                <DefaultIconBtn onClick={() => setColumnSettingsOpen(true)}>
                  <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                    <TbLayoutColumns />
                  </IconContext.Provider>
                </DefaultIconBtn>
              </div>
            </Tooltip>
            <Tooltip trigger="mouseenter" content="Toggle Row Density">
              <DefaultIconBtn onClick={() => toggleRowDensity(rowDensity, setRowDensity)}>
                <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                  <TbLayoutRows />
                </IconContext.Provider>
              </DefaultIconBtn>
            </Tooltip>
            {data.length > 0 && fetchCsv && (
              <>
                <Tooltip trigger="mouseenter" content="Download CSV">
                  <DefaultIconBtn onClick={() => fetchCsv(true)}>
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </DefaultIconBtn>
                </Tooltip>
                {document.queryCommandSupported("copy") && (
                  <Tooltip trigger="mouseenter" content="Copy CSV">
                    <DefaultIconBtn onClick={() => fetchCsv(false)}>
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <TbCopy />
                      </IconContext.Provider>
                    </DefaultIconBtn>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </TableHeaderButtons>
        <TableContainer>
          <Table>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableHeadRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHeadCell
                      key={header.id}
                      style={{
                        width: header.getSize(),
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <SortHeader
                            {...{
                              canSort: header.column.getCanSort(),
                              sortDirection: header.column.getIsSorted(),
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </SortHeader>
                        </>
                      )}
                      <Resizer
                        {...{
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                        }}
                      />
                    </TableHeadCell>
                  ))}
                </TableHeadRow>
              ))}
              {table.getHeaderGroups().map((headerGroup) => (
                <TableHeadRow key={`filter-${headerGroup.id}`}>
                  {headerGroup.headers.map((header) => (
                    <TableFilterCell
                      key={`filter-${header.id}`}
                      style={{
                        width: header.getSize(),
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{header.column.getCanFilter() ? <Filter column={header.column} short_date={short_date} /> : null}</div>
                      )}
                    </TableFilterCell>
                  ))}
                </TableHeadRow>
              ))}
            </TableHead>
            {data.length > 0 ? (
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableDataCell
                        key={cell.id}
                        style={{
                          padding: ROW_PADDING_OPTIONS[rowDensity],
                          width: cell.column.getSize(),
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableDataCell>
                    ))}
                  </TableRow>
                ))}
                {dataLoading && (
                  <TableRow style={{ border: "none" }} key="loading-screen-row">
                    <TableDataCell style={{ padding: 0 }} key="loading-screen-cell">
                      <LoadingScreen loading={dataLoading} />
                    </TableDataCell>
                  </TableRow>
                )}
                {!dataLoading && dataErr && (
                  <TableRow style={{ border: "none" }} key="error-screen-row">
                    <TableDataCell style={{ padding: 0 }} key="error-screen-cell">
                      <ErrorScreen error={dataErr}>
                        <div style={{ margin: "0 auto" }}>
                          <IconContext.Provider value={{ color: color_80.error[2], size: "65px" }}>
                            <BiError />
                          </IconContext.Provider>
                        </div>
                        <p style={{ color: color_80.error[2], fontWeight: 500 }}>{dataErr}</p>
                      </ErrorScreen>
                    </TableDataCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <>
                {dataLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableDataCell style={{ padding: ROW_PADDING_OPTIONS[rowDensity], height: 256, textAlign: "center" }} colSpan={1000}>
                        <LoadingScreen loading={dataLoading} />
                      </TableDataCell>
                    </TableRow>
                  </TableBody>
                ) : dataErr ? (
                  <TableBody>
                    <TableRow>
                      <TableDataCell style={{ padding: ROW_PADDING_OPTIONS[rowDensity], height: 256, textAlign: "center" }} colSpan={1000}>
                        <div style={{ margin: "auto" }}>
                          <IconContext.Provider value={{ color: color_80.error[2], size: "65px" }}>
                            <BiError />
                          </IconContext.Provider>
                        </div>
                        <p style={{ color: color_80.error[2], fontWeight: 500 }}>{dataErr}</p>
                      </TableDataCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableDataCell style={{ padding: ROW_PADDING_OPTIONS[rowDensity], height: 256, textAlign: "center" }} colSpan={1000}>
                        <div style={{ width: "50px", margin: "auto" }}>
                          <EmptyDataIcon outline={color.font[2]} fill={color_30.panel_fg[0]} />
                        </div>
                        <p style={{ fontSize: "18px", margin: "16px 0" }}>No {dataTypeName} Found</p>
                        <p>{getTableFilters(columnFiltersDebounced) ? "Try adjusting your filters to find what you're looking for." : emptyDataMsg}</p>
                      </TableDataCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </div>
      <PaginationContainer>{getPageButtons()}</PaginationContainer>
      <Drawer
        placement="right"
        level={null}
        open={columnSettingsOpen}
        onClose={() => setColumnSettingsOpen(false)}
        onHandleClick={() => setColumnSettingsOpen(!columnSettingsOpen)}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "18px",
                height: "18px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <FilterIcon fill={color.font_bold[2]} />
            </div>
            Column Settings
            <DrawCloseButton
              onClick={() => {
                setColumnSettingsOpen(false);
              }}
              aria-label={`Close settings`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <ColumnList table={table} defaultVisibility={defaultTableSettings?.visibility} />
          </DrawBody>
        </DrawContent>
      </Drawer>
    </>
  );
};

const loadOptions = (inputName: string, inputValue: string, callback: any) => {
  fetchAutoComplete(inputName, inputValue).then((options: any) => {
    callback(options);
  });
};

type AutoCompleteFilterType = {
  type: string;
  key: string;
  selected: any;
};

type ContainsStringFilterType = {
  type: string;
  value: string;
};

function Filter({ column, short_date }: { column: Column<any, unknown>; short_date: string }) {
  const filterType = (column.columnDef.meta as any)?.filterType;
  const loadOptionsKey = (column.columnDef.meta as any)?.loadOptionsKey;
  const filterKey = (column.columnDef.meta as any)?.filterKey;
  const selectKey = (column.columnDef.meta as any)?.selectKey ?? "label";
  const selectOptions = (column.columnDef.meta as any)?.selectOptions;
  const title = (column.columnDef.meta as any)?.title;

  const columnFilterValue = column.getFilterValue();

  if (filterType === "number") {
    return (
      <div style={{ display: "flex" }}>
        <DebouncedInput
          title={title}
          style={{ width: "60px" }}
          type="number"
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min`}
        />
        <DebouncedInput
          title={title}
          style={{ width: "60px" }}
          type="number"
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={"Max"}
        />
      </div>
    );
  } else if (filterType === "autoComplete") {
    return (
      <div>
        <AsyncSelect
          title={title}
          name={loadOptionsKey}
          defaultOptions={true}
          closeMenuOnSelect={true}
          isClearable={true}
          isMulti={false}
          isSearchable={true}
          value={(columnFilterValue as AutoCompleteFilterType)?.selected ?? undefined}
          loadOptions={(inputValue: any, callback: any) => loadOptions(loadOptionsKey, inputValue, callback)}
          onChange={(selected: any) => {
            if (selected) {
              column.setFilterValue((prev: AutoCompleteFilterType) => ({ ...prev, selectKey, filterKey, type: "select", selected }));
            } else {
              column.setFilterValue((prev: AutoCompleteFilterType) => ({ ...prev, selectKey, filterKey, type: "select", selected: undefined }));
            }
          }}
          placeholder="Select..."
        />
      </div>
    );
  } else if (filterType === "autoCompleteInList") {
    return (
      <div>
        <AsyncSelect
          title={title}
          name={loadOptionsKey}
          defaultOptions={true}
          closeMenuOnSelect={true}
          isClearable={true}
          isMulti={false}
          isSearchable={true}
          value={(columnFilterValue as AutoCompleteFilterType)?.selected ?? undefined}
          loadOptions={(inputValue: any, callback: any) => loadOptions(loadOptionsKey, inputValue, callback)}
          onChange={(selected: any) => {
            if (selected) {
              column.setFilterValue((prev: AutoCompleteFilterType) => ({ ...prev, selectKey, filterKey, type: "inList", selected }));
            } else {
              column.setFilterValue((prev: AutoCompleteFilterType) => ({ ...prev, selectKey, filterKey, type: "inList", selected: undefined }));
            }
          }}
          placeholder="Select..."
        />
      </div>
    );
    // TODO: Change all API date formats to ISO 8601 and then remove dateRangeUnix filters
  } else if (filterType === "dateRangeUnix") {
    return (
      <div style={{ position: "relative" }}>
        <FlatpickrRangePicker
          title={title}
          options={{ mode: "range", formatDate: (date: any) => moment(date).format(short_date) }}
          value={(columnFilterValue as any)?.date ?? []}
          onClose={(date: any) => column.setFilterValue({ type: "dateRangeUnix", date })}
        />
      </div>
    );
  } else if (filterType === "dateRange") {
    return (
      <div style={{ position: "relative" }}>
        <FlatpickrRangePicker
          title={title}
          options={{ mode: "range", formatDate: (date: any) => moment(date).format(short_date) }}
          value={(columnFilterValue as any)?.date ?? []}
          onClose={(date: any) => column.setFilterValue({ type: "dateRange", date })}
        />
      </div>
    );
  } else if (filterType === "select") {
    return (
      <div style={{ position: "relative" }}>
        <Select
          title={title}
          closeMenuOnSelect={true}
          isClearable={true}
          isMulti={false}
          isSearchable={true}
          value={(columnFilterValue as AutoCompleteFilterType)?.selected ?? undefined}
          options={selectOptions}
          onChange={(selected: any) => {
            if (selected) {
              column.setFilterValue((prev: AutoCompleteFilterType) => ({
                ...prev,
                selectKey,
                type: "select",
                selected,
                comparisonOperator: selected?.comparisonOperator ?? "eq",
              }));
            } else {
              column.setFilterValue((prev: AutoCompleteFilterType) => ({
                ...prev,
                selectKey,
                type: "select",
                selected: undefined,
                comparisonOperator: selected?.comparisonOperator ?? "eq",
              }));
            }
          }}
          placeholder="Select..."
        />
      </div>
    );
  } else if (filterType === "string") {
    return (
      <div>
        <DebouncedInput
          title={title}
          type="text"
          value={(columnFilterValue as ContainsStringFilterType)?.value ?? ""}
          onChange={(value) => column.setFilterValue({ filterKey, type: "string", value })}
          placeholder={`Search...`}
        />
      </div>
    );
  } else {
    return <></>;
  }
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return <FilterInput {...props} value={value} onChange={(e: any) => setValue(e.target.value)} />;
}

export default NewTable;
