import Quagga, { QuaggaJSCodeReader, QuaggaJSResultObject } from "@ericblade/quagga2";
import axios, { CancelTokenSource } from "axios";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { addContents } from "../../services/contents";
import errToStr from "../../util/errToStr";
import { exists, maxLength } from "../../util/formValidations";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import { FormError, FormInput } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { QrScanButton } from "./styles";
// @ts-ignore
import QrCodeReader from "@ericblade/quagga2-reader-qr";
import matchSorter from "match-sorter";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../services/autoComplete";
import { getAccount } from "../../services/localStorage";
import DeleteIcon from "../../svgs/DeleteIcon";
import calculateDistance from "../../util/calculateDistance";
import sortTags from "../../util/sortTags";
import Bold from "../Bold";
import ScreenHeading from "../ScreenHeading";
import { AsyncCreatableSelect, AsyncSelect } from "../Select";
import Table from "../Table";
import Tag from "../Tag";
import Tooltip from "../Tooltip";

Quagga.registerReader("qr_code", QrCodeReader);

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface Place {
  latitude: number;
  longitude: number;
  value: string;
  label: string;
}

function getMedian(arr: any[]) {
  arr.sort((a: number, b: number) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes: any[]) {
  const errors = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
}

const defaultConstraints = {
  width: 640,
  height: 480,
};

const defaultLocatorSettings = {
  patchSize: "medium",
  halfSample: true,
  willReadFrequently: true,
};

const defaultDecoders: (QuaggaJSCodeReader | "qr_code")[] = [
  "qr_code",
  "code_128_reader",
  "ean_reader",
  "ean_8_reader",
  "code_39_reader",
  "code_39_vin_reader",
  "codabar_reader",
  "upc_reader",
  "upc_e_reader",
  "i2of5_reader",
  "2of5_reader",
  "code_93_reader",
];

const defaultItem = {
  id: null,
  identifier: "",
  name: "",
  description: "",
  placeAdded: null,
  contentTags: [],
};

const AddContentsModal: FC<any> = ({ manifestId, placeId, placeName, onSuccess, onClose, modalOpen, setModalOpen }) => {
  const { color } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);
  const scannerRef = useRef<any>(undefined);

  const [formData, setFormData] = useState<any>(defaultItem);
  const [formErrors, setFormErrors] = useState<any>({});

  const [tempId, setTempId] = useState<number>(0);
  const [items, setItems] = useState<any>([]);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [placeAddedLoading, setPlaceAddedLoading] = useState<boolean>(false);

  const [scanning, setScanning] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Function to find the closest place within 1 km
  const findClosestPlace = (places: Place[], coords: Coordinates): Place | null => {
    try {
      // Sort the list of places by distance from the user's coordinates
      const sortedPlaces = places.sort((a, b) => {
        // Calculate Euclidean distances from the user's coordinates to each place
        const aDist = Math.sqrt(Math.pow(a.latitude - coords.latitude, 2) + Math.pow(a.longitude - coords.longitude, 2));
        const bDist = Math.sqrt(Math.pow(b.latitude - coords.latitude, 2) + Math.pow(b.longitude - coords.longitude, 2));

        // Return comparison result
        return aDist > bDist ? 1 : bDist > aDist ? -1 : 0;
      });

      // Check if the closest place is within 1 km of the user's coordinates
      if (sortedPlaces.length > 0) {
        const closestPlace = sortedPlaces[0];
        const distanceToClosestPlace = calculateDistance(closestPlace.latitude, closestPlace.longitude, coords.latitude, coords.longitude);

        if (distanceToClosestPlace !== null && distanceToClosestPlace <= 1) {
          return closestPlace;
        }
      }

      // Return null if no place is within 1 km of the user's coordinates
      return null;
    } catch (error) {
      console.error("An error occurred while finding the closest place within 1 km:", error);
      return null;
    }
  };

  // If no placeAdded has been input, get the user's current location and find nearest place to set as placeAdded.
  // If the user has disabled location services, use the placeId and placeName passed in as props.
  useEffect(() => {
    if (formData.placeAdded == null) {
      setPlaceAddedLoading(true);
      navigator.geolocation.getCurrentPosition(
        (pos: any) => {
          const { coords } = pos;
          fetchAutoComplete("places", "")
            .then((response) => {
              const closestPlace = findClosestPlace(response, coords);

              if (closestPlace) {
                setFormData((prev: any) => ({
                  ...prev,
                  placeAdded: {
                    value: closestPlace.value,
                    label: closestPlace.label,
                  },
                }));
              } else {
                if (placeId !== undefined && placeName !== undefined) {
                  setFormData((prev: any) => ({
                    ...prev,
                    placeAdded: {
                      value: placeId,
                      label: placeName,
                    },
                  }));
                }
              }

              setPlaceAddedLoading(false);
            })
            .catch((err) => {
              if (!axios.isCancel(err)) {
                setSubmittingErr(errToStr(err));
                setPlaceAddedLoading(false);
              }
            });
        },
        () => {
          if (placeId !== undefined && placeName !== undefined) {
            setFormData((prev: any) => ({
              ...prev,
              placeAdded: {
                value: placeId,
                label: placeName,
              },
            }));
          }
          setPlaceAddedLoading(false);
        }
      );
    }
  }, []);

  // On barcode detect, add result to ID input and stop scanning
  const onDetected = (result: any) => {
    setFormData((prev: any) => ({ ...prev, identifier: result }));
    setScanning(false);
  };

  const errorCheck = useCallback(
    (result: { codeResult: { decodedCodes: any[]; code: any } }) => {
      if (!onDetected) {
        return;
      }
      // @ts-ignore
      if (result.codeResult.format !== "qr_code") {
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        // if Quagga is at least 92% certain that it read correctly, then accept the code.
        if (err < 0.08) {
          onDetected(result.codeResult.code);
        }
      } else {
        onDetected(result.codeResult.code);
      }
    },
    [onDetected]
  );

  const handleProcessed = (result: QuaggaJSResultObject) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.font = "24px Arial";
    drawingCtx.fillStyle = "green";

    if (result) {
      if (result.boxes) {
        // @ts-ignore
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
        result.boxes
          .filter((box: any) => box !== result.box)
          .forEach((box: any[]) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "purple", lineWidth: 2 });
          });
      }
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "blue", lineWidth: 2 });
      }
    }
  };

  useEffect(() => {
    if (scanning === false) {
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
      Quagga.stop();
    } else {
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            constraints: defaultConstraints,
            target: scannerRef.current,
            willReadFrequently: true,
          },
          locator: defaultLocatorSettings,
          numOfWorkers: navigator.hardwareConcurrency || 0,
          // @ts-ignore
          decoder: { readers: defaultDecoders },
          locate: true,
          debug: false,
        },
        (err) => {
          Quagga.onProcessed(handleProcessed);

          if (err) {
            return console.log("Error starting Quagga:", err);
          }
          if (scannerRef && scannerRef.current) {
            Quagga.start();
          }
        }
      );
      Quagga.onDetected(errorCheck);
      return () => {
        Quagga.offDetected(errorCheck);
        Quagga.offProcessed(handleProcessed);
        Quagga.stop();
      };
    }
  }, [scanning, onDetected, scannerRef, errorCheck]);

  const validateItem = (item: any) => {
    const names = Object.keys(item);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "identifier":
          currValid = maxLength(name, value, 256, setFormErrors);
          break;

        case "name":
          currValid = maxLength(name, value, 256, setFormErrors);
          break;

        case "description":
          currValid = maxLength(name, value, 1024, setFormErrors);
          break;

        case "placeAdded":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatValidateItems = () => {
    if (items.length > 0) {
      let allValid = true;
      let currValid = true;

      const contents = items.map((item: any) => {
        currValid = validateItem(item);
        allValid = allValid && currValid;

        return {
          identifier: item.identifier.trim(),
          manifestId,
          name: item.name.trim(),
          description: item.description.trim(),
          placeAdded: item.placeAddedId !== undefined ? item.placeAddedId : null,
          contentTags: item.contentTags ? item.contentTags.map((tag: any) => tag.label) : [],
        };
      }, []);

      const body = {
        organisationId: getAccount().organisationId,
        manifestId,
        contents,
      };

      return [body, allValid];
    } else {
      return [[], false];
    }
  };

  const handleSubmit = () => {
    const [body, valid] = formatValidateItems();

    if (valid) {
      setSubmitting(true);
      addContents(source, body)
        .then((response) => {
          onSuccess(response);
          setSubmittedMsg("Contents Added");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleEnableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setSubmittedMsg("");
    setSubmittingErr("");
    setScanning(true);
  };

  const handleDisableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setScanning(false);
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
    if (onClose) onClose();
  };

  const handleAddItem = (e?: any) => {
    if (e) e.preventDefault();
    if (formData.placeAdded !== null) {
      setItems((prev: any) => [
        ...prev,
        { tempId: tempId, ...formData, placeAddedId: formData.placeAdded.value, placeAddedName: formData.placeAdded.label, dateAdded: new Date() },
      ]);
      setTempId(tempId + 1);
      setFormData((prev: any) => ({ ...prev, identifier: "" }));
    } else {
      setFormErrors((prev: any) => ({ ...prev, placeAdded: "Place Added is required" }));
    }
  };

  const columns = [
    {
      Header: "Actions",
      minWidth: 110,
      maxWidth: 110,
      filterable: false,
      sortable: false,
      Cell: ({ original }: any) => {
        return (
          <>
            <Tooltip content="Delete">
              <div
                onClick={() => {
                  setItems((prev: any) => prev.filter((item: any) => item.tempId !== original.tempId));
                }}
                style={{ display: "flex", width: "100%", justifyContent: "center", height: "21px", padding: 0, cursor: "pointer" }}
              >
                <DeleteIcon fill={color.danger[2]} />
              </div>
            </Tooltip>
          </>
        );
      },
      Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
    },
    {
      id: "identifier",
      Header: "ID",
      accessor: "identifier",
      minWidth: 180,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["identifier"],
        }),
      filterAll: true,
    },
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      minWidth: 180,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["name"],
        }),
      filterAll: true,
    },
    {
      id: "description",
      Header: "Description",
      accessor: "description",
      minWidth: 180,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["description"],
        }),
      filterAll: true,
    },
    {
      id: "placeAddedName",
      Header: "Place Added",
      accessor: "placeAddedName",
      minWidth: 180,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["placeAddedName"],
        }),
      filterAll: true,
    },
    {
      id: "contentTags",
      minWidth: 160,
      maxWidth: 160,
      Header: "Content Tags",
      accessor: "contentTags",
      style: { textOverflow: "unset", whiteSpace: "normal" },
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["contentTags"],
        }),
      filterAll: true,
      Cell: (props: any) =>
        props.original.contentTags ? (
          props.original.contentTags
            .sort(sortTags)
            .map((tag: any) => <Tag key={tag.label} name={tag.label} description={tag.description || ""} colour={tag.colour || color.primary[0]} />)
        ) : (
          <></>
        ),
    },
    {
      id: "dateAdded",
      accessor: "dateAdded",
      show: false,
    },
  ];

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={handleSubmit}
      onClose={handleClose}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title="Add Contents"
      success={submittedMsg}
      error={submittingErr}
      body={
        <LoadingContainer loading={submitting}>
          <div ref={scannerRef} style={{ position: "relative", display: scanning ? "flex" : "none" }}>
            <canvas
              className="drawingBuffer"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                border: "1px solid gray",
              }}
            />
          </div>
          <div style={{ display: scanning ? "none" : "block" }}>
            <form noValidate onSubmit={handleAddItem}>
              <ModalFormContainer>
                <label>ID</label>
                <QrScanButton title="Scan QR Code" onClick={handleEnableScan} />
                <FormInput
                  type="text"
                  name="identifier"
                  placeholder="XXXXXX"
                  value={formData.identifier}
                  error={formErrors.identifier}
                  onChange={handleChange}
                  required={true}
                />
                <FormError error={formErrors.identifier}>{formErrors.identifier}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Name</label>
                <FormInput type="text" name="name" placeholder="Name" value={formData.name} error={formErrors.name} onChange={handleChange} required={true} />
                <FormError error={formErrors.name}>{formErrors.name}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Description</label>
                <FormInput
                  type="text"
                  name="description"
                  placeholder="Description"
                  value={formData.description}
                  error={formErrors.description}
                  onChange={handleChange}
                />
                <FormError error={formErrors.description}>{formErrors.description}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Place Added</label>
                <AsyncSelect
                  closeMenuOnSelect={true}
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.placeAdded}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  name="placeAdded"
                  onChange={handleSelectChange}
                  placeholder="Select..."
                  value={formData.placeAdded}
                />
                <FormError error={formErrors.placeAdded}>{formErrors.placeAdded}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Content Tags</label>
                <AsyncCreatableSelect
                  name="contentTags"
                  defaultOptions={true}
                  isMulti={true}
                  isClearable={true}
                  isError={formErrors.contentTags}
                  value={formData.contentTags}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("contentTags", inputValue, callback)}
                  onChange={handleSelectChange}
                  isDisabled={placeAddedLoading}
                  isLoading={placeAddedLoading}
                  placeholder="Select..."
                />
                <FormError error={formErrors.contentTags}>{formErrors.contentTags}</FormError>
              </ModalFormContainer>
              {items.length > 0 && (
                <>
                  <ScreenHeading>{items.length} Contents Added</ScreenHeading>
                  <Table
                    filterable={true}
                    style={{ clear: "both" }}
                    data={items}
                    columns={columns}
                    ref={tableRef}
                    defaultSorted={[
                      {
                        id: "dateAdded",
                        desc: true,
                      },
                    ]}
                    defaultPageSize={5}
                  />
                </>
              )}
              <input type="submit" hidden />
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            {scanning ? (
              <OutlineBtn onClick={handleDisableScan} width="100%">
                Back
              </OutlineBtn>
            ) : items.length > 0 ? (
              <>
                <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
                <OutlineBtn onClick={handleAddItem}>Add</OutlineBtn>
                <PrimaryBtn onClick={handleSubmit}>Submit</PrimaryBtn>
              </>
            ) : (
              <>
                <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
                <PrimaryBtn onClick={handleAddItem}>Add</PrimaryBtn>
              </>
            )}
          </>
        )
      }
    />
  );
};

export default AddContentsModal;
