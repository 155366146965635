import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../services/autoComplete";
import { getAccount } from "../../services/localStorage";
import { putManifest } from "../../services/manifests";
import InfoIcon from "../../svgs/Legend";
import { isAdmin } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { exists } from "../../util/formValidations";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import Checkbox from "../Checkbox";
import FlatpickrPicker from "../FlatpickrPicker";
import { FormError, FormInput, InfoIconContainer } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncSelect } from "../Select";
import Tooltip from "../Tooltip";

const defaultManifest = {
  id: null,
  navifyTransportId: null,
  startPlace: null,
  targetPlace: null,
  deadline: "",
  startTime: moment().unix() * 1000,
  startingUser: null,
  endUser: null,
  autoStart: false,
  autoEnd: false,
};

const initManifestForm = (manifest: any, startTime: number) => {
  const formData = {
    ...defaultManifest,
    ...manifest,
    startTime,
    tracker: manifest.sensorId
      ? {
          value: manifest.sensorId,
          label: manifest.sensorId,
        }
      : null,
    startPlace: manifest.startPlaceId
      ? {
          value: manifest.startPlaceId,
          label: manifest.startPlaceName,
        }
      : null,
    targetPlace: manifest.targetPlaceId
      ? {
          value: manifest.targetPlaceId,
          label: manifest.targetPlaceName,
        }
      : null,
    deadline: manifest.deadlineTimeUnix ? manifest.deadlineTimeUnix * 1000 : "",
    startingUser: manifest.startingUserId
      ? {
          value: manifest.startingUserId,
          label: manifest.startingUserName,
        }
      : null,
    endUser: manifest.endUserId
      ? {
          value: manifest.endUserId,
          label: manifest.endUserName,
        }
      : null,
  };

  return formData;
};

const StartManifestModel: FC<any> = ({ manifest, onSuccess, modalOpen, setModalOpen }) => {
  const { color, short_datetime } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(initManifestForm(manifest, moment().unix() * 1000));
  const [formErrors, setFormErrors] = useState<any>({});

  const [usersLoading, setUsersLoading] = useState<boolean>(false);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // If no startingUser has been input, get the user's id and match it to the correct user.
  useEffect(() => {
    if (formData.startingUser == null) {
      setUsersLoading(true);
      fetchAutoComplete("users", "")
        .then((response) => {
          const matchingUser = response.find((user: any) => user.value === getAccount().id);

          if (matchingUser) {
            setFormData((prev: any) => ({
              ...prev,
              startingUser: {
                value: matchingUser.value,
                label: matchingUser.label,
              },
            }));
          }

          setUsersLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setUsersLoading(false);
          }
        });
    }
  }, []);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "startPlace":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        case "startingUser":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        case "targetPlace":
          if (formData.autoEnd === true) currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      id: formData.id,
      binaryId: formData.tracker?.value,
      name: formData.name,
      description: formData.description,
      startTime: Math.round(formData.startTime / 1000).toString(),
      startPlaceId: formData.startPlace?.value,
      targetPlaceId: formData.targetPlace?.value,
      deadlineTime: formData.deadline ? Math.round(formData.deadline / 1000).toString() : undefined,
      startingUserId: formData.startingUser ? formData.startingUser.value : undefined,
      endUserId: formData.endUser ? formData.endUser.value : undefined,
      autoEnd: formData.autoEnd,
    };

    return formattedData;
  };

  const handleStart = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      putManifest(source, body)
        .then(() => {
          if (onSuccess) onSuccess();
          setSubmittedMsg("Manifest Started");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const handleDateChange = (date: any) => {
    setFormData((prev: any) => ({ ...prev, deadline: date[0] }));
    setFormErrors((prev: any) => ({ ...prev, deadline: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  // Auto-populates select input on search.
  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={handleStart}
      onClose={handleClose}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title="Start Manifest"
      success={submittedMsg}
      error={submittingErr}
      body={
        <LoadingContainer loading={submitting}>
          <div>
            <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
              <ModalFormContainer>
                <label style={{ float: "left" }}>Start Time</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip content="The time at which this Manifest will start" interactive={true} touch={true} appendTo={document.body}>
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <FormInput
                  autoComplete="no"
                  type="text"
                  name="startTime"
                  value={formData.startTime ? moment(formData.startTime).format(short_datetime) : ""}
                  disabled={true}
                />
                <FormError error={formErrors.startTime}>{formErrors.startTime}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Start Place</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip content="The location where this Manifest journey begins" interactive={true} touch={true} appendTo={document.body}>
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <AsyncSelect
                  name="startPlace"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.startPlace}
                  value={formData.startPlace}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
                <FormError error={formErrors.startPlace}>{formErrors.startPlace}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Starting User</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    content="The User responsible for initiating the Manifest - this can be set at a later time if needed"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <AsyncSelect
                  name="startingUser"
                  defaultOptions={true}
                  isError={formErrors.startingUser}
                  value={formData.startingUser}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("users", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                  isDisabled={!isAdmin() || usersLoading}
                  isLoading={usersLoading}
                />
                <FormError error={formErrors.startingUser}>{formErrors.startingUser}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Target Destination</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    content="The intended final location for this Manifest - this can be set later if needed"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <AsyncSelect
                  name="targetPlace"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.targetPlace}
                  value={formData.targetPlace}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
                <FormError error={formErrors.targetPlace}>{formErrors.targetPlace}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true} style={{ alignContent: "center" }}>
                <Checkbox
                  style={{ display: "inline-block" }}
                  name="autoEnd"
                  label="Auto End"
                  checked={formData.autoEnd}
                  onChange={handleCheckboxChange}
                  disabled={!isAdmin() || formData.endTimeUnix !== undefined}
                />
                <div
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    marginBottom: "-3px",
                  }}
                >
                  <Tooltip
                    content="If enabled, the Manifest status will automatically change from Started → Finished when the Tracker reaches the Target Destination"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Ending User</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    content="The User accountable for receiving and ending the Manifest upon arrival - this can be set at a later time if needed"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <AsyncSelect
                  name="endUser"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.endUser}
                  value={formData.endUser}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("users", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                  isDisabled={!isAdmin() || formData.endTimeUnix !== undefined}
                />
                <FormError error={formErrors.endUser}>{formErrors.endUser}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label style={{ float: "left" }}>Deadline</label>
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    content="The expected time by which this Manifest should reach its target destination"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </Tooltip>
                </div>
                <FlatpickrPicker
                  enableTime={true}
                  name="deadline"
                  value={formData.deadline}
                  onChange={handleDateChange}
                  onClose={handleDateChange}
                  onClear={() => handleDateChange([undefined])}
                  disabled={!isAdmin()}
                />
                <FormError error={formErrors.deadline}>{formErrors.deadline}</FormError>
              </ModalFormContainer>
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
            <PrimaryBtn onClick={handleStart}>Start</PrimaryBtn>
          </>
        )
      }
    />
  );
};

export default StartManifestModel;
