import axios, { CancelTokenSource } from "axios";
import { getToken } from "../localStorage";

export const postFillKeg = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/fillkeg",
    data: JSON.stringify(body),
  });

  return data;
};

export const changeBeerBatch = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + "/api/fillkeg/changebeerbatch",
    data: JSON.stringify(body),
  });

  return data;
};
