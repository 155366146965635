import React, { FC, useEffect, useState, useRef } from "react";
import { getAccount, getTheme, getToken, saveAccount } from "./services/localStorage";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./components/Themes";
import dark from "./components/Themes/dark";
import light from "./components/Themes/light";
import useForceUpdate from "./util/forceUpdate";
import { toast } from "react-toastify";
import GlobalStyles from "./components/GlobalStyles";
import AutocompleteStyling from "./components/GlobalStyles/autocomplete";
import ReactCollapseStyling from "./components/GlobalStyles/rc-collapse";
import RcDrawerStyling from "./components/GlobalStyles/rc-drawer";
import RcSliderStyling from "./components/GlobalStyles/rc-slider";
import ReactColorStyling from "./components/GlobalStyles/react-color";
import ReactDatesStyling from "./components/GlobalStyles/react-dates";
import ReactSelectOptionStyles from "./components/GlobalStyles/react-select-option";
import ReactGoogleMapsStyling from "./components/GlobalStyles/react-google-maps";
import ReactTableStyling from "./components/GlobalStyles/react-table";
import TippyCSS from "./components/GlobalStyles/tippy";
import ToastStyling from "./components/GlobalStyles/react-toastify";
import FlatpickrStyles from "./components/GlobalStyles/react-flatpickr";
import ReactTabsStyling from "./components/GlobalStyles/react-tabs";
import AppRoutes from "./AppRoutes";
import StateContext from "./components/StateContext";
import { postEditAccount } from "./services/editAccount";
import axios from "axios";
import ReactModal from "react-modal";

const App: FC = () => {
  const [theme, setTheme] = useState<string>(getTheme());
  const isInitialMount = useRef(true);

  // When the theme changes update the user's account info in the back-end
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const lightMode = !!getAccount().lightMode;
      if (getToken()) postEditAccount(source, { lightMode }).then((response) => saveAccount(response));
    }

    return () => {
      source.cancel();
    };
  }, [theme]);

  // listen for account info or theme updates and update the active theme
  useEffect(() => {
    document.addEventListener("accountinfoupdated", updateTheme);

    return () => {
      document.removeEventListener("accountinfoupdated", updateTheme);
    };
  }, []);

  const updateTheme = () => {
    setTheme(getTheme());
    const themeChangedEvent = new Event("themechanged");
    document.dispatchEvent(themeChangedEvent);
  };

  // Calling this function causes this component to re-render
  const forceUpdate = useForceUpdate();

  toast.configure({
    position: "top-center",
    autoClose: 5000,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    draggable: true,
    pauseOnHover: true,
  });

  ReactModal.setAppElement("#root");

  return (
    <StateContext.Provider value={{ theme, setTheme, forceUpdate }}>
      <ThemeProvider theme={theme === "dark" ? dark : light}>
        <GlobalStyles />
        <AutocompleteStyling />
        <ReactCollapseStyling />
        <RcDrawerStyling />
        <RcSliderStyling />
        <ReactColorStyling />
        <ReactDatesStyling />
        <ReactSelectOptionStyles />
        <ReactTableStyling />
        <TippyCSS />
        <ToastStyling />
        <FlatpickrStyles />
        <ReactTabsStyling />
        <ReactGoogleMapsStyling />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </StateContext.Provider>
  );
};

export default App;
