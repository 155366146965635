import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { getManifestsTableSettings, manifestsTableDefaults, saveManifestsTableSettings } from "../../services/localStorage";
import { getManifests } from "../../services/manifests";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { getTableFilters } from "../../util/urlParamFilters";
import Badge from "../Badge";
import { PrimaryBtn } from "../Buttons";
import ChartHeading from "../ChartHeading";
import CreateManifestModel from "../CreateManifestModel";
import EditManifestModel from "../EditManifestModel";
import { PrimaryIconBtn } from "../IconButtons";
import NewTable from "../NewTable";
import { DesktopDiv, MobileDiv } from "../NewTable/styles";
import Tooltip from "../Tooltip";
import { MenuButton, MenuList } from "../Tooltip/styles";
import { ChartWrapper, FlexRow } from "../TrackerScreen/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

type Manifest = {
  id: number;
  navifyTransportId: string;
  sensorId: string;
  name: string;
  description: string;
  startTime: string;
  startTimeUnix: number;
  endTime: string;
  endTimeUnix: number;
  deadlineTime: string;
  deadlineTimeUnix: number;
  startPlaceId: number;
  endPlaceId: number;
  targetPlaceId: number;
  startingUserId: string;
  endUserId: string;
  status: string;
  createdUserId: string;
  createdTime: string;
  createdTimeUnix: number;
  startPlaceName: string;
  endPlaceName: string;
  targetPlaceName: string;
  startingUserName: string;
  endUserName: string;
  createdUserName: string;
  contentsQuantity: number;
};

const TrackerManifestsTable: FC<any> = ({ trackerId = "", filterDates, fetchCount, placeId, placeName }) => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "createdTimeUnix", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedManifest, setSelectedManifest] = useState<any>(undefined);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<any>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getManifestsTableSettings() ? getManifestsTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // If trackerId is not empty add filters to only show:
  // - Manifests for the specific trackerId
  // - Manifests created within the date range
  // - Manifests closed within the date range
  // - Active Manifests (Started/created but not finished)
  const trackerPageFilters = (filters: any) => {
    if (trackerId === "") return filters;

    const filtersCopy = JSON.parse(JSON.stringify(filters));
    const filter = filtersCopy.find((x: any) => x.id === "sensorId");
    if (filter) filter.value = { type: "string", value: trackerId };
    else filtersCopy.push({ id: "sensorId", value: { type: "string", value: trackerId } });

    if (filterDates !== undefined) {
      filtersCopy.push({
        id: "createdTimeUnix",
        value: {
          type: "dateRangeUnix",
          logic: "OR",
          date: [filterDates.start, filterDates.end],
        },
      });

      filtersCopy.push({
        id: "endTimeUnix",
        value: {
          type: "dateRangeUnix",
          logic: "OR",
          date: [filterDates.start, filterDates.end],
        },
      });

      filtersCopy.push({
        id: "status",
        value: {
          selectKey: "value",
          type: "select",
          logic: "OR",
          selected: {
            value: "Not Started",
            label: "Not Started",
            colour: "#0b9300",
          },
          comparisonOperator: "eq",
        },
      });

      filtersCopy.push({
        id: "status",
        value: {
          selectKey: "value",
          type: "select",
          logic: "OR",
          selected: {
            value: "Started, OK",
            label: "Started, OK",
            colour: "#0b9300",
          },
          comparisonOperator: "eq",
        },
      });
    }

    return filtersCopy;
  };

  const fetchData = () => {
    setDataLoading(true);
    setDataErr("");

    getManifests(source, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(trackerPageFilters(columnFiltersDebounced)) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    setDataErr("");

    getManifests(source, { orderBy: sorting, filters: getTableFilters(trackerPageFilters(columnFiltersDebounced)) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Manifest List.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    let headers = [];

    headers = ["Manifest ID", "Status", "Created By", "Place Started", "Quantity", "Date Created"];

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.id,
          row.status,
          row.createdUserName,
          row.startPlaceName,
          row.contentsQuantity,
          row.createdTimeUnix ? moment.unix(row.createdTimeUnix).format(long_datetime) : "",
        ];

        return rows;
      }, []),
    ];
  };

  const columns = React.useMemo<ColumnDef<Manifest>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <MenuButton
                      onClick={() => {
                        setSelectedManifest(row.original);
                        setEditModalOpen(true);
                      }}
                    >
                      Edit Manifest
                    </MenuButton>
                  ) : (
                    <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                      <div
                        style={{
                          cursor: "not-allowed",
                          userSelect: "none",
                        }}
                      >
                        <MenuButton disabled={true}>Edit Manifest</MenuButton>
                      </div>
                    </Tooltip>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        header: "Manifest",
        accessorKey: "name",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/manifests/${props.row.original.id}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 130,
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: (props: any) => (
          <div style={{ textAlign: "center" }}>
            <Badge title={props.getValue()} background={props.getValue().includes("WARNING") ? color.danger_dark[2] : color.success_dark[2]}>
              {props.getValue()}
            </Badge>
          </div>
        ),
        meta: {
          filterType: "select",
          loadOptionsKey: "pickup",
          selectKey: "value",
          selectOptions: [
            { value: "Not Started", label: "Not Started", colour: color.success_dark[2] },
            { value: "Started, OK", label: "Started, OK", colour: color.success_dark[2] },
            { value: "Started, WARNING", label: "Started, WARNING", colour: color.danger_dark[2] },
            { value: "Finished, OK", label: "Finished, OK", colour: color.success_dark[2] },
            { value: "Finished, WARNING", label: "Finished, WARNING", colour: color.danger_dark[2] },
          ],
        },
        filterFn: undefined,
        size: 190,
      },
      {
        header: "Created By",
        accessorKey: "createdUserName",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "Place Started",
        accessorKey: "startPlaceName",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/places/${props.row.original.startPlaceId}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Contents Quantity",
        accessorKey: "contentsQuantity",
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 230,
      },
      {
        header: "Date Created",
        accessorKey: "createdTimeUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 180,
      },
    ],
    [color]
  );

  // Only render the manifest table if there is data, no filters and not loading
  return (
    <div style={{ display: data.length === 0 && getTableFilters(columnFiltersDebounced) === undefined && dataLoading === false ? "none" : "block" }}>
      <FlexRow>
        <ChartWrapper>
          <ChartHeading>Manifests</ChartHeading>
          <NewTable
            data={data}
            count={count}
            dataErr={dataErr}
            dataLoading={dataLoading}
            columns={columns}
            sorting={sorting}
            setSorting={setSorting}
            columnFilters={columnFilters}
            columnFiltersDebounced={columnFiltersDebounced}
            setColumnFilters={setColumnFilters}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPagination={setPagination}
            fetchData={fetchData}
            fetchDataDependencies={[filterDates, fetchCount]}
            fetchCsv={fetchCsv}
            defaultTableSettings={manifestsTableDefaults}
            getTableSettings={getManifestsTableSettings}
            saveTableSettings={saveManifestsTableSettings}
            dataTypeName="Manifests"
            emptyDataMsg="Create a manifest above to get started"
            TableButtons={
              <div style={{ display: "flex" }}>
                {isAdminOrUser() ? (
                  <>
                    <DesktopDiv>
                      <PrimaryBtn onClick={() => setCreateModalOpen(true)}>Create</PrimaryBtn>
                    </DesktopDiv>
                    <MobileDiv>
                      <Tooltip content="Create">
                        <PrimaryIconBtn onClick={() => setCreateModalOpen(true)}>
                          <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                            <HiPlus />
                          </IconContext.Provider>
                        </PrimaryIconBtn>
                      </Tooltip>
                    </MobileDiv>
                  </>
                ) : (
                  <>
                    <DesktopDiv>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                          <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                        </div>
                      </Tooltip>
                    </DesktopDiv>
                    <MobileDiv>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                          <PrimaryIconBtn>
                            <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                              <HiPlus />
                            </IconContext.Provider>
                          </PrimaryIconBtn>
                        </div>
                      </Tooltip>
                    </MobileDiv>
                  </>
                )}
              </div>
            }
          />
        </ChartWrapper>
      </FlexRow>

      {createModalOpen && (
        <CreateManifestModel
          trackerId={trackerId}
          onSuccess={fetchData}
          modalOpen={createModalOpen}
          setModalOpen={setCreateModalOpen}
          placeId={placeId}
          placeName={placeName}
        />
      )}
      {editModalOpen && <EditManifestModel manifest={selectedManifest} onSuccess={fetchData} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
    </div>
  );
};

export default TrackerManifestsTable;
