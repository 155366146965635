import axios, { CancelTokenSource } from "axios";
import { ChangeEvent, FC, FormEvent, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { saveAccount, saveToken } from "../../services/localStorage";
import { postToken } from "../../services/token";
import errToStr from "../../util/errToStr";
import { getLogo } from "../../util/getLogo";
import getNextRoute from "../../util/getNextRoute";
import isTouch from "../../util/isTouch";
import { DangerAlert, SuccessAlert } from "../Alerts";
import { OutlineBtn, PrimaryBtn } from "../Buttons";
import Checkbox from "../Checkbox";
import { Form, FormError, FormInput, FormInputContainer } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import StateContext from "../StateContext";
import { ForgotPasswordLink, LogoContainer, SignInContainer } from "./styles";

const SignInForm: FC<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { theme, forceUpdate } = useContext(StateContext);
  const { color } = useContext(ThemeContext);

  const [noExpire, setNoExpire] = useState<boolean>(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    twoFactor: undefined,
    responseMsg: undefined,
  });
  const [isWaitingResponse, toggleWaiting] = useState<boolean>(false);
  const [err, setErr] = useState<{
    email?: string;
    password?: string;
    twoFactor?: string;
    service?: string;
  }>({
    email: undefined,
    password: undefined,
    twoFactor: undefined,
    service: undefined,
  });

  const [useTwoFactor, setUseTwoFactor] = useState<boolean>(false);

  const [nextRoute, setNextRoute] = useState<any>(undefined);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    setErr({ ...err, [e.target.name]: undefined, service: undefined });
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setNoExpire(!noExpire);
  };

  // Gets user's token on successful login
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setErr({ ...err, service: undefined });

    const { email, password, twoFactor } = credentials;

    toggleWaiting(true);
    postToken(source, email, password, noExpire, twoFactor)
      .then((response) => {
        if (twoFactor === undefined && response && response.twoFactor) {
          setUseTwoFactor(true);
          toggleWaiting(false);
          setCredentials({
            ...credentials,
            responseMsg: response.message,
          });
        } else {
          // If login successful, store token and account information in local storage
          saveToken(response, noExpire);
          saveAccount(response);

          // Then get the next route to bring the user to
          toggleWaiting(true);
          if (response.organisationId) getNextRoute(source, location, setNextRoute);
          else setNextRoute(`/select-organisation${location.search}`);
          toggleWaiting(false);

          const accountInfoUpdatedEvent = new Event("accountinfoupdated");
          document.dispatchEvent(accountInfoUpdatedEvent);
          forceUpdate();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          toggleWaiting(false);
          setErr({ ...err, service: errToStr(error) });
        }
      });
  };

  if (nextRoute) {
    navigate(nextRoute);
  }

  return (
    <LoadingContainer loading={isWaitingResponse}>
      <SignInContainer>
        <Form onSubmit={handleSubmit} noValidate>
          <LogoContainer>{getLogo(color, theme)}</LogoContainer>
          {useTwoFactor ? (
            <>
              {credentials.responseMsg && <SuccessAlert style={{ margin: "0 0 12px 0", width: "100%" }}>{credentials.responseMsg}</SuccessAlert>}
              <FormInputContainer>
                <label>Login Code</label>
                <FormInput
                  autoComplete="no"
                  type="text"
                  name="twoFactor"
                  testId="twoFactorInput"
                  value={credentials.twoFactor}
                  onChange={handleChange}
                  error={!!err.twoFactor}
                />
                <FormError error={err.twoFactor}>{err.twoFactor}</FormError>
                {err.service && <DangerAlert style={{ margin: "12px 0", width: "100%" }}>{err.service}</DangerAlert>}
              </FormInputContainer>
            </>
          ) : (
            <>
              <FormInputContainer>
                <label>Email</label>
                <FormInput
                  type="email"
                  name="email"
                  testId="emailInput"
                  autoComplete="email"
                  value={credentials.email}
                  onChange={handleChange}
                  error={!!err.email}
                  autoFocus={!isTouch()}
                />
                <FormError error={err.email}>{err.email}</FormError>
              </FormInputContainer>
              <FormInputContainer>
                <label>Password</label>
                <ForgotPasswordLink to="/forgot-password">Forgot password?</ForgotPasswordLink>
                <FormInput
                  type="password"
                  name="password"
                  testId="passwordInput"
                  autoComplete="current-password"
                  value={credentials.password}
                  onChange={handleChange}
                  error={!!err.password}
                />
                <FormError error={err.password}>{err.password}</FormError>
              </FormInputContainer>
              {err.service && <DangerAlert style={{ margin: "0 0 12px 0", width: "100%" }}>{err.service}</DangerAlert>}
              <FormInputContainer>
                <Checkbox
                  style={{
                    marginBottom: "36px",
                    fontSize: "12px",
                    lineHeight: "22px",
                  }}
                  name="noExpire"
                  label="Stay signed in on this device (30 days)"
                  checked={noExpire}
                  onChange={handleCheckboxChange}
                />
              </FormInputContainer>
            </>
          )}
          {!useTwoFactor ? (
            <>
              <PrimaryBtn type="submit" width="100%">
                Sign In
              </PrimaryBtn>
              <Link style={{ marginTop: "24px" }} to={`/register${location.search}`}>
                Create Account
              </Link>
            </>
          ) : (
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
              <OutlineBtn
                onClick={() => {
                  setUseTwoFactor(false);
                  setCredentials({ ...credentials, twoFactor: undefined });
                  setErr({ ...err, service: undefined });
                }}
                width="48%"
              >
                Back
              </OutlineBtn>
              <PrimaryBtn type="submit" width="48%">
                Sign In
              </PrimaryBtn>
            </div>
          )}
        </Form>
      </SignInContainer>
    </LoadingContainer>
  );
};

export default SignInForm;
