import axios, { CancelTokenSource } from "axios";

export const fetchUnsubscribeReport = async (source: CancelTokenSource, token: any) => {
  const params = {
    token,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + `/api/unsubscribereport/`,
    params,
  });
  return data;
};
