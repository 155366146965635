import { FC } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { PageContainer } from "../PageStyles";
import { Panel } from "../Panel";
import Reports from "../Reports";
import ReportsSchedules from "../ReportsSchedules";

const ReportsScreen: FC<any> = () => {
  return (
    <>
      <Tabs>
        <TabList className="react-tabs__tab-list__background_no-margin">
          <Tab>Reports</Tab>
          <Tab>Schedules</Tab>
        </TabList>
        <TabPanel>
          <Reports />
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <ReportsSchedules />
            </Panel>
          </PageContainer>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default ReportsScreen;
