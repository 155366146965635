import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../services/autoComplete";
import { fetchBatteryEstimate } from "../../services/batteryEstimate";
import { isAdmin, isSuper } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { exists, logRateMax, logRateMin, max, maxLength, min, uploadRateMax, uploadRateMin, validName } from "../../util/formValidations";
import formatTrackerSettings from "../../util/formatTrackerSettings";
import {
  convertRoundedPressure,
  convertRoundedTemp,
  convertRoundedVelocity,
  printPressureUnit,
  printTempUnit,
  printVelocityUnit,
} from "../../util/formatUnits";
import { humaniseHours } from "../../util/humaniseDurations";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import { DangerAlert, SuccessAlert, WarningAlert } from "../Alerts";
import Checkbox from "../Checkbox";
import { FormError, FormInput, InfoIconContainer, InputUnitWrapper } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncCreatableSelect, AsyncSelect, Select } from "../Select";
import Slider from "../Slider";
import { BatteryEstimateContainer, CameraIconContainer, QrScanButton, UpdateImageButton, UpdateImageButtonContainer } from "./styles";
// @ts-ignore
import Collapse, { Panel } from "rc-collapse";
import Quagga, { QuaggaJSCodeReader, QuaggaJSResultObject } from "@ericblade/quagga2";
import { getTracker, putTracker } from "../../services/trackers";
import InfoIcon from "../../svgs/Legend";
import sortTags from "../../util/sortTags";
// @ts-ignore
import QrCodeReader from "@ericblade/quagga2-reader-qr";
import { hideAll } from "tippy.js";
import { deletePhoto } from "../../services/photo";
import CameraIcon from "../../svgs/CameraIcon";
import { isBinaryBeer, isBinaryTech } from "../../util/checkDomain";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import DeleteModal from "../DeleteModal";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import UploadPhotoModal from "../UploadPhotoModal";
import TrackerInfoPanel from "../TrackerInfoPanel";
import { useDebouncedCallback } from "use-debounce";

Quagga.registerReader("qr_code", QrCodeReader);

function getMedian(arr: any[]) {
  arr.sort((a: number, b: number) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes: any[]) {
  const errors = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
}

const defaultConstraints = {
  width: 640,
  height: 480,
};

const defaultLocatorSettings = {
  patchSize: "medium",
  halfSample: true,
  willReadFrequently: true,
};

const defaultDecoders: (QuaggaJSCodeReader | "qr_code")[] = [
  "qr_code",
  "code_128_reader",
  "ean_reader",
  "ean_8_reader",
  "code_39_reader",
  "code_39_vin_reader",
  "codabar_reader",
  "upc_reader",
  "upc_e_reader",
  "i2of5_reader",
  "2of5_reader",
  "code_93_reader",
];

const defaultSettings = {
  active: undefined,
  adcAlertMode1: undefined,
  adcAlertMode2: undefined,
  adcAlertMode3: undefined,
  adcAlertMode4: undefined,
  adcAlertMode5: undefined,
  adcAlertMode6: undefined,
  adcAlertMode7: undefined,
  adcAlertValue1: undefined,
  adcAlertValue2: undefined,
  adcAlertValue3: undefined,
  adcAlertValue4: undefined,
  adcAlertValue5: undefined,
  adcAlertValue6: undefined,
  adcAlertValue7: undefined,
  adcEnableAlert1: undefined,
  adcEnableAlert2: undefined,
  adcEnableAlert3: undefined,
  adcEnableAlert4: undefined,
  adcEnableAlert5: undefined,
  adcEnableAlert6: undefined,
  adcEnableAlert7: undefined,
  adcSamplingMode1: undefined,
  adcSamplingMode2: undefined,
  adcSamplingMode3: undefined,
  adcSamplingMode4: undefined,
  adcSamplingMode5: undefined,
  adcSamplingMode6: undefined,
  adcSamplingMode7: undefined,
  assetId: "",
  assetType: undefined,
  assetTypeColour: undefined,
  assetTypeIcon: undefined,
  assetTypeId: undefined,
  assetTypeName: undefined,
  beerUploadRate: "",
  configurableGpsRetry: undefined,
  configurableNetworkRetry: undefined,
  configurableUpload: undefined,
  dateActivated: "",
  dateActivatedUnix: undefined,
  dateCreated: "",
  dateCreatedUnix: undefined,
  dateProvisioned: "",
  dateProvisionedUnix: undefined,
  enableLightWakeup: undefined,
  enableMagnetWakeup: undefined,
  enableMovementWakeup: undefined,
  enableSwitchWakeup: undefined,
  temperatureHighInterrupt: undefined,
  temperatureHighInterruptThreshold: undefined,
  temperatureLowInterrupt: undefined,
  temperatureLowInterruptThreshold: undefined,
  temperatureCountInterruptThreshold: undefined,
  temperatureWakeupDuration: undefined,
  firmwareVersion: undefined,
  gpsRetryTime: undefined,
  hasBattery: undefined,
  hasBeer: undefined,
  hasCoupled: undefined,
  hasEngineRunning: undefined,
  hasGps: undefined,
  hasInput1: undefined,
  hasInput2: undefined,
  hasInput3: undefined,
  hasInput4: undefined,
  hasInput5: undefined,
  hasInput6: undefined,
  hasInput7: undefined,
  hasLightLevel: undefined,
  hasLightWakeup: undefined,
  hasMagnetWakeup: undefined,
  hasMovement: undefined,
  hasMovementWakeup: undefined,
  hasMovementWakeupThreshold: undefined,
  hasOrientation: undefined,
  hasReboot: undefined,
  hasSupplyVoltage: undefined,
  HasSwitchWakeup: undefined,
  hasTemperature: undefined,
  hasHighTempWakeup: undefined,
  hasLowTempWakeup: undefined,
  hasTimeOfFlight: undefined,
  id: "",
  imei: undefined,
  imsi: undefined,
  image: undefined,
  input1Label: undefined,
  input1Max: undefined,
  input1Min: undefined,
  input1Mode: undefined,
  input1Unit: undefined,
  input2Label: undefined,
  input2Max: undefined,
  input2Min: undefined,
  input2Mode: undefined,
  input2Unit: undefined,
  input3Label: undefined,
  input3Max: undefined,
  input3Min: undefined,
  input3Mode: undefined,
  input3Unit: undefined,
  input4Label: undefined,
  input4Max: undefined,
  input4Min: undefined,
  input4Mode: undefined,
  input4Unit: undefined,
  input5Label: undefined,
  input5Max: undefined,
  input5Min: undefined,
  input5Mode: undefined,
  input5Unit: undefined,
  input6Label: undefined,
  input6Max: undefined,
  input6Min: undefined,
  input6Mode: undefined,
  input6Unit: undefined,
  input7Label: undefined,
  input7Max: undefined,
  input7Min: undefined,
  input7Mode: undefined,
  input7Unit: undefined,
  kegTypeId: undefined,
  kegType: undefined,
  kegTypeName: undefined,
  latestSampleDate: undefined,
  latestSampleDateUnix: undefined,
  lightWakeupThreshold: undefined,
  markedForPickup: undefined,
  maxApCount: 6,
  maxAps: undefined,
  maxLightWakeupThreshold: undefined,
  maxLogs: undefined,
  maxMovementWakeupThreshold: undefined,
  maxUploadRate: undefined,
  mcc: undefined,
  minApCount: 3,
  minAps: undefined,
  minLogTime: undefined,
  minUploadTime: undefined,
  mnc: undefined,
  movementWakeupThreshold: undefined,
  networkRetryTime: undefined,
  nextSampleDue: "",
  nextSampleDueUnix: undefined,
  nickname: "",
  notes: "",
  organisationId: undefined,
  organisationName: "",
  placeId: undefined,
  placeName: undefined,
  showCoupled: undefined,
  showEngineRunning: undefined,
  showEvents: undefined,
  showFreshness: undefined,
  showInput1: undefined,
  showInput2: undefined,
  showInput3: undefined,
  showInput4: undefined,
  showInput5: undefined,
  showInput6: undefined,
  showInput7: undefined,
  showLight: undefined,
  showMovement: undefined,
  showNetwork: undefined,
  showOrientation: undefined,
  showSample: undefined,
  showSupplyVoltage: undefined,
  showTemperature: undefined,
  showTimeOfFlight: undefined,
  showVoltage: undefined,
  sleepCount: undefined,
  sleepDuration: undefined,
  sticker: undefined,
  trackerTags: undefined,
  trackerTypeId: undefined,
  trackerTypeName: undefined,
  uploadRate: undefined,
  logRate: undefined,
  trackerPhotoUrl: undefined,
};

const uploadRateLabels: any = {
  1440: "1 upload / 1 day",
  720: "1 upload / 12 hours",
  480: "1 upload / 8 hours",
  360: "1 upload / 6 hours",
  180: "1 upload / 3 hours",
  120: "1 upload / 2 hours",
  60: "1 upload / 1 hour",
};

// Sets the has properties to true and formats for formData to properly display dropdown options in the form
const formatTrackerData = (settings: any) => {
  const formData = {
    ...defaultSettings,
    ...settings,
    assetType: settings.assetTypeName
      ? {
          value: settings.assetTypeId,
          label: settings.assetTypeName,
          colour: settings.assetTypeColour,
          icon: settings.assetTypeIcon,
        }
      : null,
    trackerTags:
      settings.trackerTags && settings.trackerTags.length > 0
        ? settings.trackerTags.sort(sortTags).map((tag: any) => ({ value: tag.id, label: tag.name, colour: tag.colour, description: tag.description }))
        : [],
    kegType: settings.kegTypeId
      ? {
          value: settings.kegTypeId,
          label: settings.kegTypeName,
        }
      : undefined,
    beerUploadRate: settings.uploadRate ? { value: settings.uploadRate, label: uploadRateLabels[settings.uploadRate] } : undefined,
    uploadRate: settings.sleepDuration && settings.sleepCount ? settings.sleepDuration * settings.sleepCount : undefined,
    logRate: settings.sleepDuration != null ? settings.sleepDuration : undefined,
    // input 1
    input1Mode: settings.input1Mode ? { value: settings.input1Mode, label: settings.input1Mode } : { label: "0-4V", value: "0-4V" },
    adcSamplingMode1: settings.adcSamplingMode1 == false ? { label: "Average", value: false } : { label: "Maximum", value: true },
    adcAlertMode1:
      settings.adcAlertMode1 == false
        ? { label: "Upload when below interrupt value", value: false }
        : { label: "Upload when above interrupt value", value: true },
    // input 2
    input2Mode: settings.input2Mode ? { value: settings.input2Mode, label: settings.input2Mode } : { label: "0-4V", value: "0-4V" },
    adcSamplingMode2: settings.adcSamplingMode2 == false ? { label: "Average", value: false } : { label: "Maximum", value: true },
    adcAlertMode2:
      settings.adcAlertMode2 == false
        ? { label: "Upload when below interrupt value", value: false }
        : { label: "Upload when above interrupt value", value: true },
    // input 3
    input3Mode: settings.input3Mode ? { value: settings.input3Mode, label: settings.input3Mode } : { label: "0-4V", value: "0-4V" },
    adcSamplingMode3: settings.adcSamplingMode3 == false ? { label: "Average", value: false } : { label: "Maximum", value: true },
    adcAlertMode3:
      settings.adcAlertMode3 == false
        ? { label: "Upload when below interrupt value", value: false }
        : { label: "Upload when above interrupt value", value: true },
    // input 4
    input4Mode: settings.input4Mode ? { value: settings.input4Mode, label: settings.input4Mode } : { label: "0-4V", value: "0-4V" },
    adcSamplingMode4: settings.adcSamplingMode4 == false ? { label: "Average", value: false } : { label: "Maximum", value: true },
    adcAlertMode4:
      settings.adcAlertMode4 == false
        ? { label: "Upload when below interrupt value", value: false }
        : { label: "Upload when above interrupt value", value: true },
    // input 5
    input5Mode: settings.input5Mode ? { value: settings.input5Mode, label: settings.input5Mode } : { label: "0-4V", value: "0-4V" },
    adcSamplingMode5: settings.adcSamplingMode5 == false ? { label: "Average", value: false } : { label: "Maximum", value: true },
    adcAlertMode5:
      settings.adcAlertMode5 == false
        ? { label: "Upload when below interrupt value", value: false }
        : { label: "Upload when above interrupt value", value: true },
    // input 6
    input6Mode: settings.input6Mode ? { value: settings.input6Mode, label: settings.input6Mode } : { label: "0-4V", value: "0-4V" },
    adcSamplingMode6: settings.adcSamplingMode6 == false ? { label: "Average", value: false } : { label: "Maximum", value: true },
    adcAlertMode6:
      settings.adcAlertMode6 == false
        ? { label: "Upload when below interrupt value", value: false }
        : { label: "Upload when above interrupt value", value: true },
    // input 7
    input7Mode: settings.input7Mode ? { value: settings.input7Mode, label: settings.input7Mode } : { label: "0-4V", value: "0-4V" },
    adcSamplingMode7: settings.adcSamplingMode7 == false ? { label: "Average", value: false } : { label: "Maximum", value: true },
    adcAlertMode7:
      settings.adcAlertMode7 == false
        ? { label: "Upload when below interrupt value", value: false }
        : { label: "Upload when above interrupt value", value: true },
  };

  if (formData.uploadRate != null) {
    formData.uploadRate = formData.uploadRate / 60;
  }

  formData.temperatureHighInterruptThreshold = convertRoundedTemp(formData.temperatureHighInterruptThreshold);
  formData.temperatureLowInterruptThreshold = convertRoundedTemp(formData.temperatureLowInterruptThreshold);
  formData.temperatureWakeupDuration = formData.temperatureCountInterruptThreshold * formData.logRate;

  if (formData.input1Mode && formData.input1Mode.value === "NTC Thermistor") {
    formData.adcAlertValue1 = convertRoundedTemp(formData.adcAlertValue1);
    if (formData.adcAlertMode1.value === true) formData.adcAlertMode1 = { label: "Upload when below interrupt value", value: false };
    else formData.adcAlertMode1 = { label: "Upload when above interrupt value", value: true };
  }

  if (formData.input3Mode && formData.input3Mode.value === "Air Velocity") formData.adcAlertValue3 = convertRoundedVelocity(formData.adcAlertValue3);

  if (formData.input4Mode && formData.input4Mode.value === "Differential Pressure") formData.adcAlertValue4 = convertRoundedPressure(formData.adcAlertValue4);

  return formData;
};

// Triggered when a tracker's data is fetched to format the form correctly
const updateFromData = (tracker: any) => {
  if (
    tracker.uploadRate === 60 ||
    tracker.uploadRate === 120 ||
    tracker.uploadRate === 180 ||
    tracker.uploadRate === 360 ||
    tracker.uploadRate === 480 ||
    tracker.uploadRate === 720 ||
    tracker.uploadRate === 1440
  ) {
    return formatTrackerData(tracker);
  } else {
    return formatTrackerData({
      ...tracker,
      beerUploadRate: null,
    });
  }
};

// if settings are passed into this component we merge these with the default settings and initialise the form
const initForm = (settings?: any) => {
  if (settings) {
    return {
      ...defaultSettings,
      ...settings,
      showSample: true,
      showMovement: true,
      showEvents: true,
      showTemperature: true,
      showVoltage: false,
      showSupplyVoltage: true,
      showOrientation: true,
      showFreshness: true,
      showLight: true,
      showEngineRunning: true,
      showNetwork: true,
      showInput1: true,
      showInput2: true,
      showInput3: true,
      showInput4: true,
      showInput5: true,
      showInput6: true,
      showInput7: true,
      showTimeOfFlight: true,
      showCoupled: true,
    };
  } else {
    return defaultSettings;
  }
};

// Function to calculate log rate marks
const getLogRateOptions = (uploadRate: number, minLogTime: number) => {
  const max = (uploadRate * 60) / Math.ceil((uploadRate * 60) / 1440);
  const min = (uploadRate * 60) / Math.floor((uploadRate * 60) / minLogTime);

  const uploadRateMinutes = uploadRate * 60;
  const validLogRates = [];

  for (let i = 1; i <= uploadRateMinutes; i++) {
    if (uploadRateMinutes % i === 0) validLogRates.push(i);
  }

  const marks = validLogRates.reduce((acc: any, rate: any) => {
    if (rate >= min && rate <= max) {
      acc[rate] = "";
    }
    return acc;
  }, {});

  return { min, max, marks };
};

const EditTrackerModal: FC<any> = ({ id, settings, organisationId, onSuccess, onError, modalOpen, setModalOpen }) => {
  const { color, long_datetime } = useContext(ThemeContext);

  const scannerRef = useRef<any>(undefined);
  const photoMenuRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const [formData, setFormData] = useState<any>(initForm(settings));
  const [formErrors, setFormErrors] = useState<any>({});

  const [tracker, setTracker] = useState<any>({});
  const [trackerErr, setTrackerErr] = useState<string>("");
  const [trackerLoading, setTrackerLoading] = useState<boolean>(true);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [batteryEstimate, setBatteryEstimate] = useState<any>(undefined);
  const [batteryEstimateErr, setBatteryEstimateErr] = useState<string>("");
  const [batteryEstimateLoading, setBatteryEstimateLoading] = useState<boolean>(false);

  const [tabIndex, setTabIndex] = useState(0);

  const [generalTabValid, setGeneralTabValid] = useState<boolean>(true);
  const [settingsTabValid, setSettingsTabValid] = useState<boolean>(true);
  const [inputTabValid, setInputTabValid] = useState<boolean>(true);

  const [scanning, setScanning] = useState<boolean>(false);

  const [inputPhoto, setInputPhoto] = useState<any>(undefined);
  const [uploadPhotoModalOpen, setUploadPhotoModalOpen] = useState<boolean>(false);
  const [deletePhotoModalOpen, setDeletePhotoModalOpen] = useState<boolean>(false);

  const [logRateOptions, setLogRateOptions] = useState(getLogRateOptions(formData.uploadRate, formData.minLogTime));

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // On barcode detect, add result to Asset ID input and stop scanning
  const onDetected = useCallback((result: any) => {
    setFormData((prev: any) => ({ ...prev, assetId: result }));
    setScanning(false);
  }, []);

  const errorCheck = useCallback(
    (result: { codeResult: { decodedCodes: any[]; code: any } }) => {
      if (!onDetected) {
        return;
      }
      // @ts-ignore
      if (result.codeResult.format !== "qr_code") {
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        // if Quagga is at least 92% certain that it read correctly, then accept the code.
        if (err < 0.08) {
          onDetected(result.codeResult.code);
        }
      } else {
        onDetected(result.codeResult.code);
      }
    },
    [onDetected]
  );

  const handleProcessed = (result: QuaggaJSResultObject) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.font = "24px Arial";
    drawingCtx.fillStyle = "green";

    if (result) {
      if (result.boxes) {
        // @ts-ignore
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
        result.boxes
          .filter((box: any) => box !== result.box)
          .forEach((box: any[]) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "purple", lineWidth: 2 });
          });
      }
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "blue", lineWidth: 2 });
      }
    }
  };

  useEffect(() => {
    if (scanning === false) {
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
      Quagga.stop();
    } else {
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            constraints: defaultConstraints,
            target: scannerRef.current,
            willReadFrequently: true,
          },
          locator: defaultLocatorSettings,
          numOfWorkers: navigator.hardwareConcurrency || 0,
          // @ts-ignore
          decoder: { readers: defaultDecoders },
          locate: true,
          debug: false,
        },
        (err) => {
          Quagga.onProcessed(handleProcessed);

          if (err) {
            return console.log("Error starting Quagga:", err);
          }
          if (scannerRef && scannerRef.current) {
            Quagga.start();
          }
        }
      );
      Quagga.onDetected(errorCheck);
      return () => {
        Quagga.offDetected(errorCheck);
        Quagga.offProcessed(handleProcessed);
        Quagga.stop();
      };
    }
  }, [scanning, onDetected, scannerRef, errorCheck]);

  useEffect(() => {
    fetchTracker();
  }, [id, source]);

  // Debounce the battery estimate request to reduce requests when using the sliders
  const [debouncedFetchBatteryEstimate] = useDebouncedCallback(() => {
    if (formData.configurableUpload) {
      let sleepCount = 0;
      let sleepDuration = 0;

      if (isBinaryBeer() && formData.beerUploadRate) {
        sleepCount = 15;
        sleepDuration = Math.round(formData.beerUploadRate.value / 15);
      } else {
        if (formData.uploadRate > 0 && formData.logRate > 0) {
          sleepDuration = formData.logRate;
        }
        sleepCount = Math.round((formData.uploadRate * 60) / formData.logRate);
      }

      setBatteryEstimateErr("");
      fetchBatteryEstimate(source, id, sleepCount, sleepDuration)
        .then((response) => {
          setBatteryEstimate(response);
          setBatteryEstimateLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setBatteryEstimateErr(errToStr(err));
            setBatteryEstimateLoading(false);
          }
        });
    }
  }, 200);

  useEffect(() => {
    setBatteryEstimateLoading(true);
    debouncedFetchBatteryEstimate();
  }, [formData.beerUploadRate, formData.uploadRate, formData.logRate, id, source]);

  // Update log rate options when upload rate or min log time changes
  useEffect(() => {
    const options = getLogRateOptions(formData.uploadRate, formData.minLogTime);
    const markValues = Object.keys(options.marks).map(Number);
    if (markValues.length > 0) {
      // Determine the new log rate by selecting the mark value closest to the current logRate
      const newLogRate = markValues.reduce((prev, curr) => (Math.abs(curr - formData.logRate) < Math.abs(prev - formData.logRate) ? curr : prev));
      setFormData((prev: any) => ({ ...prev, logRate: newLogRate }));
    }
    setLogRateOptions(options);
  }, [formData.uploadRate, formData.minLogTime]);

  // Snap temperatureWakeupDuration to the nearest multiple of logRate when logRate changes
  useEffect(() => {
    const newTemperatureWakeupDuration = Math.round(formData.temperatureWakeupDuration / formData.logRate) * formData.logRate;
    setFormData((prev: any) => ({ ...prev, temperatureWakeupDuration: newTemperatureWakeupDuration }));
  }, [formData.logRate]);

  const fetchTracker = () => {
    if (id !== undefined) {
      setTrackerLoading(true);
      getTracker(source, id)
        .then((response) => {
          setTracker(response);
          setFormData(updateFromData(response));
          setTrackerLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setTrackerErr(errToStr(err));
            setTrackerLoading(false);
          }
        });
    }
  };

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;
    let generalValid = true;
    let settingsValid = true;
    let inputValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "nickname":
          currValid = maxLength(name, value, 200, setFormErrors);
          if (!currValid) generalValid = false;
          break;

        case "assetId":
          currValid = maxLength(name, value, 255, setFormErrors);
          if (!currValid) generalValid = false;
          break;

        case "assetType":
          if (value) {
            currValid = maxLength(name, value.value, 200, setFormErrors) && validName(name, value.value, setFormErrors);
            if (!currValid) generalValid = false;
          } else {
            currValid = true;
          }
          break;

        case "notes":
          currValid = maxLength(name, value, 200, setFormErrors);
          if (!currValid) generalValid = false;
          break;

        case "beerUploadRate":
          if (isBinaryBeer() && formData.configurableUpload) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
            if (!currValid) settingsValid = false;
          } else {
            currValid = true;
          }
          break;

        // only visible if not on binarybeer, check exists and is between 30/60 - 10080 minutes
        case "uploadRate":
          if (!isBinaryBeer()) {
            const uploadRateMinutes = value * 60;
            if (formData.configurableUpload) {
              currValid =
                exists(name, value, setFormErrors) &&
                uploadRateMin(name, uploadRateMinutes, isSuper() ? 0.1 : formData.minUploadTime, setFormErrors) &&
                uploadRateMax(name, uploadRateMinutes, 10080, setFormErrors); // max 1 week for all trackers
            } else {
              currValid = true;
            }
            if (!currValid) settingsValid = false;
          } else {
            currValid = true;
          }
          break;

        // only visible if not on binarybeer
        case "logRate":
          if (!isBinaryBeer()) {
            if (formData.configurableUpload) {
              currValid =
                exists(name, value, setFormErrors) &&
                logRateMin(name, value, logRateOptions.min, setFormErrors) &&
                logRateMax(name, value, logRateOptions.max, setFormErrors);
            } else {
              currValid = true;
            }
            if (!currValid) settingsValid = false;
          } else {
            currValid = true;
          }
          break;

        case "adcAlertValue1":
          if (formData.adcEnableAlert1) {
            currValid = exists(name, value, setFormErrors);
            if (formData.input1Mode && formData.input1Mode.value === "NTC Thermistor")
              currValid = currValid && min(name, value, 0, setFormErrors) && max(name, value, 100, setFormErrors);
            if (!currValid) inputValid = false;
          } else {
            currValid = true;
          }
          break;

        case "adcAlertValue2":
          if (formData.adcEnableAlert2) {
            currValid = exists(name, value, setFormErrors) && min(name, value, 0, setFormErrors);
            if (!currValid) inputValid = false;
          } else {
            currValid = true;
          }
          break;

        case "adcAlertValue3":
          if (formData.adcEnableAlert3) {
            currValid = exists(name, value, setFormErrors);
            if (formData.input3Mode && formData.input3Mode.value === "Air Velocity")
              currValid = currValid && min(name, value, 0, setFormErrors) && max(name, value, 3, setFormErrors);
            if (!currValid) inputValid = false;
          } else {
            currValid = true;
          }
          break;

        case "adcAlertValue4":
          if (formData.adcEnableAlert4) {
            currValid = exists(name, value, setFormErrors);
            if (formData.input4Mode && formData.input4Mode.value === "Differential Pressure")
              currValid = currValid && min(name, value, -7, setFormErrors) && max(name, value, 7, setFormErrors);
            if (!currValid) inputValid = false;
          } else {
            currValid = true;
          }
          break;

        case "adcAlertValue5":
          if (formData.adcEnableAlert5) {
            currValid = exists(name, value, setFormErrors) && min(name, value, 0, setFormErrors);
            if (!currValid) inputValid = false;
          } else {
            currValid = true;
          }
          break;

        case "adcAlertValue6":
          if (formData.adcEnableAlert6) {
            currValid = exists(name, value, setFormErrors) && min(name, value, 0, setFormErrors);
            if (!currValid) inputValid = false;
          } else {
            currValid = true;
          }
          break;

        case "adcAlertValue7":
          if (formData.adcEnableAlert7) {
            currValid = exists(name, value, setFormErrors) && min(name, value, 0, setFormErrors);
            if (!currValid) inputValid = false;
          } else {
            currValid = true;
          }
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }

    setGeneralTabValid(generalValid);
    setSettingsTabValid(settingsValid);
    setInputTabValid(inputValid);

    return allValid;
  };

  const handleSubmit = () => {
    if (!submitting) {
      const body = formatTrackerSettings(formData);
      const valid = validateForm();

      if (valid) {
        setSubmitting(true);
        putTracker(source, body)
          .then((response) => {
            if (onSuccess) onSuccess(response);
            setSubmittedMsg(kegOrTracker("Keg Updated", "Tracker Updated"));
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              if (onError) onError(errToStr(err));
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));

    // Reset uploadRate and logRate, error if uploadRate or logRate is changed
    if (e.target.name === "uploadRate" || e.target.name === "logRate") {
      setFormErrors((prev: any) => ({
        ...prev,
        uploadRate: undefined,
        logRate: undefined,
      }));
    }
  };

  const handleSliderChange = (name: string, value: number) => {
    setFormData((prev: any) => ({ ...prev, [name]: value }));
    setFormErrors((prev: any) => ({ ...prev, [name]: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const handleEnableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setSubmittedMsg("");
    setSubmittingErr("");
    setScanning(true);
  };

  const handleDisableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setScanning(false);
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  const handleFileChange = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setInputPhoto(e.target.files[0]);
    if (e.target.files[0]) setUploadPhotoModalOpen(true);
    e.target.value = "";
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue, tracker.organisationId || organisationId).then((response) => {
      callback(response);
    });
  };

  const getInputPanel = (number: number) => {
    const modeOptions = [
      { label: "0-4V", value: "0-4V" },
      { label: "4-20mA", value: "4-20mA" },
    ];
    if (number === 1) modeOptions.push({ label: "NTC Thermistor", value: "NTC Thermistor" });
    if (number === 2) modeOptions.push({ label: "CT Clamp", value: "CT Clamp" });
    if (number === 3) modeOptions.push({ label: "Air Velocity", value: "Air Velocity" });
    if (number === 4) modeOptions.push({ label: "Differential Pressure", value: "Differential Pressure" });

    const samplingModeOptions = [
      { label: "Average", value: false },
      { label: "Maximum", value: true },
    ];

    return (
      <div style={{ display: "flex", flexDirection: "column", padding: "12px" }}>
        <ModalFormContainer>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <label>Label</label>
              <FormInput
                type="text"
                name={`input${number}Label`}
                value={formData[`input${number}Label`]}
                error={formErrors[`input${number}Label`]}
                onChange={handleChange}
              />
              <FormError error={formErrors[`input${number}Label`]}>{formErrors[`input${number}Label`]}</FormError>
            </div>
            <div style={{ width: "100%", paddingLeft: "12px" }}>
              <label>Pin Mode</label>
              <Select
                name={`input${number}Mode`}
                isError={formErrors[`input${number}Mode`]}
                value={formData[`input${number}Mode`]}
                onChange={handleSelectChange}
                placeholder="Select"
                isSearchable={false}
                options={modeOptions}
              />
              <FormError error={formErrors[`input${number}Mode`]}>{formErrors[`input${number}Mode`]}</FormError>
            </div>
            {((formData[`input${number}Mode`] && formData[`input${number}Mode`].value === "0-4V") ||
              (formData[`input${number}Mode`] && formData[`input${number}Mode`].value === "4-20mA")) && (
              <div style={{ width: "100%", paddingLeft: "12px" }}>
                <label>Sampling Mode</label>
                <Select
                  name={`adcSamplingMode${number}`}
                  isError={formErrors[`adcSamplingMode${number}`]}
                  value={formData[`adcSamplingMode${number}`]}
                  onChange={handleSelectChange}
                  placeholder="Select"
                  isSearchable={false}
                  options={samplingModeOptions}
                />
                <FormError error={formErrors[`adcSamplingMode${number}`]}>{formErrors[`adcSamplingMode${number}`]}</FormError>
              </div>
            )}
          </div>
        </ModalFormContainer>
        {formData[`input${number}Mode`] &&
          (formData[`input${number}Mode`].value === "0-4V" ||
            formData[`input${number}Mode`].value === "4-20mA" ||
            formData[`input${number}Mode`].value === "CT Clamp") && (
            <>
              <ModalFormContainer>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <label>Scale Min</label>
                    <FormInput
                      type="number"
                      name={`input${number}Min`}
                      value={formData[`input${number}Min`]}
                      error={formErrors[`input${number}Min`]}
                      onChange={handleChange}
                    />
                    <FormError error={formErrors[`input${number}Min`]}>{formErrors[`input${number}Min`]}</FormError>
                  </div>
                  <div style={{ width: "100%", padding: "0 12px" }}>
                    <label>Scale Max</label>
                    <FormInput
                      type="number"
                      name={`input${number}Max`}
                      value={formData[`input${number}Max`]}
                      error={formErrors[`input${number}Max`]}
                      onChange={handleChange}
                    />
                    <FormError error={formErrors[`input${number}Max`]}>{formErrors[`input${number}Max`]}</FormError>
                  </div>
                  <div style={{ width: "100%" }}>
                    <label>Unit</label>
                    <FormInput
                      type="text"
                      name={`input${number}Unit`}
                      value={formData[`input${number}Unit`]}
                      error={formErrors[`input${number}Unit`]}
                      onChange={handleChange}
                    />
                    <FormError error={formErrors[`input${number}Unit`]}>{formErrors[`input${number}Unit`]}</FormError>
                  </div>
                </div>
              </ModalFormContainer>
            </>
          )}
        <ModalFormContainer>
          <div style={{ display: "flex" }}>
            <div style={{ width: "20%" }}>
              <label>Interrupt</label>
              <div style={{ padding: "5px 0" }}>
                <Checkbox
                  style={{ display: "inline-block" }}
                  name={`adcEnableAlert${number}`}
                  label="Enabled"
                  checked={formData[`adcEnableAlert${number}`]}
                  onChange={handleCheckboxChange}
                />
              </div>
              <FormError error={formErrors[`adcEnableAlert${number}`]}>{formErrors[`adcEnableAlert${number}`]}</FormError>
            </div>
            {formData[`adcEnableAlert${number}`] && (
              <>
                <div style={{ paddingLeft: "12px", width: "50%" }}>
                  <label>Interrupt Mode</label>
                  <Select
                    name={`adcAlertMode${number}`}
                    isError={formErrors[`adcAlertMode${number}`]}
                    value={formData[`adcAlertMode${number}`]}
                    onChange={handleSelectChange}
                    placeholder="Select"
                    isSearchable={false}
                    options={[
                      { label: "Upload when above interrupt value", value: true },
                      { label: "Upload when below interrupt value", value: false },
                    ]}
                  />
                  <FormError error={formErrors[`adcAlertMode${number}`]}>{formErrors[`adcAlertMode${number}`]}</FormError>
                </div>
                <div style={{ paddingLeft: "12px", width: "30%" }}>
                  <label>Interrupt Value</label>
                  <InputUnitWrapper
                    unit={
                      number === 1 && formData.input1Mode && formData.input1Mode.value === "NTC Thermistor"
                        ? printTempUnit()
                        : number === 3 && formData.input3Mode && formData.input3Mode.value === "Air Velocity"
                        ? printVelocityUnit()
                        : number === 4 && formData.input4Mode && formData.input4Mode.value === "Differential Pressure"
                        ? printPressureUnit()
                        : formData[`input${number}Unit`]
                    }
                  >
                    <FormInput
                      type="number"
                      name={`adcAlertValue${number}`}
                      value={formData[`adcAlertValue${number}`]}
                      error={formErrors[`adcAlertValue${number}`]}
                      onChange={handleChange}
                    />
                  </InputUnitWrapper>
                  <FormError error={formErrors[`adcAlertValue${number}`]}>{formErrors[`adcAlertValue${number}`]}</FormError>
                </div>
              </>
            )}
          </div>
        </ModalFormContainer>
      </div>
    );
  };

  if (trackerLoading) {
    return (
      <>
        <SubmitModal
          isOpen={modalOpen}
          onSubmit={handleSubmit}
          onClose={handleClose}
          title="Edit Tracker"
          submitBtnText="Update Tracker"
          body={<LoadingContainer loading={trackerLoading} />}
        />
      </>
    );
  }

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        size="lg"
        onSubmit={handleSubmit}
        onClose={handleClose}
        title={kegOrTracker("Edit Keg", "Edit Tracker")}
        success={submittedMsg}
        error={trackerErr || submittingErr}
        body={
          <LoadingContainer loading={submitting}>
            <div ref={scannerRef} style={{ position: "relative", display: scanning ? "flex" : "none" }}>
              <canvas
                className="drawingBuffer"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  border: "1px solid gray",
                }}
              />
            </div>
            <div style={{ display: scanning ? "none" : "block" }}>
              <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                  <Tab className={!generalTabValid ? "react-tabs__tab__error" : "react-tabs__tab"}>General</Tab>
                  {isAdmin() && !formData.sticker && <Tab className={!settingsTabValid ? "react-tabs__tab__error" : "react-tabs__tab"}>Settings</Tab>}
                  {!isBinaryTech() && formData.hasInput1 && isAdmin() && (
                    <Tab className={!inputTabValid ? "react-tabs__tab__error" : "react-tabs__tab"}>Inputs</Tab>
                  )}
                  {isAdmin() && <Tab>Charts</Tab>}
                  {isAdmin() && <Tab>Photo</Tab>}
                </TabList>
                <TabPanel>
                  <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
                    <ModalFormContainer>
                      <TrackerInfoPanel trackerId={formData.id} />
                    </ModalFormContainer>
                    {formData.id !== "" && (
                      <ModalFormContainer halfWidth={true}>
                        <label>Tracker ID</label>
                        <FormInput autoComplete="no" type="text" name="id" value={formData.id} disabled={true} />
                        <FormError error={formErrors.id}>{formErrors.id}</FormError>
                      </ModalFormContainer>
                    )}
                    <ModalFormContainer halfWidth={true}>
                      <label>Asset ID</label>
                      <QrScanButton title="Scan QR Code" onClick={handleEnableScan} />
                      <FormInput autoComplete="no" type="text" name="assetId" value={formData.assetId} error={formErrors.assetId} onChange={handleChange} />
                      <FormError error={formErrors.assetId}>{formErrors.assetId}</FormError>
                    </ModalFormContainer>
                    {formData.organisationName && (
                      <ModalFormContainer halfWidth={true}>
                        <label>Asset Type</label>
                        <AsyncSelect
                          name="assetType"
                          defaultOptions={true}
                          isClearable={true}
                          isError={formErrors.assetType}
                          value={formData.assetType}
                          loadOptions={(inputValue: any, callback: any) => loadOptions("assetTypes", inputValue, callback)}
                          onChange={handleSelectChange}
                          placeholder="Select..."
                        />
                        <FormError error={formErrors.assetType}>{formErrors.assetType}</FormError>
                      </ModalFormContainer>
                    )}
                    {formData.organisationName && (
                      <ModalFormContainer halfWidth={true}>
                        <label>{kegsOrTrackers("Keg Tags", "Tracker Tags")}</label>
                        <AsyncCreatableSelect
                          name="trackerTags"
                          defaultOptions={true}
                          isMulti={true}
                          isClearable={true}
                          isError={formErrors.trackerTags}
                          value={formData.trackerTags}
                          loadOptions={(inputValue: any, callback: any) => loadOptions("trackerTags", inputValue, callback)}
                          onChange={handleSelectChange}
                          placeholder="Select..."
                        />
                        <FormError error={formErrors.trackerTags}>{formErrors.trackerTags}</FormError>
                      </ModalFormContainer>
                    )}
                    <ModalFormContainer>
                      <label>Name</label>
                      <FormInput type="text" name="nickname" value={formData.nickname} onChange={handleChange} />
                      <FormError error={formErrors.nickname}>{formErrors.nickname}</FormError>
                    </ModalFormContainer>
                    {formData.hasBeer && (
                      <ModalFormContainer>
                        <label>Keg Type</label>
                        <AsyncSelect
                          name="kegType"
                          defaultOptions={true}
                          isClearable={true}
                          isError={formErrors.kegType}
                          value={formData.kegType || ""}
                          loadOptions={(inputValue: any, callback: any) => loadOptions("kegTypes", inputValue, callback)}
                          onChange={handleSelectChange}
                          placeholder="Select..."
                        />
                        <FormError error={formErrors.kegType}>{formErrors.kegType}</FormError>
                      </ModalFormContainer>
                    )}
                    <ModalFormContainer>
                      <label>Notes</label>
                      <FormInput type="text" name="notes" value={formData.notes} onChange={handleChange} />
                      <FormError error={formErrors.notes}>{formErrors.notes}</FormError>
                    </ModalFormContainer>
                  </form>
                </TabPanel>
                {isAdmin() && !formData.sticker && (
                  <TabPanel>
                    <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
                      {tracker.nextSampleDueUnix > 0 && (
                        <ModalFormContainer>
                          <WarningAlert
                            style={{
                              float: "left",
                              width: "100%",
                              marginBottom: "0",
                            }}
                          >
                            Any changes here will apply on the next upload {"("}
                            <ReactTimeago
                              live={false}
                              date={tracker.nextSampleDueUnix * 1000}
                              title={moment.unix(tracker.nextSampleDueUnix).format(long_datetime)}
                            />
                            {")"}
                          </WarningAlert>
                        </ModalFormContainer>
                      )}
                      {formData.configurableUpload && formData.hasBattery && batteryEstimate !== 0 && (
                        <BatteryEstimateContainer>
                          <LoadingContainer loading={batteryEstimateLoading}>
                            {batteryEstimate !== undefined ? (
                              <SuccessAlert
                                style={{
                                  float: "left",
                                  width: "100%",
                                  margin: 0,
                                }}
                              >
                                Approximately {humaniseHours(batteryEstimate, ["y", "mo"])} expected battery life
                              </SuccessAlert>
                            ) : batteryEstimateErr ? (
                              <DangerAlert
                                style={{
                                  float: "left",
                                  width: "100%",
                                  margin: 0,
                                }}
                              >
                                Error fetching expected battery life
                              </DangerAlert>
                            ) : (
                              <>
                                <SuccessAlert
                                  style={{
                                    float: "left",
                                    width: "100%",
                                    margin: 0,
                                  }}
                                >
                                  &nbsp;
                                </SuccessAlert>
                              </>
                            )}
                          </LoadingContainer>
                        </BatteryEstimateContainer>
                      )}
                      {formData.configurableUpload ? (
                        isBinaryBeer() ? (
                          <ModalFormContainer>
                            <label>Upload Rate</label>
                            <Select
                              name="beerUploadRate"
                              isError={formErrors.beerUploadRate}
                              value={formData.beerUploadRate}
                              onChange={handleSelectChange}
                              placeholder="Select"
                              options={[
                                { label: "1 upload / 1 day", value: 1440 },
                                { label: "1 upload / 12 hours", value: 720 },
                                { label: "1 upload / 8 hours", value: 480 },
                                { label: "1 upload / 6 hours", value: 360 },
                                { label: "1 upload / 3 hours", value: 180 },
                                { label: "1 upload / 2 hours", value: 120 },
                                { label: "1 upload / 1 hour", value: 60 },
                              ]}
                            />
                            <FormError error={formErrors.beerUploadRate}>{formErrors.beerUploadRate}</FormError>
                          </ModalFormContainer>
                        ) : (
                          <>
                            <ModalFormContainer>
                              <label style={{ float: "left" }}>Upload Rate</label>
                              <div style={{ cursor: "pointer" }}>
                                <Tooltip content={`How often do you want to upload data?`} interactive={true} touch={true} appendTo={document.body}>
                                  <InfoIconContainer>
                                    <InfoIcon fill={color.font[2]} />
                                  </InfoIconContainer>
                                </Tooltip>
                              </div>
                              <div style={{ width: "100%", clear: "both" }}>
                                <Slider
                                  name="uploadRate"
                                  unit="hours"
                                  minRange={isSuper() ? 0.1 : formData.minUploadTime / 60} // 0.1 hours for super
                                  maxRange={168} // max 1 week for all trackers
                                  step={isSuper() ? 0.1 : 1}
                                  value={formData.uploadRate} // 0.1 hours for super
                                  setValue={(value: number) => handleSliderChange("uploadRate", value)}
                                  onChange={(value: number) => handleSliderChange("uploadRate", value)}
                                />
                              </div>
                              <FormError error={formErrors.uploadRate}>{formErrors.uploadRate}</FormError>
                            </ModalFormContainer>
                            <ModalFormContainer>
                              <label style={{ float: "left" }}>Log Rate</label>
                              <div style={{ cursor: "pointer" }}>
                                <Tooltip
                                  content={`How often do you want to log temperature & movement with each upload?`}
                                  interactive={true}
                                  touch={true}
                                  appendTo={document.body}
                                >
                                  <InfoIconContainer>
                                    <InfoIcon fill={color.font[2]} />
                                  </InfoIconContainer>
                                </Tooltip>
                              </div>
                              <div style={{ width: "100%", clear: "both" }}>
                                <Slider
                                  name="logRate"
                                  unit="mins"
                                  minRange={logRateOptions.min}
                                  maxRange={logRateOptions.max}
                                  marks={logRateOptions.marks}
                                  step={null}
                                  value={formData.logRate}
                                  setValue={(value: number) => handleSliderChange("logRate", value)}
                                  onChange={(value: number) => handleSliderChange("logRate", value)}
                                />
                              </div>
                              <FormError error={formErrors.logRate}>{formErrors.logRate}</FormError>
                            </ModalFormContainer>
                          </>
                        )
                      ) : (
                        <></>
                      )}
                      {isSuper() && formData.configurableNetworkRetry && (
                        <ModalFormContainer>
                          <label>Network Retry</label>
                          <Slider
                            name="networkRetryTime"
                            unit="s"
                            minRange={100}
                            maxRange={1500}
                            value={formData.networkRetryTime}
                            setValue={(value: number) => handleSliderChange("networkRetryTime", value)}
                            onChange={(value: number) => handleSliderChange("networkRetryTime", value)}
                          />
                          <FormError error={formErrors.networkRetryTime}>{formErrors.networkRetryTime}</FormError>
                        </ModalFormContainer>
                      )}
                      {isSuper() && formData.configurableGpsRetry && (
                        <ModalFormContainer>
                          <label>GPS Retries</label>
                          <Slider
                            name="gpsRetryTime"
                            unit="s"
                            minRange={0}
                            maxRange={300}
                            value={formData.gpsRetryTime}
                            setValue={(value: number) => handleSliderChange("gpsRetryTime", value)}
                            onChange={(value: number) => handleSliderChange("gpsRetryTime", value)}
                          />
                          <FormError error={formErrors.gpsRetryTime}>{formErrors.gpsRetryTime}</FormError>
                        </ModalFormContainer>
                      )}
                      {isSuper() && formData.minAps != null && (
                        <ModalFormContainer>
                          <label>Min AP Count</label>
                          <Slider
                            name="minApCount"
                            minRange={0}
                            maxRange={11}
                            value={formData.minApCount}
                            setValue={(value: number) => handleSliderChange("minApCount", value)}
                            onChange={(value: number) => handleSliderChange("minApCount", value)}
                          />
                          <FormError error={formErrors.minApCount}>{formErrors.minApCount}</FormError>
                        </ModalFormContainer>
                      )}
                      {isSuper() && formData.maxAps != null && (
                        <ModalFormContainer>
                          <label>Max AP Count</label>
                          <Slider
                            name="maxApCount"
                            minRange={0}
                            maxRange={9}
                            value={formData.maxApCount}
                            setValue={(value: number) => handleSliderChange("maxApCount", value)}
                            onChange={(value: number) => handleSliderChange("maxApCount", value)}
                          />
                          <FormError error={formErrors.maxApCount}>{formErrors.maxApCount}</FormError>
                        </ModalFormContainer>
                      )}
                      {isSuper() && formData.hasMovementWakeup && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="enableMovementWakeup"
                            label={
                              formData.maxMovementWakeupThreshold !== undefined && formData.enableMovementWakeup === true
                                ? "Movement Wakeup Threshold"
                                : "Movement Wakeup"
                            }
                            checked={formData.enableMovementWakeup}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {isSuper() && formData.maxMovementWakeupThreshold !== undefined && formData.enableMovementWakeup === true && (
                        <ModalFormContainer>
                          <Slider
                            name="movementWakeupThreshold"
                            minRange={0}
                            maxRange={100}
                            value={formData.movementWakeupThreshold}
                            setValue={(value: number) => handleSliderChange("movementWakeupThreshold", value)}
                            onChange={(value: number) => handleSliderChange("movementWakeupThreshold", value)}
                          />
                          <FormError error={formErrors.movementWakeupThreshold}>{formErrors.movementWakeupThreshold}</FormError>
                        </ModalFormContainer>
                      )}
                      {isSuper() && formData.hasLightWakeup && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="enableLightWakeup"
                            label={
                              formData.maxLightWakeupThreshold !== undefined && formData.enableLightWakeup === true ? "Light Wakeup Threshold" : "Light Wakeup"
                            }
                            checked={formData.enableLightWakeup}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {isSuper() && formData.maxLightWakeupThreshold !== undefined && formData.enableLightWakeup === true && (
                        <ModalFormContainer>
                          <Slider
                            name="lightWakeupThreshold"
                            unit="%"
                            minRange={0}
                            maxRange={100}
                            value={formData.lightWakeupThreshold}
                            setValue={(value: number) => handleSliderChange("lightWakeupThreshold", value)}
                            onChange={(value: number) => handleSliderChange("lightWakeupThreshold", value)}
                          />
                          <FormError error={formErrors.lightWakeupThreshold}>{formErrors.lightWakeupThreshold}</FormError>
                        </ModalFormContainer>
                      )}
                      {formData.hasHighTempWakeup && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block", float: "left" }}
                            name="temperatureHighInterrupt"
                            label={formData.temperatureHighInterrupt === true ? "High Temperature Wakeup Threshold" : "High Temperature Wakeup"}
                            checked={formData.temperatureHighInterrupt}
                            onChange={handleCheckboxChange}
                          />
                          <div style={{ cursor: "pointer" }}>
                            <Tooltip
                              content={`When a tracker records a temperature above this value, it uploads straight away. Use with Temperature Alerts for immediate notifications`}
                              interactive={true}
                              touch={true}
                              appendTo={document.body}
                            >
                              <InfoIconContainer>
                                <InfoIcon fill={color.font[2]} />
                              </InfoIconContainer>
                            </Tooltip>
                          </div>
                        </ModalFormContainer>
                      )}
                      {formData.hasHighTempWakeup && formData.temperatureHighInterrupt === true && (
                        <ModalFormContainer>
                          <Slider
                            name="temperatureHighInterruptThreshold"
                            unit={printTempUnit()}
                            step={0.1}
                            minRange={convertRoundedTemp(-20)}
                            maxRange={convertRoundedTemp(85)}
                            value={formData.temperatureHighInterruptThreshold}
                            setValue={(value: number) => handleSliderChange("temperatureHighInterruptThreshold", value)}
                            onChange={(value: number) => handleSliderChange("temperatureHighInterruptThreshold", value)}
                          />
                          <FormError error={formErrors.temperatureHighInterruptThreshold}>{formErrors.temperatureHighInterruptThreshold}</FormError>
                        </ModalFormContainer>
                      )}
                      {formData.hasLowTempWakeup && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block", float: "left" }}
                            name="temperatureLowInterrupt"
                            label={formData.temperatureLowInterrupt === true ? "Low Temperature Wakeup Threshold" : "Low Temperature Wakeup"}
                            checked={formData.temperatureLowInterrupt}
                            onChange={handleCheckboxChange}
                          />
                          <div style={{ cursor: "pointer" }}>
                            <Tooltip
                              content={`When a tracker records a temperature below this value, it uploads straight away. Use with Temperature Alerts for immediate notifications`}
                              interactive={true}
                              touch={true}
                              appendTo={document.body}
                            >
                              <InfoIconContainer>
                                <InfoIcon fill={color.font[2]} />
                              </InfoIconContainer>
                            </Tooltip>
                          </div>
                        </ModalFormContainer>
                      )}
                      {formData.hasLowTempWakeup && formData.temperatureLowInterrupt === true && (
                        <ModalFormContainer>
                          <Slider
                            name="temperatureLowInterruptThreshold"
                            unit={printTempUnit()}
                            step={0.1}
                            minRange={convertRoundedTemp(-20)}
                            maxRange={convertRoundedTemp(85)}
                            value={formData.temperatureLowInterruptThreshold}
                            setValue={(value: number) => handleSliderChange("temperatureLowInterruptThreshold", value)}
                            onChange={(value: number) => handleSliderChange("temperatureLowInterruptThreshold", value)}
                          />
                          <FormError error={formErrors.temperatureLowInterruptThreshold}>{formErrors.temperatureLowInterruptThreshold}</FormError>
                        </ModalFormContainer>
                      )}
                      {formData.hasLowTempWakeup && (formData.temperatureLowInterrupt === true || formData.temperatureHighInterrupt === true) && (
                        <ModalFormContainer>
                          <label style={{ float: "left" }}>Temperature Wakeup Duration</label>
                          <div style={{ cursor: "pointer" }}>
                            <Tooltip
                              content={`When a tracker records temperatures outside the set range for a specified duration, it does an additional upload`}
                              interactive={true}
                              touch={true}
                              appendTo={document.body}
                            >
                              <InfoIconContainer>
                                <InfoIcon fill={color.font[2]} />
                              </InfoIconContainer>
                            </Tooltip>
                          </div>
                          <div style={{ width: "100%", clear: "both" }}>
                            <Slider
                              name="temperatureWakeupDuration"
                              unit="mins"
                              minRange={0}
                              maxRange={logRateOptions.max}
                              step={formData.logRate}
                              marks={undefined}
                              value={formData.temperatureWakeupDuration}
                              setValue={(value: number) => handleSliderChange("temperatureWakeupDuration", value)}
                              onChange={(value: number) => handleSliderChange("temperatureWakeupDuration", value)}
                            />
                          </div>
                          <FormError error={formErrors.temperatureWakeupDuration}>{formErrors.temperatureWakeupDuration}</FormError>
                        </ModalFormContainer>
                      )}
                      {isSuper() && (
                        <ModalFormContainer halfWidth={true}>
                          <label>MCC</label>
                          <FormInput type="number" name="mcc" step={1} value={formData.mcc} error={formErrors.mcc} onChange={handleChange} />
                          <FormError error={formErrors.mcc}>{formErrors.mcc}</FormError>
                        </ModalFormContainer>
                      )}
                      {isSuper() && (
                        <ModalFormContainer halfWidth={true}>
                          <label>MNC</label>
                          <FormInput type="number" name="mnc" step={1} value={formData.mnc} error={formErrors.mnc} onChange={handleChange} />
                          <FormError error={formErrors.mnc}>{formErrors.mnc}</FormError>
                        </ModalFormContainer>
                      )}
                      {isSuper() && formData.hasSwitchWakeup && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="enableSwitchWakeup"
                            label="Tamper Interrupt"
                            checked={formData.enableSwitchWakeup}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {isSuper() && formData.hasMagnetWakeup && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="enableMagnetWakeup"
                            label="Hall Effect Interrupt"
                            checked={formData.enableMagnetWakeup}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                    </form>
                  </TabPanel>
                )}
                {!isBinaryBeer() && formData.hasInput1 && isAdmin() && (
                  <TabPanel>
                    <form noValidate onSubmit={(e) => e.preventDefault()}>
                      <Collapse defaultActiveKey="0">
                        <Panel header={formData.input1Label ? formData.input1Label + " (Input 1)" : "Input 1"}>{getInputPanel(1)}</Panel>
                        <Panel header={formData.input2Label ? formData.input2Label + " (Input 2)" : "Input 2"}>{getInputPanel(2)}</Panel>
                        <Panel header={formData.input3Label ? formData.input3Label + " (Input 3)" : "Input 3"}>{getInputPanel(3)}</Panel>
                        <Panel header={formData.input4Label ? formData.input4Label + " (Input 4)" : "Input 4"}>{getInputPanel(4)}</Panel>
                        <Panel header={formData.input5Label ? formData.input5Label + " (Input 5)" : "Input 5"}>{getInputPanel(5)}</Panel>
                        <Panel header={formData.input6Label ? formData.input6Label + " (Input 6)" : "Input 6"}>{getInputPanel(6)}</Panel>
                        <Panel header={formData.input7Label ? formData.input7Label + " (Input 7)" : "Input 7"}>{getInputPanel(7)}</Panel>
                      </Collapse>
                    </form>
                  </TabPanel>
                )}
                {isAdmin() && (
                  <TabPanel>
                    <form noValidate onSubmit={(e) => e.preventDefault()}>
                      {formData.showSample !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showSample"
                            label="Sample Type"
                            checked={formData.showSample}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {formData.showMovement !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showMovement"
                            label="Movement"
                            checked={formData.showMovement}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}

                      {formData.showEvents !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showEvents"
                            label="Events"
                            checked={formData.showEvents}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {formData.showTemperature !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showTemperature"
                            label="Temperature"
                            checked={formData.showTemperature}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {formData.showVoltage !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showVoltage"
                            label="Voltage"
                            checked={formData.showVoltage}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showSupplyVoltage !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showSupplyVoltage"
                            label="Supply Voltage"
                            checked={formData.showSupplyVoltage}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {formData.showOrientation !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showOrientation"
                            label="Orientation"
                            checked={formData.showOrientation}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {formData.showFreshness !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showFreshness"
                            label="Freshness"
                            checked={formData.showFreshness}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showLight !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showLight"
                            label="Light"
                            checked={formData.showLight}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showEngineRunning !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showEngineRunning"
                            label="Engine Running"
                            checked={formData.showEngineRunning}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showNetwork !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showNetwork"
                            label="Network"
                            checked={formData.showNetwork}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showInput1 !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showInput1"
                            label={formData.input1Label ? formData.input1Label + " (Input 1)" : "Input 1"}
                            checked={formData.showInput1}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showInput2 !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showInput2"
                            label={formData.input2Label ? formData.input2Label + " (Input 2)" : "Input 2"}
                            checked={formData.showInput2}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showInput3 !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showInput3"
                            label={formData.input3Label ? formData.input3Label + " (Input 3)" : "Input 3"}
                            checked={formData.showInput3}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showInput4 !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showInput4"
                            label={formData.input4Label ? formData.input4Label + " (Input 4)" : "Input 4"}
                            checked={formData.showInput4}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showInput5 !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showInput5"
                            label={formData.input5Label ? formData.input5Label + " (Input 5)" : "Input 5"}
                            checked={formData.showInput5}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showInput6 !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showInput6"
                            label={formData.input6Label ? formData.input6Label + " (Input 6)" : "Input 6"}
                            checked={formData.showInput6}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {!isBinaryBeer() && formData.showInput7 !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showInput7"
                            label={formData.input7Label ? formData.input7Label + " (Input 7)" : "Input 7"}
                            checked={formData.showInput7}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {formData.showTimeOfFlight !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showTimeOfFlight"
                            label="Time Of Flight"
                            checked={formData.showTimeOfFlight}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                      {formData.showCoupled !== undefined && (
                        <ModalFormContainer>
                          <Checkbox
                            style={{ display: "inline-block" }}
                            name="showCoupled"
                            label="On Tap"
                            checked={formData.showCoupled}
                            onChange={handleCheckboxChange}
                          />
                        </ModalFormContainer>
                      )}
                    </form>
                  </TabPanel>
                )}
                {isAdmin() && (
                  <TabPanel>
                    {tracker.trackerPhotoUrl && (
                      <div style={{ position: "relative", width: "100%", margin: "0px auto", maxWidth: "400px" }}>
                        <img width="100%" height="100%" style={{ maxWidth: "400px", margin: "0 auto", borderRadius: "3px" }} src={tracker.trackerPhotoUrl} />
                        <Tooltip
                          onCreate={(instance) => (photoMenuRef.current = instance)}
                          interactive={true}
                          touch={true}
                          appendTo={document.body}
                          trigger="click"
                          placement="bottom-start"
                          maxWidth="none"
                          theme="binary-no-padding"
                          content={
                            <MenuList>
                              <MenuButton
                                onClick={() => {
                                  inputRef.current.click();
                                  hideAll();
                                }}
                              >
                                Change
                              </MenuButton>
                              {tracker.trackerPhotoUrl && (
                                <DangerMenuButton
                                  onClick={() => {
                                    setDeletePhotoModalOpen(true);
                                    hideAll();
                                  }}
                                >
                                  Delete
                                </DangerMenuButton>
                              )}
                            </MenuList>
                          }
                        >
                          <UpdateImageButton>
                            <UpdateImageButtonContainer>
                              <CameraIconContainer>
                                <CameraIcon />
                              </CameraIconContainer>
                            </UpdateImageButtonContainer>
                          </UpdateImageButton>
                        </Tooltip>
                      </div>
                    )}
                    <input
                      type="file"
                      style={{ display: tracker.trackerPhotoUrl ? "none" : "block" }}
                      ref={inputRef}
                      onChange={handleFileChange}
                      accept="image/png, image/jpeg"
                    />
                  </TabPanel>
                )}
              </Tabs>
            </div>
          </LoadingContainer>
        }
        footer={
          submittedMsg ? (
            <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
          ) : submittingErr ? (
            <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
          ) : (
            <>
              {scanning ? (
                <OutlineBtn onClick={handleDisableScan} width="100%">
                  Back
                </OutlineBtn>
              ) : (
                <>
                  <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
                  <PrimaryBtn onClick={handleSubmit}>{kegOrTracker("Update Keg", "Update Tracker")}</PrimaryBtn>
                </>
              )}
            </>
          )
        }
      />
      {uploadPhotoModalOpen && (
        <UploadPhotoModal
          modalOpen={uploadPhotoModalOpen}
          setModalOpen={setUploadPhotoModalOpen}
          photo={inputPhoto}
          trackerId={formData.id}
          organisationId={undefined}
          placeId={undefined}
          userId={undefined}
          onSuccess={() => {
            fetchTracker();
            setTabIndex(2 + (isSuper() ? 1 : 0) + (!isBinaryBeer() && formData.hasInput1 ? 1 : 0));
            setUploadPhotoModalOpen(false);
          }}
        />
      )}
      {deletePhotoModalOpen && (
        <DeleteModal
          title={kegOrTracker("Delete Keg Photo", "Delete Tracker Photo")}
          body={<span>Are you sure you want to delete this photo?</span>}
          successMsg={kegOrTracker("Keg Photo Deleted", "Tracker Photo Deleted")}
          modalOpen={deletePhotoModalOpen}
          setModalOpen={setDeletePhotoModalOpen}
          deleteService={deletePhoto}
          serviceParams={[formData.id, undefined, undefined, undefined]}
          onDelete={() => {
            fetchTracker();
            setTabIndex(2 + (isSuper() ? 1 : 0) + (!isBinaryBeer() && formData.hasInput1 ? 1 : 0));
            setDeletePhotoModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default EditTrackerModal;
