import styled from "../Themes";

export const TagText = styled<any>(`span`)`
  z-index: auto;
  border-radius: 2px;
  min-width: 20px;
  min-height: 20px;
  line-height: 1.4rem;
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  margin: 1px;
  font-size: 0.85rem;
  overflow: hidden;
  padding: 0 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  font-weight: 400;
  cursor: default;
`;

export const TagContainer = styled(`span`)`
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 100%;
`;
