import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { MdEdit } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { hideAll } from "tippy.js";
import { deletePickupRequest } from "../../services/pickupRequests";
import { fetchSensorPage } from "../../services/sensorPage";
import MoreIcon from "../../svgs/MoreIcon";
import { isBinaryMed } from "../../util/checkDomain";
import { isAdmin, isAdminOrUser, isSuper } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import getParameterByName from "../../util/getParamByName";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import useWindowSize from "../../util/useWindowSize";
import ActivateSelectedSensorModal from "../ActivateSelectedSensorModal";
import AdditionalSensorInfoModal from "../AdditionalSensorInfoModal";
import AlertLogsTable from "../AlertLogsTable";
import { OutlineBtn } from "../Buttons";
import ChartHeading from "../ChartHeading";
import CreateManifestModel from "../CreateManifestModel";
import DateRangeList from "../DateRangeList";
import DeactivateSelectedSensorModal from "../DeactivateSelectedSensorModal";
import DeleteModal from "../DeleteModal";
import EditPickupRequestModal from "../EditPickupRequestModal";
import EditTrackerModal from "../EditTrackerModal";
import LoadingContainer from "../LoadingContainer";
import MarkAsCollectedModel from "../MarkAsCollectedModel";
import MarkAsEmptyModal from "../MarkAsEmptyModal";
import MarkAsFilledModal from "../MarkAsFilledModal";
import { SubmitModal } from "../Modal";
import PageBreadcrumbs from "../PageBreadcrumbs";
import { PageContainer } from "../PageStyles";
import SensorHistory from "../SensorHistory";
import SensorHops from "../SensorHops";
import SensorLogs from "../SensorLogs";
import SensorMeta from "../SensorMeta";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList, SuccessMenuButton } from "../Tooltip/styles";
import TrackerCsvDownloadModal from "../TrackerCsvDownloadModal";
import TrackerManifestsTable from "../TrackerManifestsTable";
import UpdateLocationModal from "../UpdateLocationModal";
import { AlertWrapper, ChartWrapper, FlexRow, HistoryWrapper, MapWrapper, MetaWrapper } from "./styles";
import TrackerServiceLogsModal from "../TrackerServiceLogsModal";
import ChangeBeerModal from "../ChangeBeerModal";

const TrackerScreen: FC = () => {
  const { color } = useContext(ThemeContext);

  const location = useLocation();
  const { trackerId } = useParams();

  const { width } = useWindowSize();

  // Gets details from URL query parameters if available to set date range
  const startDateParam = getParameterByName("start", location.search);
  const endDateParam = getParameterByName("end", location.search);
  const dateLabelParam = getParameterByName("label", location.search);

  const [filterDates, setFilterDates] = useState<any>({
    start: startDateParam !== null ? moment.unix(+startDateParam) : moment().subtract(7, "days"),
    end: endDateParam !== null ? moment.unix(+endDateParam) : moment(),
    dateLabel: startDateParam !== null || endDateParam !== null ? dateLabelParam : "Last 7 days",
  });

  const [map, setMap] = useState<any>(undefined);
  const [meta, setMeta] = useState<any>({});
  const [hops, setHops] = useState<any>([]);
  const [places, setPlaces] = useState<any>([]);
  const [logs, setLogs] = useState<any>([]);
  const [events, setEvents] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);
  const [pieces, setPieces] = useState<any>([]);
  const [history, setHistory] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [cursor, setCursor] = useState<any>(Infinity);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [activateModalOpen, setActivateModalOpen] = useState<boolean>(false);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState<boolean>(false);
  const [markAsFilledModalOpen, setMarkAsFilledModalOpen] = useState<boolean>(false);
  const [changeBeerModalOpen, setChangeBeerModalOpen] = useState<boolean>(false);
  const [createPickupRequestModalOpen, setCreatePickupRequestModalOpen] = useState<boolean>(false);
  const [deletePickupRequestModalOpen, setDeletePickupRequestModalOpen] = useState<boolean>(false);
  const [markAsCollectedModalOpen, setMarkAsCollectedModalOpen] = useState<any>(false);
  const [additionalInfoModalOpen, setAdditionalInfoModalOpen] = useState<boolean>(false);
  const [locationAlertModalOpen, setLocationAlertModalOpen] = useState<boolean>(false);
  const [updateLocationModalOpen, setUpdateLocationModalOpen] = useState<boolean>(false);
  const [markAsEmptyModalOpen, setMarkAsEmptyModalOpen] = useState<boolean>(false);
  const [csvModalOpen, setCsvModalOpen] = useState<boolean>(false);
  const [createManifestModalOpen, setCreateManifestModalOpen] = useState<boolean>(false);
  const [trackerServicesModalOpen, setTrackerServicesModalOpen] = useState<boolean>(false);

  // Used to trigger a re-fetch of data in child components
  const [fetchCount, setFetchCount] = useState<number>(0);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const startDateParam = getParameterByName("start", location.search);
    const endDateParam = getParameterByName("end", location.search);
    const dateLabelParam = getParameterByName("label", location.search);
    setFilterDates({
      start: startDateParam !== null ? moment.unix(+startDateParam) : moment().subtract(7, "days"),
      end: endDateParam !== null ? moment.unix(+endDateParam) : moment(),
      dateLabel: startDateParam !== null || endDateParam !== null ? dateLabelParam : "Last 7 days",
    });
  }, [location]);

  const fetchData = () => {
    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    if (dates && trackerId !== undefined) {
      setDataLoading(true);
      setDataErr("");
      setFetchCount(fetchCount + 1);

      fetchSensorPage(source, trackerId, dates)
        .then((response) => {
          setMeta(response.meta);
          setHops(response.hops);
          setPlaces(response.places);
          setHistory(response.history);

          if (response.history.length > 0) {
            // adds first log to new events
            const newEvents = [];

            // loops through all events
            for (let i = 0; i < response.history.length; i++) {
              const tempEvent = response.history[i];
              tempEvent.eventDate = Math.round(tempEvent.eventDate);
              if (tempEvent.eventDate >= dates.start && tempEvent.eventDate <= dates.end) {
                newEvents.push([tempEvent.eventDate, tempEvent, 0.5]);
              }
            }
            setEvents(newEvents);
          } else {
            setEvents([]);
          }

          // the logs from the api are "compressed" by removing values from each log if it is the same
          // as the previous log, this code adds those back in so the graphs are displayed correctly
          if (response.logs.length > 0) {
            // gets the logs from the response
            const { logs, series } = response;

            // gets the log's keys from the series object
            const props = [];
            for (const [key, value] of Object.entries(series)) {
              if (value === true) props.push(key);
            }
            setSeries([...props, "const"]);

            // adds first log to new logs
            const newLogs = [{ ...logs[0], const: 1 }];
            const newPieces = [];

            // round all timestamps because echarts doesn't like decimals
            newLogs[0].ts = Math.round(newLogs[0].ts);

            // loops through all logs
            for (let i = 1; i < logs.length; i++) {
              // create new temp log to repopulate missing fields
              const tempLog = logs[i];

              // for each prop, check if missing and if so take value from previous log if it isn't a on/off type prop
              for (let j = 0; j < props.length; j++) {
                if (logs[i][props[j]] === undefined && props[j] !== "sample" && props[j] !== "lightInterrupt") {
                  tempLog[props[j]] = newLogs[i - 1][props[j]];
                }
              }

              // round all timestamps because echarts doesn't like decimals
              tempLog.ts = Math.round(tempLog.ts);
              tempLog.const = 1;

              // if light log and value > 100, set to 100
              if (tempLog.light !== undefined && tempLog.light > 100) {
                tempLog.light = 100;
              }

              // add temp log to new logs array
              newLogs[i] = tempLog;
              newPieces[i - 1] = { min: logs[i - 1].ts - 1, max: logs[i].ts + 1, color: logs[i - 1].placeGroupColour };
            }
            setLogs(newLogs);
            setPieces(newPieces);
          } else {
            setLogs([]);
          }
          setDataLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setDataErr(errToStr(err));
            setDataLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [filterDates, trackerId]);

  return (
    <>
      <PageBreadcrumbs
        height="57px"
        prevRoutes={[
          {
            slug: `/${kegsOrTrackers("kegs", "trackers")}`,
            title: kegsOrTrackers("Kegs", "Trackers"),
          },
        ]}
        currRoute={trackerId}
        rightContent={
          width < 768 ? (
            <>
              <DateRangeList
                filterDates={filterDates}
                setFilterDates={setFilterDates}
                minDate={moment.unix(meta.dateActivated)}
                maxDate={moment.unix(meta.latestSampleDate)}
              />
              <Tooltip
                maxWidth="none"
                theme="binary-no-padding"
                content={
                  <MenuList>
                    {isAdminOrUser() && (
                      <MenuButton
                        onClick={() => {
                          setEditModalOpen(true);
                          hideAll();
                        }}
                      >
                        Edit {kegOrTracker("Keg", "Tracker")}
                      </MenuButton>
                    )}
                    {isAdminOrUser() ? (
                      meta.dateMarkedForPickup ? (
                        <>
                          <MenuButton
                            onClick={() => {
                              setMarkAsCollectedModalOpen(true);
                              hideAll();
                            }}
                          >
                            Mark as Collected
                          </MenuButton>
                          <MenuButton
                            onClick={() => {
                              setDeletePickupRequestModalOpen(true);
                              hideAll();
                            }}
                          >
                            Delete Pickup Request
                          </MenuButton>
                        </>
                      ) : (
                        <MenuButton
                          onClick={() => {
                            setCreatePickupRequestModalOpen(true);
                            hideAll();
                          }}
                        >
                          Create Pickup Request
                        </MenuButton>
                      )
                    ) : (
                      <></>
                    )}
                    {meta.hasBeer && isAdminOrUser() ? (
                      meta.empty === false ? (
                        <>
                          <MenuButton
                            onClick={() => {
                              setMarkAsEmptyModalOpen(true);
                              hideAll();
                            }}
                          >
                            Mark as Emptied
                          </MenuButton>{" "}
                          <MenuButton
                            onClick={() => {
                              setChangeBeerModalOpen(true);
                              hideAll();
                            }}
                          >
                            Change Beer
                          </MenuButton>
                        </>
                      ) : (
                        <MenuButton
                          onClick={() => {
                            setMarkAsFilledModalOpen(true);
                            hideAll();
                          }}
                        >
                          Mark as Filled
                        </MenuButton>
                      )
                    ) : (
                      <></>
                    )}
                    {isAdminOrUser() && (
                      <MenuButton
                        onClick={() => {
                          setUpdateLocationModalOpen(true);
                          hideAll();
                        }}
                      >
                        Update Location
                      </MenuButton>
                    )}
                    {isBinaryMed() && (
                      <MenuButton
                        onClick={() => {
                          setCreateManifestModalOpen(true);
                          hideAll();
                        }}
                      >
                        Create Manifest
                      </MenuButton>
                    )}
                    {isBinaryMed() && (
                      <MenuButton
                        onClick={() => {
                          setTrackerServicesModalOpen(true);
                          hideAll();
                        }}
                      >
                        Service Logs
                      </MenuButton>
                    )}
                    <MenuButton
                      onClick={() => {
                        setCsvModalOpen(true);
                        hideAll();
                      }}
                    >
                      Download CSV
                    </MenuButton>
                    {isSuper() && (
                      <MenuButton
                        onClick={() => {
                          setAdditionalInfoModalOpen(true);
                          hideAll();
                        }}
                      >
                        More Data
                      </MenuButton>
                    )}
                    {isSuper() && meta.organisationName == null && (
                      <SuccessMenuButton
                        onClick={() => {
                          setActivateModalOpen(true);
                          hideAll();
                        }}
                      >
                        Activate Tracker
                      </SuccessMenuButton>
                    )}
                    {isAdmin() && meta.organisationName !== null && (
                      <DangerMenuButton
                        onClick={() => {
                          setDeactivateModalOpen(true);
                          hideAll();
                        }}
                      >
                        Deactivate Tracker
                      </DangerMenuButton>
                    )}
                  </MenuList>
                }
                interactive={true}
                touch={true}
                appendTo={document.body}
                trigger="click"
                placement="bottom-start"
              >
                <div style={{ display: "inline-block", margin: "12px 6px" }}>
                  <Tooltip content="More" hideOnClick={true} trigger="mouseenter">
                    <OutlineBtn key="edit" style={{ height: "40px", minWidth: "unset" }} padding="0 6px">
                      <div
                        style={{
                          padding: "4px",
                          width: "26px",
                          height: "26px",
                          lineHeight: "0",
                          margin: "0 2px",
                        }}
                      >
                        <MoreIcon fill={color.font[2]} />
                      </div>
                    </OutlineBtn>
                  </Tooltip>
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <DateRangeList
                filterDates={filterDates}
                setFilterDates={setFilterDates}
                minDate={moment.unix(meta.dateCreated)}
                maxDate={moment()}
                minWidth={768}
                enableTime={false}
              />
              {isAdminOrUser() && (
                <Tooltip content={`Edit ${kegOrTracker("Keg", "Tracker")}`}>
                  <OutlineBtn
                    style={{
                      height: "40px",
                      margin: "0 6px",
                      minWidth: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key="edit"
                    onClick={() => setEditModalOpen(true)}
                  >
                    <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                      <MdEdit />
                    </IconContext.Provider>
                  </OutlineBtn>
                </Tooltip>
              )}
              <Tooltip
                maxWidth="none"
                theme="binary-no-padding"
                content={
                  <MenuList>
                    {isAdminOrUser() ? (
                      meta.dateMarkedForPickup ? (
                        <>
                          <MenuButton
                            onClick={() => {
                              setMarkAsCollectedModalOpen(true);
                              hideAll();
                            }}
                          >
                            Mark as Collected
                          </MenuButton>
                          <MenuButton
                            onClick={() => {
                              setDeletePickupRequestModalOpen(true);
                              hideAll();
                            }}
                          >
                            Delete Pickup Request
                          </MenuButton>
                        </>
                      ) : (
                        <MenuButton
                          onClick={() => {
                            setCreatePickupRequestModalOpen(true);
                            hideAll();
                          }}
                        >
                          Create Pickup Request
                        </MenuButton>
                      )
                    ) : (
                      <></>
                    )}
                    {meta.hasBeer && isAdminOrUser() ? (
                      meta.empty === false ? (
                        <>
                          <MenuButton
                            onClick={() => {
                              setMarkAsEmptyModalOpen(true);
                              hideAll();
                            }}
                          >
                            Mark as Emptied
                          </MenuButton>{" "}
                          <MenuButton
                            onClick={() => {
                              setChangeBeerModalOpen(true);
                              hideAll();
                            }}
                          >
                            Change Beer
                          </MenuButton>
                        </>
                      ) : (
                        <MenuButton
                          onClick={() => {
                            setMarkAsFilledModalOpen(true);
                            hideAll();
                          }}
                        >
                          Mark as Filled
                        </MenuButton>
                      )
                    ) : (
                      <></>
                    )}
                    {isAdminOrUser() && (
                      <MenuButton
                        onClick={() => {
                          setUpdateLocationModalOpen(true);
                          hideAll();
                        }}
                      >
                        Update Location
                      </MenuButton>
                    )}
                    {isBinaryMed() && (
                      <MenuButton
                        onClick={() => {
                          setCreateManifestModalOpen(true);
                          hideAll();
                        }}
                      >
                        Create Manifest
                      </MenuButton>
                    )}
                    {isBinaryMed() && (
                      <MenuButton
                        onClick={() => {
                          setTrackerServicesModalOpen(true);
                          hideAll();
                        }}
                      >
                        Service Logs
                      </MenuButton>
                    )}
                    <MenuButton
                      onClick={() => {
                        setCsvModalOpen(true);
                        hideAll();
                      }}
                    >
                      Download CSV
                    </MenuButton>
                    {isSuper() && (
                      <MenuButton
                        onClick={() => {
                          setAdditionalInfoModalOpen(true);
                          hideAll();
                        }}
                      >
                        More Data
                      </MenuButton>
                    )}
                    {isSuper() && meta.organisationName == null && (
                      <SuccessMenuButton
                        onClick={() => {
                          setActivateModalOpen(true);
                          hideAll();
                        }}
                      >
                        Activate Tracker
                      </SuccessMenuButton>
                    )}
                    {isAdmin() && meta.organisationName !== null && (
                      <DangerMenuButton
                        onClick={() => {
                          setDeactivateModalOpen(true);
                          hideAll();
                        }}
                      >
                        Deactivate Tracker
                      </DangerMenuButton>
                    )}
                  </MenuList>
                }
                interactive={true}
                touch={true}
                appendTo={document.body}
                trigger="click"
                placement="bottom-start"
              >
                <div style={{ display: "inline-block", margin: "12px 6px" }}>
                  <Tooltip content="More" hideOnClick={true} trigger="mouseenter">
                    <OutlineBtn key="edit" style={{ height: "40px", minWidth: "unset" }} padding="0 6px">
                      <div
                        style={{
                          padding: "4px",
                          width: "26px",
                          height: "26px",
                          lineHeight: "0",
                          margin: "0 2px",
                        }}
                      >
                        <MoreIcon fill={color.font_bold[2]} />
                      </div>
                    </OutlineBtn>
                  </Tooltip>
                </div>
              </Tooltip>
            </>
          )
        }
      />
      <PageContainer top="57px" slim={true}>
        <LoadingContainer loading={dataLoading} err={dataErr}>
          {!dataErr && (
            <>
              <FlexRow>
                <MetaWrapper>
                  <SensorMeta
                    trackerId={trackerId}
                    data={meta}
                    setData={setMeta}
                    dataLoading={dataLoading}
                    filterDates={filterDates}
                    setFilterDates={setFilterDates}
                    fetchData={fetchData}
                  />
                </MetaWrapper>
                <MapWrapper>
                  <SensorHops map={map} setMap={setMap} hops={hops} places={places} dataLoading={dataLoading} cursor={cursor} meta={meta} />
                </MapWrapper>
              </FlexRow>
              {isBinaryMed() && (
                <TrackerManifestsTable trackerId={trackerId} filterDates={filterDates} fetchCount={fetchCount} placeId={meta.placeId} placeName={meta.place} />
              )}
              <FlexRow>
                <ChartWrapper>
                  <SensorLogs map={map} logs={logs} pieces={pieces} events={events} meta={meta} loading={dataLoading} series={series} setCursor={setCursor} />
                </ChartWrapper>
              </FlexRow>
              <FlexRow>
                <HistoryWrapper>
                  <SensorHistory data={history} loading={dataLoading} />
                </HistoryWrapper>
              </FlexRow>
              <FlexRow>
                <AlertWrapper>
                  <ChartHeading>Alert Notifications</ChartHeading>
                  <AlertLogsTable sensorId={trackerId} filterDates={filterDates} />
                </AlertWrapper>
              </FlexRow>
            </>
          )}
        </LoadingContainer>
      </PageContainer>
      {editModalOpen && <EditTrackerModal id={meta.sensorId} onSuccess={fetchData} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {createPickupRequestModalOpen && (
        <EditPickupRequestModal
          trackerId={meta.sensorId}
          onSuccess={fetchData}
          modalOpen={createPickupRequestModalOpen}
          setModalOpen={setCreatePickupRequestModalOpen}
        />
      )}
      {markAsCollectedModalOpen && (
        <MarkAsCollectedModel id={meta.pickupRequestId} onSuccess={fetchData} modalOpen={markAsCollectedModalOpen} setModalOpen={setMarkAsCollectedModalOpen} />
      )}
      {deletePickupRequestModalOpen && (
        <DeleteModal
          title="Delete Pickup Request"
          body={
            <>
              <span>Are you sure you want to delete this pickup request?</span>
            </>
          }
          successMsg="Pickup Request Deleted"
          onDelete={fetchData}
          modalOpen={deletePickupRequestModalOpen}
          setModalOpen={setDeletePickupRequestModalOpen}
          deleteService={deletePickupRequest}
          serviceParams={[meta.sensorId]}
          id={meta.sensorId}
        />
      )}
      {markAsFilledModalOpen && (
        <MarkAsFilledModal
          sensor={{ sensorId: meta.sensorId }}
          place={{ placeId: meta.placeId, placeName: meta.place }}
          onSuccess={fetchData}
          noDate={true}
          modalOpen={markAsFilledModalOpen}
          setModalOpen={setMarkAsFilledModalOpen}
        />
      )}
      {changeBeerModalOpen && (
        <ChangeBeerModal
          sensor={{ sensorId: meta.sensorId }}
          place={{ placeId: meta.placeIdFilled, placeName: meta.placeFilled }}
          onSuccess={fetchData}
          noDate={true}
          modalOpen={changeBeerModalOpen}
          setModalOpen={setChangeBeerModalOpen}
        />
      )}
      {markAsEmptyModalOpen && (
        <MarkAsEmptyModal
          sensor={{ sensorId: meta.sensorId, latestSampleDate: meta.latestSampleDate, latitude: meta.latitude, longitude: meta.longitude }}
          onSuccess={fetchData}
          modalOpen={markAsEmptyModalOpen}
          setModalOpen={setMarkAsEmptyModalOpen}
        />
      )}
      {updateLocationModalOpen && (
        <UpdateLocationModal
          sensor={{ trackerId: meta.sensorId, latitude: meta.latitude, longitude: meta.longitude }}
          onSuccess={fetchData}
          modalOpen={updateLocationModalOpen}
          setModalOpen={setUpdateLocationModalOpen}
        />
      )}
      {activateModalOpen && (
        <ActivateSelectedSensorModal id={meta.sensorId} onSuccess={fetchData} modalOpen={activateModalOpen} setModalOpen={setActivateModalOpen} />
      )}
      {deactivateModalOpen && (
        <DeactivateSelectedSensorModal id={meta.sensorId} onSuccess={fetchData} modalOpen={deactivateModalOpen} setModalOpen={setDeactivateModalOpen} />
      )}
      {csvModalOpen && <TrackerCsvDownloadModal modalOpen={csvModalOpen} setModalOpen={setCsvModalOpen} id={trackerId} tracker={meta} />}
      {additionalInfoModalOpen && isSuper() && (
        <AdditionalSensorInfoModal modalOpen={additionalInfoModalOpen} setModalOpen={setAdditionalInfoModalOpen} data={meta.superMeta} />
      )}
      {createManifestModalOpen && (
        <CreateManifestModel
          onSuccess={fetchData}
          trackerId={trackerId}
          placeId={meta.placeId}
          placeName={meta.place}
          modalOpen={createManifestModalOpen}
          setModalOpen={setCreateManifestModalOpen}
        />
      )}
      {locationAlertModalOpen && (
        <SubmitModal
          isOpen={locationAlertModalOpen}
          onSubmit={() => {
            setLocationAlertModalOpen(false);
            setUpdateLocationModalOpen(true);
          }}
          onClose={() => {
            setLocationAlertModalOpen(false);
          }}
          title="Update Location"
          submitBtnText="Update"
          body={
            <span>
              The Tracker must be at a place to add contents. <br />
              Would you like to update the tracker's location?
            </span>
          }
        />
      )}
      {trackerServicesModalOpen && (
        <TrackerServiceLogsModal trackerId={trackerId} onSuccess={fetchData} modalOpen={trackerServicesModalOpen} setModalOpen={setTrackerServicesModalOpen} />
      )}
    </>
  );
};

export default TrackerScreen;
