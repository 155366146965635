import axios, { CancelTokenSource } from "axios";

type GetManifestsProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export type ContentsDto = {
  id: number;
  name: string;
  identifier?: string;
  timestamp?: Date;
};

export type ManifestMapPointDto = {
  placeId?: number;
  placeName?: string;
  latitude: number;
  longitude: number;
  timestamp?: string;
  deadline?: string;
  radius?: number;
  markerType: string; // START_PLACE, END_PLACE, TARGET_PLACE, LATEST_LOCATION, CONTENTS_ADDED, CONTENTS_REMOVED
  contentsAddedCount?: number;
  contentsRemovedCount?: number;
};

export type ManifestMapDto = {
  id: number;
  status: string;
  startTime?: Date;
  endTime?: Date;
  deadlineTime?: Date;
  sensorId: string;
  description: string;
  points: ManifestMapPointDto[];
};

// get manifests
export const getManifests = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: GetManifestsProps) => {
  const params: any = {
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/manifests",
    params,
  });

  return { count: headers?.count, data };
};

// get manifest
export const getManifest = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/manifests/${id ? id : ""}`,
  });

  return data;
};

// get manifest map
export const getManifestMap = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/manifests/${id ? id : ""}/map`,
  });

  return data;
};

// create manifest
export const postManifest = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + `/v1/manifests`,
    data: JSON.stringify(body),
  });

  return data;
};

// update manifest
export const putManifest = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/manifests/UpdateManifest`,
    data: JSON.stringify(body),
  });

  return data;
};

// end manifest
export const endManifest = async (source: CancelTokenSource, manifestId: string, endPlaceId: string, endUserId: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + `/v1/manifests/CloseManifest/?manifestId=${manifestId}&endPlaceId=${endPlaceId}&endUserId=${endUserId}`,
  });

  return data;
};

// mark manifest for pickup
export const markManifestForPickup = async (source: CancelTokenSource, manifestId: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/manifests/MarkForPickup/?manifestId=${manifestId}`,
  });

  return data;
};
