import Tippy from "@tippy.js/react";
import React from "react";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/tippy.css";
import isTouch from "../../util/isTouch";
import { IProps } from "./types";

const Tooltip: React.FC<IProps> = ({ touch = !isTouch, trigger = "mouseenter click", theme = "binary", ...rest }) => {
  return (
    <>
      <Tippy
        {...rest}
        theme={theme}
        animation="shift-away"
        duration={[100, 100]}
        touch={touch}
        arrow={true}
        trigger={trigger}
        boundary="window"
        maxWidth={500}
      />
    </>
  );
};

export default Tooltip;
