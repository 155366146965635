import axios from "axios";
import matchSorter from "match-sorter";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { ThemeContext } from "styled-components";
import { deleteAlerts, fetchAlerts } from "../../services/editAlerts";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import Badge from "../Badge";
import Bold from "../Bold";
import { PrimaryBtn } from "../Buttons";
import DeleteModal from "../DeleteModal";
import EditAlertModal from "../EditAlertModal";
import { PrimaryIconBtn } from "../IconButtons";
import LoadingContainer from "../LoadingContainer";
import { DesktopDiv, MobileDiv, TableHeaderButtons } from "../NewTable/styles";
import ScheduleModal from "../ScheduleModal";
import Table, { CsvButtonsComponent } from "../Table";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

const AlertsTable: FC<any> = () => {
  const { color } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [selectedId, setSelectedId] = useState<any>(undefined);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [selectedSchedule, setSelectedSchedule] = useState<any>(undefined);
  const [scheduleModalOpen, setScheduleModalOpen] = useState<boolean>(false);

  const tableRef = useRef<any>(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchAlerts(source)
      .then((response) => {
        const formattedData = response.map((item: { conditions: string[] }) => ({
          ...item,
          conditions: buildConditionsArray(item),
        }));
        setData(formattedData);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  const buildConditionsArray = (e: any) => {
    if (e) {
      const s = [];

      if (e.startDate) s.push(`Start Date: ${e.startDate ? moment(e.startDate).format("YYYY-MM-DD") : ""}`);
      if (e.endDate) s.push(`End Date: ${e.endDate ? moment(e.endDate).format("YYYY-MM-DD") : ""}`);
      if (e.sensorId) s.push(`${kegsOrTrackers("Keg", "Tracker")}: ${e.sensorId}${e.nickname ? " (" + e.nickname + ")" : ""}`);
      if (e.placeId)
        s.push(
          `${e.alertEnter && e.alertLeave ? "Enters or Leaves" : e.alertEnter ? "Enters" : e.alertLeave ? "Leaves" : "At"} Place: ${
            e.placeName ? e.placeName : e.placeId
          }`
        );
      if (e.sensorGroup) s.push(`${kegsOrTrackers("Keg Tag", "Tracker Tag")}: ${e.sensorGroup}`);
      if (e.assetId) s.push(`Asset ID: ${e.assetId}`);
      if (e.assetTypeId) s.push(`Asset Type: ${e.assetTypeName}`);
      if (e.placeTagId)
        s.push(`${e.alertEnter && e.alertLeave ? "Enters or Leaves" : e.alertEnter ? "Enters" : e.alertLeave ? "Leaves" : "At"} Place Tag: ${e.placeTagName}`);
      if (e.placeGroup)
        s.push(`${e.alertEnter && e.alertLeave ? "Enters or Leaves" : e.alertEnter ? "Enters" : e.alertLeave ? "Leaves" : "At"} Place Type: ${e.placeGroup}`);
      if (e.latitude)
        s.push(`${e.alertEnter && e.alertLeave ? "Enters or Leaves" : e.alertEnter ? "Enters" : e.alertLeave ? "Leaves" : "At"} Geofence Location`);
      if (e.productId) s.push(`Tracker Type: ${e.productName ? e.productName : e.productId}`);
      if (e.contentTagId) s.push(`Content Tag: ${e.contentTagName}`);
      if (e.allManifests) s.push(`All Manifests`);
      if (e.manifestCreatedPlaceId) s.push(`Manifests created at ${e.manifestCreatedPlaceName}`);
      if (e.manifestDestinationPlaceId) s.push(`Manifests destination at ${e.manifestDestinationPlace}`);

      return s;
    } else {
      return [];
    }
  };

  const formatDataToCsv = (tableRef: any) => {
    const headers = ["Alert Name", "Conditions", "Email Addresses", "Phone Numbers", "Webhooks", "Schedule", "Start Date", "End Date", "Active"];

    const data = tableRef.current.getResolvedState().sortedData;

    return [
      headers,
      ...data.map((row: any) => {
        const data = [
          row.alertName,
          buildConditionsArray(row._original).join(", "),
          row.emailAddress.join(", "),
          row.phoneNumber.join(", "),
          row.webhook.join(", "),
          row.schedules && row.schedules.length > 0 ? "True" : "False",
          moment(row.startDate).format("YYYY-MM-DD"),
          moment(row.endDate).format("YYYY-MM-DD"),
          row.enabled ? "Active" : "Inactive",
        ];

        return data;
      }, []),
    ];
  };

  const columns = [];

  columns.push({
    Header: "Actions",
    minWidth: 110,
    maxWidth: 110,
    filterable: false,
    sortable: false,
    Cell: ({ original }: any) => {
      return (
        <Tooltip
          maxWidth="none"
          theme="binary-no-padding"
          content={
            <MenuList>
              {isAdminOrUser() ? (
                <>
                  <MenuButton
                    onClick={() => {
                      setSelectedId(original.id);
                      setEditModalOpen(true);
                    }}
                  >
                    Edit Alert
                  </MenuButton>
                  <DangerMenuButton
                    onClick={() => {
                      setSelectedId(original.id);
                      setDeleteModalOpen(true);
                    }}
                  >
                    Delete Alert
                  </DangerMenuButton>
                </>
              ) : (
                <>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <MenuButton disabled={true}>Edit Alert</MenuButton>
                    </div>
                  </Tooltip>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <MenuButton disabled={true}>Delete Alert</MenuButton>
                    </div>
                  </Tooltip>
                </>
              )}
            </MenuList>
          }
          interactive={true}
          touch={true}
          appendTo={document.body}
          trigger="click"
          placement="bottom-start"
        >
          <MoreIconContainer>
            <MoreIconSize>
              <MoreIcon fill={color.font[2]} />
            </MoreIconSize>
          </MoreIconContainer>
        </Tooltip>
      );
    },
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "alertName",
    Header: "Alert Name",
    accessor: "alertName",
    minWidth: 300,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["alertName"],
      }),
    filterAll: true,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
  });

  columns.push({
    id: "conditions",
    Header: "Conditions",
    accessor: "conditions",
    minWidth: 300,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["conditions"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const value = props.value;
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((item, index) => (
              <Tag key={index} name={item} colour={color.primary[2]} />
            ))}
          </div>
        );
      }
      return <span></span>;
    },
  });

  columns.push({
    id: "emailAddress",
    Header: "Email Addresses",
    accessor: "emailAddress",
    minWidth: 220,
    maxWidth: 220,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["emailAddress"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const value = props.value;
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((item, index) => (
              <Tag key={index} name={item} colour={color.primary[2]} />
            ))}
          </div>
        );
      }
      return <span></span>;
    },
  });

  columns.push({
    id: "phoneNumber",
    Header: "Phone Numbers",
    accessor: "phoneNumber",
    minWidth: 220,
    maxWidth: 220,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["phoneNumber"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const value = props.value;
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((item, index) => (
              <Tag key={index} name={item} colour={color.primary[2]} />
            ))}
          </div>
        );
      }
      return <span></span>;
    },
  });

  columns.push({
    id: "webhook",
    Header: "Webhooks",
    accessor: "webhook",
    minWidth: 250,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["webhook"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const value = props.value;
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((item, index) => (
              <Tag key={index} name={item} colour={color.primary[2]} />
            ))}
          </div>
        );
      }
      return <span></span>;
    },
  });

  columns.push({
    id: "schedules",
    Header: "Schedule",
    accessor: "schedules",
    minWidth: 130,
    maxWidth: 130,
    Cell: (props: any) => (
      <div
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() => {
          setSelectedSchedule(props.original);
          setScheduleModalOpen(true);
        }}
      >
        {props.value && props.value.length > 0 ? "View" : ""}
      </div>
    ),
  });

  columns.push({
    id: "enabled",
    Header: "Active",
    accessor: "enabled",
    minWidth: 120,
    maxWidth: 120,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "active") {
        return row[filter.id] === true;
      }
      if (filter.value === "inactive") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select>
    ),
    Cell: (props: any) => (
      <Badge title={props.value ? "Active" : "Inactive"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
        {props.value ? "Active" : "Inactive"}
      </Badge>
    ),
  });

  const defaultSorted = [
    {
      id: "alertName",
      desc: true,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedId(null);
                      setEditModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedId(null);
                        setEditModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
          <CsvButtonsComponent data={data} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Alert List.csv" />
        </TableHeaderButtons>
        <Table
          loading={dataLoading}
          filterable={true}
          style={{ clear: "both" }}
          data={data}
          columns={columns}
          ref={tableRef}
          defaultPageSize={15}
          defaultSorted={defaultSorted}
        />
      </LoadingContainer>
      {editModalOpen && <EditAlertModal id={selectedId} setData={setData} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Alert"
          body={<span>Are you sure you want to delete this alert?</span>}
          successMsg="Alert Deleted"
          onDelete={() => setData((prev: any) => prev.filter((row: any) => row.id !== selectedId))}
          onClose={() => setSelectedId(undefined)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deleteAlerts}
          serviceParams={[selectedId]}
        />
      )}
      {scheduleModalOpen && <ScheduleModal modalOpen={scheduleModalOpen} setModalOpen={setScheduleModalOpen} data={selectedSchedule} editable={false} />}
    </div>
  );
};

export default AlertsTable;
