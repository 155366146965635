import React, { FC, useState, useEffect, useContext, useCallback } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { FormError, FormInput } from "../FormComponents";
import { exists } from "../../util/formValidations";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncSelect, Select } from "../Select";
import { fetchAutoComplete, fetchAutoCompleteBatches } from "../../services/autoComplete";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { changeBeerBatch } from "../../services/fillKeg";
import Flatpickr from "react-flatpickr";
import { saveLastFillEvent } from "../../services/localStorage";
import moment from "moment";
import { ThemeContext } from "styled-components";
import ReactTimeago from "react-timeago";
import EditBeerBatchModal from "../EditBeerBatchModal";
import { WarningAlert } from "../Alerts";

// Initialise form data with data from the selected event
const initEvent = (sensor: any) => {
  const date = new Date();
  let sensorId = undefined;

  if (sensor && sensor.sensorId) sensorId = sensor.sensorId;

  return {
    sensorId,
    brewery: undefined,
    beer: undefined,
    batch: undefined,
  };
};

const ChangeBeerModal: FC<any> = ({ sensor, place, onSuccess, noDate, modalOpen, setModalOpen }) => {
  const { short_datetime, long_datetime } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(initEvent(sensor));
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [batches, setBatches] = useState<any>([]);
  const [batchesLoading, setBatchesLoading] = useState<boolean>(false);

  const [batchModalOpen, setBatchModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const formatBatchOption = useCallback(
    (id: string, date: number, notes: string) => ({
      label: `${moment.unix(date).format(short_datetime)} ${notes ? "- " + notes : ""}`,
      value: id,
      date: date,
    }),
    [short_datetime]
  );

  useEffect(() => {
    // Loads the batches options for the batches select input
    // Once loaded, checks if it contains the default batch.
    // If it does, update the selected batch to the default.
    const placeId = place.placeId;
    const beerId = formData.beer ? formData.beer.value : undefined;

    if (placeId !== undefined && beerId !== undefined) {
      setBatchesLoading(true);
      fetchAutoCompleteBatches("batches", placeId, beerId)
        .then((response) => {
          const formattedBatches = response.map((batch: any) => formatBatchOption(batch.value, batch.label, batch.notes));
          setBatches(formattedBatches);
          setBatchesLoading(false);
        })
        .catch(() => {
          setBatchesLoading(false);
        });
    } else {
      setBatches([]);
      setFormData((prev: any) => ({
        ...prev,
        batch: undefined,
      }));
    }
  }, [place.placeId, formData.beer, formatBatchOption]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "sensorId":
          currValid = exists(name, value, setFormErrors);
          break;

        case "batch":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      sensorId: formData.sensorId,
      batchId: formData.batch && formData.batch.value,
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();
    saveLastFillEvent(formData);

    if (valid) {
      setSubmitting(true);
      changeBeerBatch(source, body)
        .then((response) => {
          onSuccess(sensor);
          setSubmittedMsg(response.message);
          setSubmittingErr("");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleDateChange = (date: any) => {
    setFormData((prev: any) => ({ ...prev, date: date[0] }));
    setFormErrors((prev: any) => ({ ...prev, date: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, batch: undefined, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, batch: undefined, [action.name]: undefined }));
  };

  const handleBatchSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({
      ...prev,
      [action.name]: selected,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      [action.name]: undefined,
    }));
  };

  // Loads the beer options for the beer select input.
  // Once loaded, checks if it contains the beer from the last fill event stored in local storage.
  // If it does, update the selected beer with this beer, else keep as undefined.
  const loadBeers = (inputValue: string, callback: any) => {
    fetchAutoComplete("beers", inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onSubmit={() => handleSubmit()}
        onClose={() => {
          if (!submitting) setModalOpen(false);
        }}
        title="Change Beer"
        success={submittedMsg}
        error={submittingErr}
        body={
          <LoadingContainer loading={submitting}>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <ModalFormContainer style={{ cursor: "not-allowed" }}>
                <label>Brewery</label>
                <FormInput autoComplete="no" type="text" name="brewery" value={place.placeName} disabled={true} />
                <FormError error={formErrors.brewery}>{formErrors.brewery}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Beer</label>
                <AsyncSelect
                  name="beer"
                  defaultOptions={true}
                  isClearable={true}
                  isSearchable={true}
                  isError={formErrors.beer}
                  value={formData.beer}
                  loadOptions={(inputValue: any, callback: any) => loadBeers(inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
                <FormError error={formErrors.beer}>{formErrors.beer}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Batch</label>
                <Select
                  name="batch"
                  options={batches}
                  isClearable={true}
                  isSearchable={true}
                  isError={formErrors.batch}
                  value={formData.batch || ""}
                  onChange={handleBatchSelectChange}
                  placeholder="Select..."
                  isLoading={batchesLoading}
                />
                <FormError error={formErrors.batch}>{formErrors.batch}</FormError>
                {noDate && formData.batch && formData.batch.date && moment().diff(moment.unix(formData.batch.date), "hours") >= 24 && (
                  <WarningAlert style={{ margin: "20px 0 0 0" }}>
                    Warning: This is an older batch that was created{" "}
                    <ReactTimeago live={false} date={formData.batch.date * 1000} title={moment.unix(formData.batch.date).format(long_datetime)} />
                  </WarningAlert>
                )}
              </ModalFormContainer>
              {!noDate && (
                <ModalFormContainer>
                  <label>Fill Date</label>
                  <Flatpickr
                    data-enable-time
                    value={formData.date}
                    onChange={handleDateChange}
                    options={{
                      maxDate: Date.now(),
                      minDate: formData.batch && formData.batch.date ? new Date(formData.batch.date * 1000) : undefined,
                    }}
                  />
                  <FormError error={formErrors.date}>{formErrors.date}</FormError>
                </ModalFormContainer>
              )}
            </form>
          </LoadingContainer>
        }
      />
      {batchModalOpen && (
        <EditBeerBatchModal
          modalOpen={batchModalOpen}
          setModalOpen={setBatchModalOpen}
          placeId={formData.brewery ? formData.brewery.value : undefined}
          beerId={formData.beer ? formData.beer.value : undefined}
          onCreate={(response: any) => {
            if (response) {
              const newBatchOption = formatBatchOption(response.id, response.dateCreated, response.notes);
              setBatches((prev: any) => [newBatchOption, ...prev]);
              setFormData((prev: any) => ({
                ...prev,
                brewery: { value: response.placeId, label: response.placeName },
                beer: { value: response.beerId, label: response.beerName },
                batch: newBatchOption,
              }));
              setFormErrors((prev: any) => ({
                ...prev,
                brewery: undefined,
                beer: undefined,
                batch: undefined,
              }));
            }
          }}
        />
      )}
    </>
  );
};

export default ChangeBeerModal;
