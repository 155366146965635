import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { getReportSchedulesTableSettings, reportSchedulesTableDefaults, saveReportSchedulesTableSettings } from "../../services/localStorage";
import { deleteReportSchedule, getReportSchedules } from "../../services/reportSchedules";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { getTableFilters } from "../../util/urlParamFilters";
import DeleteModal from "../DeleteModal";
import NewTable from "../NewTable";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

interface ReportSchedule {
  id: number;
  name: string;
  reportId: string;
  reportName: string;
  frequency: string;
  filters: string;
  recipients: string[];
  dateCreatedUnix: number;
  dateCreated: Date;
  createdBy: string;
}

const formatFilters = (filters: string): any => {
  if (!filters) return <span>No filters</span>;

  try {
    const parsed = JSON.parse(filters);
    const filtersArray: string[] = [];

    // Mapping of keys to display labels and value keys
    const filterMap = [
      { key: "startPlace", label: "Start Place", valueKey: "startPlaceName" },
      { key: "endPlace", label: "End Place", valueKey: "endPlaceName" },
      { key: "startPlaceType", label: "Start Place Type", valueKey: "startPlaceTypeName" },
      { key: "endPlaceType", label: "End Place Type", valueKey: "endPlaceTypeName" },
      { key: "startCountry", label: "Start Country", valueKey: "startCountry" },
      { key: "visitedCountry", label: "Visited Country", valueKey: "visitedCountry" },
      { key: "endCountry", label: "End Country", valueKey: "endCountry" },
    ];

    // Iterate through filterMap to generate tags dynamically
    filterMap.forEach(({ label, valueKey }) => {
      const value = parsed?.[valueKey];
      if (value) {
        filtersArray.push(`${label}: ${value}`);
      }
    });

    return filtersArray.length ? filtersArray : [];
  } catch (e) {
    console.error("Error parsing filters:", e);
    return [];
  }
};

const ReportsSchedules: FC<any> = () => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);

  const sourceRef = useRef(axios.CancelToken.source());

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "dateCreated", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedSchedule, setSelectedSchedule] = useState<any>(undefined);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getReportSchedulesTableSettings() ? getReportSchedulesTableSettings().pageSize : 20,
  });

  useEffect(() => {
    return () => {
      sourceRef.current.cancel();
    };
  }, []);

  const resetLoadingState = () => {
    setDataLoading(true);
    setDataErr("");
  };

  const fetchData = () => {
    resetLoadingState();

    getReportSchedules(sourceRef.current, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        const formattedData = response.data.map((item: { filters: string }) => ({
          ...item,
          filters: formatFilters(item.filters),
        }));
        setData(formattedData);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = useCallback(
    (download: boolean) => {
      resetLoadingState();

      getReportSchedules(sourceRef.current, { orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
        .then((response) => {
          const csvString = stringify(formatDataToCsv(response.data), {
            quoted: true,
            quoted_string: true,
          });

          if (download) {
            downloadFile(csvString, "text/csv;charset=utf-8", "Report Schedules List.csv");
          } else {
            copyToClipboard(csvString);
            toast.info("Copied to Clipboard");
          }
          setDataLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setDataErr(errToStr(err));
            setDataLoading(false);
          }
        });
    },
    [sorting, columnFiltersDebounced]
  );

  const formatDataToCsv = (data: ReportSchedule[]) => {
    const headers = ["Name", "Report Name", "Frequency", "Filters", "Recipients", "Date Created", "Created By"];

    return [
      headers,
      ...data.map((row) => [
        row.name,
        row.reportName,
        row.frequency,
        formatFilters(row.filters).join(", "),
        row.recipients.join(", "),
        row.dateCreatedUnix ? moment.unix(row.dateCreatedUnix).format(long_datetime) : "",
        row.createdBy,
      ]),
    ];
  };

  const columns = React.useMemo<ColumnDef<ReportSchedule>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <DangerMenuButton
                      onClick={() => {
                        setSelectedSchedule(row.original);
                        setDeleteModalOpen(true);
                      }}
                    >
                      Delete Report Schedule
                    </DangerMenuButton>
                  ) : (
                    <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                      <div
                        style={{
                          cursor: "not-allowed",
                          userSelect: "none",
                        }}
                      >
                        <MenuButton disabled={true}>Delete Report Schedule</MenuButton>
                      </div>
                    </Tooltip>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        header: "Name",
        accessorKey: "name",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 230,
      },
      {
        header: "Report Name",
        accessorKey: "reportName",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 230,
      },
      {
        header: "Frequency",
        accessorKey: "frequency",
        meta: {
          filterType: "select",
          loadOptionsKey: "frequency",
          selectKey: "value",
          selectOptions: [
            { value: "Daily", label: "Daily" },
            { value: "Weekly", label: "Weekly" },
            { value: "Monthly", label: "Monthly" },
          ],
        },
        filterFn: undefined,
        size: 190,
      },
      {
        header: "Recipients",
        accessorKey: "recipients",
        meta: {
          filterType: "string",
          filterKey: "recipientsRaw",
        },
        filterFn: undefined,
        cell: (props: any) => {
          const value = props.getValue();
          if (Array.isArray(value)) {
            return (
              <div>
                {value.map((recipient, index) => (
                  <Tag key={index} name={recipient} colour={color.primary[2]} />
                ))}
              </div>
            );
          }
          return <span>No Recipients</span>;
        },
        size: 230,
      },
      {
        header: "Filters",
        accessorKey: "filters",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        cell: (props: any) => {
          const value = props.getValue();
          if (Array.isArray(value)) {
            return (
              <div>
                {value.map((recipient, index) => (
                  <Tag key={index} name={recipient} colour={color.primary[2]} />
                ))}
              </div>
            );
          }
          return <span>No Filters</span>;
        },
        size: 250,
      },
      {
        header: "Date Created",
        accessorKey: "dateCreatedUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 170,
      },
      {
        header: "Created By",
        accessorKey: "createdBy",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 170,
      },
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={fetchData}
        fetchCsv={fetchCsv}
        defaultTableSettings={reportSchedulesTableDefaults}
        getTableSettings={getReportSchedulesTableSettings}
        saveTableSettings={saveReportSchedulesTableSettings}
        dataTypeName="Report Schedules"
        emptyDataMsg="Create a schedule to get started"
      />
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Report Schedule"
          body={
            <>
              <span>Are you sure you want to delete this report schedule?</span>
            </>
          }
          successMsg="Report Schedule Deleted"
          onDelete={() => setData((prev: any) => prev.filter((row: any) => row.id !== selectedSchedule.id))}
          onClose={() => setSelectedSchedule(null)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deleteReportSchedule}
          serviceParams={[selectedSchedule.id]}
        />
      )}
    </>
  );
};

export default ReportsSchedules;
