import { FC, useContext } from "react";
import { IconContext } from "react-icons";
import { BiSolidFactory } from "react-icons/bi";
import { BsThermometerHalf } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosBeer } from "react-icons/io";
import { TbTruckDelivery } from "react-icons/tb";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import BbKeg from "../../svgs/BbKeg";
import { checkDomain, isBinaryBeer } from "../../util/checkDomain";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import Bold from "../Bold";
import { PageContainer } from "../PageStyles";
import { Panel } from "../Panel";
import { CardBody, CardContainer, CardHeading, CardIcon, CardText, ReportCard, ReportPanelText } from "./styles";

const Reports: FC<any> = () => {
  const { color } = useContext(ThemeContext);

  const temperatureReports = [
    {
      slug: "/reports/temperature-range-audit",
      title: "Temperature Range Audit",
      meta: "The range of temperatures trackers experienced during a specified range",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <BsThermometerHalf />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/reports/temperature-events-audit",
      title: "Temperature Events Audit",
      meta: "Occasions where trackers recorded temperatures outside a specified range",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <BsThermometerHalf />
        </IconContext.Provider>
      ),
    },
  ];

  const placeReports = [
    {
      slug: "/reports/current-places-duration",
      title: "Current Places Duration",
      meta: "Compare time spent for trackers currently at different known places",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <FaMapMarkerAlt />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/reports/location-audit",
      title: "Location Audit",
      meta: "Review your fleet distribution at a specified date/time",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <FaMapMarkerAlt />
        </IconContext.Provider>
      ),
    },
  ];

  const transportReports = [
    {
      slug: "/reports/route-audit",
      title: "Route Audit",
      meta: "Review how often certain routes occur and how long they take",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <TbTruckDelivery />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/reports/current-in-transit-duration",
      title: "Current In-Transit Duration",
      meta: "Compare time spent for trackers currently not within a known location",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <TbTruckDelivery />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/reports/marked-for-pickup",
      title: "Marked for Pickup",
      meta: "Assets that have been marked for pickup",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <TbTruckDelivery />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/reports/stationary-not-at-a-place",
      title: `Stationary ${kegsOrTrackers("Kegs", "Trackers")} not at a Place`,
      meta: `${kegsOrTrackers("Kegs", "Trackers")} that appear to be stationary but aren't at a known place`,
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <TbTruckDelivery />
        </IconContext.Provider>
      ),
    },
    ...(isBinaryBeer()
      ? [
          {
            slug: "/reports/keg-collections",
            title: "Kegs Ready for Collection",
            meta: "Kegs which have been identified as empty and not already at a brewery",
            icon: (
              <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
                <TbTruckDelivery />
              </IconContext.Provider>
            ),
          },
        ]
      : []),
  ];

  const consumptionReports = [
    {
      slug: "/reports/freshness-report",
      title: "Freshness Report",
      meta: "Kegs that are below a specified freshness",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <IoIosBeer />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/reports/consumption-audit",
      title: "Consumption Audit",
      meta: "Review durations for kegs to arrive at a place, being placed on tap, and leave the place",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <IoIosBeer />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/reports/refrigeration-performance",
      title: "Refrigeration Performance",
      meta: "Review metrics from refrigeration measurements during a specific date/time",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <IoIosBeer />
        </IconContext.Provider>
      ),
    },
  ];

  const productionReports = [
    {
      slug: "/reports/left-brewery-empty",
      title: "Left Brewery Empty",
      meta: "Kegs which departed a brewery without a fill event from a scanner or user",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <BiSolidFactory />
        </IconContext.Provider>
      ),
    },
  ];

  const logisticReports = [
    {
      slug: "/reports/keg-turn-audit",
      title: "Keg Turn Audit",
      meta: "Review completed keg turns for a specified date/time",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <BbKeg colour={color.primary[2]} size="40px" />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/reports/wayward-kegs",
      title: "Wayward Kegs",
      meta: "These are kegs that have gone from one venue to another venue instead of back to a warehouse or brewery",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <BbKeg colour={color.primary[2]} size="40px" />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/reports/stagnant-kegs",
      title: "Stagnant Kegs",
      meta: "Number of kegs that have been at a place for 14 - 30 days and > 30 days",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <BbKeg colour={color.primary[2]} size="40px" />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/reports/keg-fleet-report",
      title: "Keg Fleet Report",
      meta: "Summary of the fleet's performance calculated per state. Includes averages of the kegs temperatures, durations spent at specific places, and the fleet distribution",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <BbKeg colour={color.primary[2]} size="40px" />
        </IconContext.Provider>
      ),
    },
  ];

  const generateReportCard = (reports: any) => {
    return reports.map((report: any, index: number) => (
      <ReportCard key={index}>
        <Link key={index} to={report.slug} style={{ textDecoration: "none" }}>
          <CardContainer>
            <CardIcon>{report.icon}</CardIcon>
            <CardText>
              <CardHeading>{report.title}</CardHeading>
              <CardBody>{report.meta}</CardBody>
            </CardText>
          </CardContainer>
        </Link>
      </ReportCard>
    ));
  };

  return (
    <PageContainer>
      <Panel>
        <ReportPanelText>
          <Bold>Temperature Reports:</Bold>{" "}
          {checkDomain(
            "Determine which assets experience extreme conditions",
            "Determine which assets experience extreme conditions",
            "Simplified quality auditing tools, save your filters for one-click auditing",
            "Determine which assets experience extreme conditions"
          )}
        </ReportPanelText>
        <div style={{ display: "flex", flexFlow: "wrap" }}>{generateReportCard(temperatureReports)}</div>
      </Panel>
      <Panel>
        <ReportPanelText>
          <Bold>Place Reports:</Bold> Compare locations against each other. For example,{" "}
          {checkDomain(
            "identify which storage locations hold onto assets the longest",
            "identify which storage locations hold onto assets the longest",
            "review time spent in hospitals vs labs",
            "identify which storage locations hold onto assets the longest"
          )}
        </ReportPanelText>
        <div style={{ display: "flex", flexFlow: "wrap" }}>{generateReportCard(placeReports)}</div>
      </Panel>
      <Panel>
        <ReportPanelText>
          <Bold>Transport Reports:</Bold> Optimise your {checkDomain("transport", "transport", "courier", "transport")} operations and shipping workflows
        </ReportPanelText>
        <div style={{ display: "flex", flexFlow: "wrap" }}>{generateReportCard(transportReports)}</div>
      </Panel>
      {isBinaryBeer() && (
        <>
          <Panel>
            <ReportPanelText>
              <Bold>Consumption Reports:</Bold> Identify the parts of your supply chain with the biggest impact on quality
            </ReportPanelText>
            <div style={{ display: "flex", flexFlow: "wrap" }}>{generateReportCard(consumptionReports)}</div>
          </Panel>
          <Panel>
            <ReportPanelText>
              <Bold>Production Reports:</Bold> Reports regarding breweries and brewery operations
            </ReportPanelText>
            <div style={{ display: "flex", flexFlow: "wrap" }}>{generateReportCard(productionReports)}</div>
          </Panel>
          <Panel>
            <ReportPanelText>
              <Bold>Logistic Reports:</Bold> Reports regarding logistics and supply chain operations
            </ReportPanelText>
            <div style={{ display: "flex", flexFlow: "wrap" }}>{generateReportCard(logisticReports)}</div>
          </Panel>
        </>
      )}
    </PageContainer>
  );
};

export default Reports;
