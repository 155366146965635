export const isBinaryTech = () => {
  const hostname = window.location.hostname || "";
  if (hostname.includes("binarytech")) {
    return true;
  } else if (isBinaryBeer()) {
    return false;
  } else if (isBinaryMed()) {
    return false;
  } else if (isBinaryLoop()) {
    return false;
  } else {
    return true;
  }
};

export const isBinaryBeer = () => {
  // return true;
  const hostname = window.location.hostname || "";
  return hostname.includes("binarybeer");
};

export const isBinaryMed = () => {
  // return true;
  const hostname = window.location.hostname || "";
  return hostname.includes("binarymed");
};

export const isBinaryLoop = () => {
  // return true;
  const hostname = window.location.hostname || "";
  return hostname.includes("binaryloop");
};

export const checkDomain = (tech: any, beer: any, med: any, loop: any) => {
  if (isBinaryTech()) {
    return tech;
  } else if (isBinaryBeer()) {
    return beer;
  } else if (isBinaryMed()) {
    return med;
  } else if (isBinaryLoop()) {
    return loop;
  }
  return tech;
};
