import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

type GetReportSchedulesProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const getReportSchedules = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: GetReportSchedulesProps) => {
  const params: any = {
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/reportschedule",
    params,
  });

  return { count: headers?.count, data };
};

export const postReportSchedule = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + `/v1/reportschedule`,
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const deleteReportSchedule = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "delete",
    url: process.env.REACT_APP_API_URL + `/v1/reportschedule/${id ? id : ""}`,
  });

  return data;
};
