import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { isEmail, isNumeric } from "validator";
import { humaniseMinutes } from "./humaniseDurations";

export const exists = (name: string, value: any, setFormErrors: any) => {
  if (value === "" || value === undefined || value == null || value.length === 0) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Required",
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const validName = (name: string, value: any, setFormErrors: any) => {
  // https://www.notion.so/binarytech/Allow-and-other-useful-characters-in-Tags-fc2c54ba749c4a22ae8de33a56dda885?pvs=4
  // if (!/^[\w\-\s.]*$/.test(value)) { // old test for only allowing alphanumeric characters, '-', '_', '.'"
  if ((typeof value === "string" || value instanceof String) && value.includes(",")) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Invalid name, commas (,) are not allowed",
    }));
    return false;
  } else if ((typeof value === "string" || value instanceof String) && value.toLowerCase() === "in transit") {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Invalid name, 'In Transit' is a reserved name",
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const validEmail = (name: string, value: any, setFormErrors: any) => {
  if (!isEmail(value)) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Invalid email format",
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const possiblePhoneNumber = (name: string, value: any, setFormErrors: any) => {
  if (!isPossiblePhoneNumber(value)) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Invalid phone number format",
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const validPhoneNumber = (name: string, value: any, setFormErrors: any) => {
  if (!isValidPhoneNumber(value)) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Invalid phone number format",
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const validWebhook = (name: string, value: any, setFormErrors: any) => {
  if (!value.includes("https://")) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Webhook URL must begin with https://",
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const validPassword = (name: string, value: string, setFormErrors: any) => {
  // Check if the password is at least 12 characters long
  if (!value || value.length < 12) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Password must be at least 12 characters long.",
    }));
    return false;
  }

  // Check if the password contains at least one lowercase letter
  if (!/[a-z]/.test(value)) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Password must contain at least one lowercase letter.",
    }));
    return false;
  }

  // Check if the password contains at least one uppercase letter
  if (!/[A-Z]/.test(value)) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Password must contain at least one uppercase letter.",
    }));
    return false;
  }

  // Check if the password contains at least one number
  if (!/[0-9]/.test(value)) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Password must contain at least one number.",
    }));
    return false;
  }

  // Check if the password contains at least one symbol
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Password must contain at least one symbol.",
    }));
    return false;
  }

  // If all checks pass, clear errors and return true
  setFormErrors((prev: any) => ({
    ...prev,
    [name]: false,
  }));
  return true;
};

export const passwordsMatch = (name: string, password: string, otherPassword: string, setFormErrors: any) => {
  if (password !== otherPassword) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Passwords don't match",
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

// Doesn't work with exponentials (e.g. 1e+12)
export const validNumber = (name: string, value: any, setFormErrors: any) => {
  if (!isNumeric(String(value))) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: "Invalid number",
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const max = (name: string, value: number, max: number, setFormErrors: any) => {
  if (value === undefined || value > max) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: `Must be less than or equal to ${max}`,
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const min = (name: string, value: number, min: number, setFormErrors: any) => {
  if (value === undefined || value < min) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: `Must be greater than or equal to ${min}`,
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const uploadRateMax = (name: string, value: number, max: number, setFormErrors: any) => {
  if (value === undefined || value > max) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: `Must be less than or equal to ${humaniseMinutes(max, ["h", "m", "s"])}`,
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const uploadRateMin = (name: string, value: number, min: number, setFormErrors: any) => {
  if (value === undefined || value < min) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: `Must be greater than or equal to ${humaniseMinutes(min, ["h", "m", "s"])}`,
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const logRateMax = (name: string, value: number, max: number, setFormErrors: any) => {
  if (value === undefined || value > max) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: `Must be less than or equal to ${humaniseMinutes(max, ["h", "m", "s"])}`,
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const logRateMin = (name: string, value: number, min: number, setFormErrors: any) => {
  if (value === undefined || value < min) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: `Must be greater than or equal to ${humaniseMinutes(min, ["h", "m", "s"])}`,
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const maxLength = (name: string, value: string, max: number, setFormErrors: any) => {
  if (value === undefined || value.length > max) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: `Must be ${max} characters or less`,
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const minLength = (name: string, value: string, min: number, setFormErrors: any) => {
  if (value === undefined || value.length < min) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: `Must be ${min} characters or more`,
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};

export const validOption = (name: string, value: any, options: any[], setFormErrors: any) => {
  if (!options.includes(value)) {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: `Invalid option, must be one of: ${options.join(", ")}`,
    }));
    return false;
  } else {
    setFormErrors((prev: any) => ({
      ...prev,
      [name]: false,
    }));
    return true;
  }
};
