import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchTripReport = async (
  source: CancelTokenSource,
  startPlace?: number,
  startPlaceType?: string,
  startCountry?: string,
  visitedCountry?: string,
  endPlace?: number,
  endPlaceType?: string,
  endCountry?: string,
  startDate?: number,
  endDate?: number
) => {
  const params = {
    organisationId: getAccount().organisationId,
    startPlace,
    startPlaceType,
    startCountry,
    visitedCountry,
    endPlace,
    endPlaceType,
    endCountry,
    startDate,
    endDate,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    // url: process.env.REACT_APP_API_URL + "/api/tripreport",
    url: process.env.REACT_APP_API_URL + "/v1/reports/transport/TransportReports",
    params,
  });

  return data;
};
