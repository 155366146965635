import styled, { css } from "styled-components";
import { MAP_CONSTANTS } from "./constants";

export const MapContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const ErrorScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 24px;
  text-align: center;
  background: ${({ theme }) => `${theme.color.main_bg[2]}CC`};
  backdrop-filter: blur(2px);
  z-index: 1000;
`;

export const MapError = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.color.error[2]};
  color: ${({ theme }) => theme.color.font[2]};
  padding: 12px 24px;
  border-radius: 2px;
  box-shadow: ${MAP_CONSTANTS.SHADOW};
  text-align: center;
  max-width: 80%;
  word-wrap: break-word;
`;

export const MarkerBase = css<{ isActive?: boolean; isHovered?: boolean }>`
  transition: transform 0.2s ease-in-out;
  transform: scale(${({ isActive, isHovered }) => (isActive || isHovered ? 1.1 : 1)});
`;

export const SmallMarker = styled.div<{ isActive?: boolean; isHovered?: boolean }>`
  ${MarkerBase}
  background: ${({ theme }) => theme.color.primary[2]};
  border-radius: 50%;
  width: ${MAP_CONSTANTS.MARKER_SIZE.SMALL}px;
  height: ${MAP_CONSTANTS.MARKER_SIZE.SMALL}px;
  border: ${MAP_CONSTANTS.BORDER_WIDTH}px solid white;
  box-shadow: ${MAP_CONSTANTS.SHADOW};
  cursor: pointer;
`;

export const LargeMarker = styled.div<{ isActive?: boolean; isHovered?: boolean; bgColor: string }>`
  ${MarkerBase}
  position: relative;
  width: ${MAP_CONSTANTS.MARKER_SIZE.LARGE}px;
  height: ${MAP_CONSTANTS.MARKER_SIZE.LARGE}px;
  border-radius: 50%;
  background: ${({ bgColor }) => bgColor};
  box-shadow: ${MAP_CONSTANTS.SHADOW};
  border: ${MAP_CONSTANTS.BORDER_WIDTH}px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 12px;
`;

export const PinContainer = styled.div<{ isActive?: boolean; isHovered?: boolean }>`
  ${MarkerBase}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const InfoWindowContent = styled.div`
  padding: 12px 12px 12px 0;
`;

export const InfoWindowRow = styled.div`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MapControlContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
`;

export const ControlPanel = styled.div`
  background: ${({ theme }) => theme.color.main_bg[2]};
  padding: 12px;
  border-radius: 2px;
  box-shadow: ${MAP_CONSTANTS.SHADOW};
`;

export const ControlButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background: ${({ theme }) => theme.color.main_bg[2]};
  border: 1px solid ${({ theme }) => theme.color.border[2]};
  box-shadow: ${MAP_CONSTANTS.SHADOW};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.font[2]};
  transition: all 0.2s ease-in-out;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.m};

  &:hover {
    background: ${({ theme }) => theme.color.panel_bg[2]};
    border-color: ${({ theme }) => theme.color.border[3]};
  }
`;

export const ZoomToFitButton = styled(ControlButton)`
  position: absolute;
  top: 10px;
  right: 60px;
  width: auto;
  padding: 0 12px;
  display: flex;
  gap: 6px;
`;

export const ErrorCloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font[2]};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.color.font[3]};
  }
`;
