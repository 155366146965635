import styled from "../Themes";

export const Form = styled("form")`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth.form};

  display: flex;
  flex-direction: column;
  align-items: center;

  margin: auto;
  padding: 24px;
`;

export const FormInputContainer = styled(`div`)`
  width: 100%;
  float: left;
  text-align: left;
`;

export const FormInput = styled<any>(`input`)`
  transition: all 250ms;
  width: 100%;
  text-align: left;
  border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_30.panel_fg[0])};
  background: ${({ theme }) => theme.color.input_bg[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  padding: 7px 7px;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  min-height: 34px;
  outline: none;

  ::placeholder {
    opacity: 0.5;
  }

  &:hover {
    border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_80.panel_fg[0])};
  }

  :focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.primary[2]};
  }

  :disabled {
    opacity: 0.5;
  }
`;

export const FormTextareaInput = styled<any>(`textarea`)`
  transition: all 250ms;
  width: 100%;
  text-align: left;
  border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_30.panel_fg[0])};
  background: ${({ theme }) => theme.color.input_bg[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  padding: 7px 7px;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  min-height: 34px;
  outline: none;
  resize: vertical;

  ::placeholder {
    opacity: 0.5;
  }

  &:hover {
    border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_80.panel_fg[0])};
  }

  :focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.primary[2]};
  }

  :disabled {
    opacity: 0.5;
  }
`;

export const FormSelectInput = styled<any>(`select`)`
  width: 100%;
  text-align: left;
  border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_30.panel_fg[0])};
  background: ${({ theme }) => theme.color.input_bg[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  padding: 7px 7px;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  min-height: 34px;
  outline: none;

  ::placeholder {
    opacity: 0.5;
  }

  :disabled {
    opacity: 0.4;
  }
`;

export const FormContainer = styled(`div`)`
  width: 720px;
  margin: 0 auto;
  padding: 24px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FormError = styled<any>(`span`)`
  font-size: 12px;
  line-height: 12px;
  height: 14px;
  color: ${({ theme }) => theme.color.error[2]};
  opacity: ${({ error }) => (error ? 1 : 0)};
  display: inline-block;
  width: 100%;
  float: left;
`;

export const InputUnitWrapper = styled<any>(`div`)`
  display: block;
  position: relative;

  &::after {
    content: ${({ unit }) => (unit ? `"${unit}"` : "")};
    position: absolute;
    top: 5px;
    right: 8px;
  }
`;

export const InfoIconContainer = styled(`div`)`
  width: 17px;
  height: 17px;
  text-align: center;
  float: left;
  margin-left: 4px;
`;
