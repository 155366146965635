import { createGlobalStyle } from "../Themes";

export default createGlobalStyle<any>`
    .gm-style-iw-d {
        overflow: auto !important;
    }

    .gm-style-iw.gm-style-iw-c {
        background-color: ${({ theme }) => theme.color.tooltip_bg[2]} !important;
        box-shadow: 0 2px 7px 1px ${({ theme }) => theme.color.tooltip_shadow[2]} !important;
        filter: drop-shadow(0 4px 2px ${({ theme }) => theme.color.tooltip_shadow[2]}) !important;
    }

    .gm-style-iw-t::after {
        background: ${({ theme }) => theme.color.tooltip_bg[2]} !important;
        box-shadow: -2px 2px 2px 0 ${({ theme }) => theme.color.tooltip_shadow[2]} !important;
    }

    /* Style the bottom arrow */
    .gm-style .gm-style-iw-tc::after {
        background: ${({ theme }) => theme.color.tooltip_bg[2]} !important;
        filter: drop-shadow(0 4px 2px ${({ theme }) => theme.color.tooltip_shadow[2]}) !important;
    }

    /* Style the bottom arrow */
    .gm-style .gm-style-iw-tc {
        filter: drop-shadow(0 4px 2px ${({ theme }) => theme.color.tooltip_shadow[2]}) !important;
    }

    /* Style the close button */
    .gm-style-iw-c button.gm-ui-hover-effect {
        background-color: ${({ theme }) => theme.color.tooltip_bg[2]} !important;
        
        /* Style the X icon */
        > span {
            background-color: ${({ theme }) => theme.color.font[2]} !important;
        }

        &:hover > span {
            background-color: ${({ theme }) => theme.color.font_bold[2]} !important;
        }
    }

    /* Style all map controls (buttons) */
    .gm-style .gmnoprint .gm-control-active,
    .gm-style .gmnoprint .gm-svpc,
    .gm-style .gm-style-mtc button,
    .gm-style .gm-svpc button,
    .gm-style button[title="Toggle fullscreen view"] {
        background-color: ${({ theme }) => theme.color.main_bg[2]} !important;
        border: 1px solid ${({ theme }) => theme.color.border[2]} !important;
        color: ${({ theme }) => theme.color.font[2]} !important;
        border-radius: 2px !important;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px !important;
        font-family: ${({ theme }) => theme.fontFamily.body} !important;
        font-size: ${({ theme }) => theme.fontSize.m} !important;
        transition: all 0.2s ease-in-out !important;

        &:hover {
            background-color: ${({ theme }) => theme.color.panel_bg[2]} !important;
            border-color: ${({ theme }) => theme.color.border[3]} !important;
        }
    }

    /* Style the dropdown menu */
    .gm-style .gm-style-mtc ul[role="menu"] {
        background-color: ${({ theme }) => theme.color.main_bg[2]} !important;
        border: 1px solid ${({ theme }) => theme.color.border[2]} !important;
        border-radius: 2px !important;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px !important;

        li[role="menuitemcheckbox"] {
            color: ${({ theme }) => theme.color.font[2]} !important;
            background-color: ${({ theme }) => theme.color.main_bg[2]} !important;
            font-family: ${({ theme }) => theme.fontFamily.body} !important;
            font-size: ${({ theme }) => theme.fontSize.m} !important;
            transition: all 0.2s ease-in-out !important;

            &:hover {
                background-color: ${({ theme }) => theme.color.panel_bg[2]} !important;
            }

            label {
                color: ${({ theme }) => theme.color.font[2]} !important;
                font-family: ${({ theme }) => theme.fontFamily.body} !important;
            }

            /* Style the checkbox container */
            span {
                border: 1px solid ${({ theme }) => theme.color.border[2]} !important;
                border-radius: 2px !important;
                background-color: ${({ theme }) => theme.color.main_bg[2]} !important;
                position: relative !important;
                transition: all 0.2s ease-in-out !important;
                margin-right: 2px !important;

                &:hover {
                    border-color: ${({ theme }) => theme.color.border[3]} !important;
                }
            }

            /* Style the checked state */
            &[aria-checked="true"] {
                span {
                    background-color: ${({ theme }) => theme.color.primary[2]} !important;
                    border-color: ${({ theme }) => theme.color.primary[2]} !important;
                    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='white' d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3E%3C/svg%3E") !important;
                    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='white' d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3E%3C/svg%3E") !important;
                    mask-size: cover !important;
                    -webkit-mask-size: cover !important;
                }
            }
            

            /* When unchecked, hide the checked icon and show the unchecked one */
            &[aria-checked="false"] {
                span {
                    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !important;
                    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !important;
                    mask-size: cover !important;
                    -webkit-mask-size: cover !important;
                    border-color: ${({ theme }) => theme.color.primary[2]} !important;
                    background-color: ${({ theme }) => theme.color.font[2]} !important;
                }
            }
        }
    }
`;
